import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from './../../shared/services/shared.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ContextService } from 'src/app/shared/services/context.service';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { tcRequireToken } from 'src/app/core/interceptors/auth.interceptor';
import { environment } from 'src/environments/environment';

interface Errors {
    missingUppercase?: boolean;
    missingLowercase?: boolean;
    missingSymbol?: boolean;
    missingNumber?: boolean;
}

enum ValidateTokenErrors {
    EmailAlreadyVerified = 'EmailAlreadyVerified',
    EmailNotExist = 'EmailNotExist',
    InvalidToken = 'InvalidToken',
    EmptyToken = 'EmptyToken',
    Valid = 'Valid'
}

interface ValidateActivateAccountToken {
    emailToken: string;
    email: string;
    userId: number;
    verificationResult: ValidateTokenErrors
    isPasswordSet: boolean
    companyId: number
    isPreviouslyVerified: boolean
}

const APIURL = `${environment.apiUrl}/cmp/EmployeeSetup/`;

@Component({
    selector: 'app-employee-account-activation',
    templateUrl: './employee-account-activation.component.html',
    styleUrls: ['./employee-account-activation.component.scss']
})
export class EmployeeAccountActivationComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private sharedService: SharedService,
        private router: Router,
        private contextService: ContextService,
        private http: HttpClient
    ) { }

    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }),
        responseType: 'json' as 'json',
    };
    isBusy: number = 0;
    submitted: boolean = false;
    toggleNewPasswordInputType: boolean = false;
    toggleconfirmNewPasswordInputType: boolean = false;
    errorMessages: Set<string> = new Set();
    accountActivate: boolean = false;
    token: string = "";
    loading: boolean = false;
    isPasswordSet: boolean = false;

    form = new FormGroup({
        newPassword: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            this.passwordValidator,
        ]),
        confirmNewPassword: new FormControl('', [Validators.required]),
    });

    get newPassword() {
        return this.form.get('newPassword')!;
    }
    get confirmNewPassword() {
        return this.form.get('confirmNewPassword')!;
    }

    async ngOnInit() {
        this.token = this.route.snapshot.queryParams['t'];
        await this.ValidateActivateAccountToken();

    }

    async ValidateActivateAccountToken() {
        try {
            this.loading = true;
            this.isBusy++;
            let response = await this.validateActivateAccountTokenApi(this.token);
            if (response.success && response.data) {
                if (response.data.isPasswordSet && !response.data.isPreviouslyVerified) {
                    this.isPasswordSet = true;
                    this.contextService.isShowPopoverHelp$.next(true);
                    if (response.data.companyId == this.contextService.getCompanyId()) {
                        this.router.navigate(['/welcome-page']);
                    } else {
                        let selectedCompany = this.contextService.employeeCompanies$
                            .getValue()
                            .find((item) => item.id == response.data?.companyId) ?? null;
                        if (selectedCompany) {
                            this.contextService.companyId$.next(selectedCompany?.id ?? 0);
                            this.contextService.selectCompany(selectedCompany?.id ?? 0);
                            this.contextService.companyName$.next(selectedCompany?.name ?? "Select Company");
                            this.contextService.companyLogoId$.next(selectedCompany?.logoDocumentId ?? 0);
                        }
                        this.router.navigate(['/welcome-page']);
                    }
                } else {
                    this.isPasswordSet = false;
                    switch (response.data.verificationResult) {
                        case ValidateTokenErrors.Valid:
                            if (this.contextService.getUserId() > 0 && response.data.userId != this.contextService.getUserId())
                                this.router.navigate([`/token-validations/wrongAccount/${response.data.email}`]);
                            break;
                        case ValidateTokenErrors.EmailAlreadyVerified:
                            let selectedCompany = this.contextService.employeeCompanies$
                            .getValue()
                            .find((item) => item.id == response.data?.companyId) ?? null;
                            if (selectedCompany) {
                                this.contextService.companyId$.next(selectedCompany?.id ?? 0);
                                this.contextService.selectCompany(selectedCompany?.id ?? 0);
                                this.contextService.companyName$.next(selectedCompany?.name ?? "Select Company");
                                this.contextService.companyLogoId$.next(selectedCompany?.logoDocumentId ?? 0);
                            }
                            if (this.contextService.getUserId() == 0)
                                this.router.navigate(['/token-validations/emailAlreadyVerified']);
                            else if (this.contextService.getUserId() > 0 && response.data.userId == this.contextService.getUserId())
                                this.router.navigate(['/token-validations/emailAlreadyVerified']);
                            else if (this.contextService.getUserId() > 0 && response.data.userId != this.contextService.getUserId())
                                this.router.navigate([`/token-validations/wrongAccount/${response.data.email}`]);
                            break;
                        case ValidateTokenErrors.EmailNotExist:
                        case ValidateTokenErrors.InvalidToken:
                        case ValidateTokenErrors.EmptyToken:
                            this.router.navigate(['/token-validations/accountActivationLinkNoLongerValid']);
                            break;
                    };
                }
            }
        } catch (error: any) {
            this.sharedService.alertDangerMessage();
        } finally {
            this.isBusy--;
            setTimeout(() => {
                this.loading = false;
            }, 1000);

        }
    }

    // Custom password validator
    passwordValidator(control: any) {
        const value = control.value;
        let errors: Errors = {};

        // Check for at least one uppercase letter
        const uppercaseRegex = /[A-Z]/;
        if (!uppercaseRegex.test(value)) {
            errors.missingUppercase = true;
        }

        // Check for at least one lowercase letter
        const lowercaseRegex = /[a-z]/;
        if (!lowercaseRegex.test(value)) {
            errors.missingLowercase = true;
        }

        // Check for at least one symbol
        const symbolRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
        if (!symbolRegex.test(value)) {
            errors.missingSymbol = true;
        }

        // Check for at least one number
        const numberRegex = /\d/;
        if (!numberRegex.test(value)) {
            errors.missingNumber = true;
        }

        return errors;
    }

    async submit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.newPassword != this.form.value.confirmNewPassword) {
            this.errorMessages.add('Passwords don’t match');
            return;
        } else {
            this.errorMessages.delete('Passwords don’t match');
        }
        try {
            this.isBusy++;
            let response = await this.activateAccount(
                this.token,
                this.form.value.newPassword!
            );
            if (response.success) {
                this.sharedService.alertSuccessMessage();
                this.router.navigate(['/token-validations/yourAccountHasBeenActivated']);
            }
        } catch (error: any) {
            this.sharedService.alertDangerMessage();
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        } finally {
            this.isBusy--;
        }
    }

    async validateActivateAccountTokenApi(token: string) {
        return await lastValueFrom(this.http
            .post<ApiResponse<ValidateActivateAccountToken>>(
                APIURL + 'ValidateActivateAccountToken',
                { 
                    token: token,
                 },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
    }

    async activateAccount(token: string, password: string) {
        return await lastValueFrom(this.http
            .post<ApiResponse<ValidateActivateAccountToken>>(
                APIURL + 'ActivateAccount',
                { 
                    token: token,
                    password: password
                },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
    }

}
