import { TranslateService } from '@ngx-translate/core';
import { Component, Input, Output, EventEmitter, OnInit, HostListener, OnDestroy } from '@angular/core';
import { FileService } from '../../services/file.service';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ImageViewer } from '../../model/shared.model';
@Component({
    selector: 'app-image-viewer',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit, OnDestroy {
    @Input() open: boolean = false;
    // @Input() imageURL: string = "";
    @Input() imagesList: any = [];
    @Input() imageIndex: number = 0;
    @Input() viewMode: boolean = false;
    @Output() close = new EventEmitter();
    @Output() delete = new EventEmitter();
    activeImageUrl: Observable<SafeUrl> | null = null;
    activeImageId: number = 0;
    activeImageApiId: number = 0;
    activeMediaType: string = "image";
    constructor(
        private fileService: FileService,
        private translate: TranslateService,
    ) { }

    ngOnInit(): void {

    }

    @HostListener('document:keydown', ['$event'])
    handleDeleteKeyboardEvent(event: KeyboardEvent) {
        switch (event.key) {
            case "ArrowRight":
                this.nextArrow();
                break;
            case "ArrowLeft":
                this.prevArrow();
                break;
        }
    }

    ngOnChanges(changes: any) {
        let header = document.querySelector<HTMLElement>('.page-header')!;
        let sideNav = document.querySelector<HTMLElement>('.side-nav')!;
        if (changes?.open?.currentValue) {
            this.getActiveImage();
            header.style.visibility = "hidden";
            sideNav.style.visibility = "hidden";
        } else {
            header.style.visibility = "visible";
            sideNav.style.visibility = "visible";
        }
    }

    deleteImage(){
        this.delete.emit(this.activeImageId)
    }

    getActiveImage() {
        this.imagesList.forEach((e: ImageViewer, i: number) => {
            if (i == this.imageIndex) {
                this.activeImageUrl = this.fileService.fetchImageWithToken(e.imageId);
                this.activeImageId = e.id;
                this.activeImageApiId = e.imageId;
                this.activeMediaType = e.type ? e.type : "image"
                // return this.activeImageUrl;
            }
        });
    }

    nextArrow() {
        if (this.imageIndex == this.imagesList.length - 1)
            return;
        this.imageIndex++;
        this.getActiveImage();
    }

    prevArrow() {
        if (this.imageIndex == 0)
            return
        this.imageIndex--;
        this.getActiveImage();
    }

    ngOnDestroy() {
        let header = document.querySelector<HTMLElement>('.page-header')!;
        let sideNav = document.querySelector<HTMLElement>('.side-nav')!;
        header.style.visibility = "visible";
        sideNav.style.visibility = "visible";
        this.activeImageUrl = null
    }

    async downloadImage() {
        this.fileService.downloadFile("Image",
            this.activeImageApiId
        );
    }
}
