import { FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

interface Errors {
    missingUppercase?: boolean;
    missingLowercase?: boolean;
    missingSymbol?: boolean;
    missingNumber?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FormService {

    constructor() { }

    trackChanges(form: FormGroup, onChange: (key: string, value: any) => void): Subscription {
        const initialValue = form.value;
        return form.valueChanges.subscribe((value) => {
            let el = Object.keys(initialValue).find((key) => form.value[key] != initialValue[key]);
            if (el) {
                onChange(el, form.value[el]);
                initialValue[el] = form.value[el];
            }
        });
    }

    setFormFieldsRequiredValidators(form: FormGroup, fileds: string[] = []) {
        let f = form.controls;
        fileds.forEach(e => {
            f[e].setValidators(Validators.required);
            f[e].updateValueAndValidity();
        })
        return form;
    }

    removeFormFieldsValidators(form: FormGroup, fileds: string[] = []) {
        let f = form.controls;
        fileds.forEach(e => {
            f[e].setValidators(null);
            f[e].clearValidators();
            f[e].setErrors(null);
            f[e].updateValueAndValidity();
        })
        return form;
    }

    // Custom password validator
    passwordValidator(control: any) {
        const value = control.value;
        let errors: Errors = {};

        // Check for at least one uppercase letter
        const uppercaseRegex = /[A-Z]/;
        if (!uppercaseRegex.test(value)) {
            errors.missingUppercase = true;
        }

        // Check for at least one lowercase letter
        const lowercaseRegex = /[a-z]/;
        if (!lowercaseRegex.test(value)) {
            errors.missingLowercase = true;
        }

        // Check for at least one symbol
        const symbolRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
        if (!symbolRegex.test(value)) {
            errors.missingSymbol = true;
        }

        // Check for at least one number
        const numberRegex = /\d/;
        if (!numberRegex.test(value)) {
            errors.missingNumber = true;
        }

        return errors;
    }
}
