<div [class.view-mode]="viewMode">
    <div class="dropdown" #dropdownContainer>
        <input #inputSearch [id]="id" [formControl]="myControl" (click)="inputClick()"
            [class.input-table-editable]="isTableEditable" [class.input-table-editable-error]="filedError"
            [class.input-has-tooltip]="tooltipTemplate && myControl.value && dropdownSelectedObj" (keyup)="inputClick()"
            (keyup.enter)="inputClick()" [readonly]="readonly || viewMode" autocomplete="off"
            [placeholder]="placeholder | translate" (input)="changeInput($event)" (blur)="onBlurInput()"
            [maxlength]="maxlength" />
        <span class="tooltip-custom" *ngIf="tooltipTemplate && myControl.value && dropdownSelectedObj">
            <img src="/assets/images/info-icon-empty.svg" alt="" [ngbPopover]="tooltip_template" triggers="mouseenter" placement="end"/>
            <ng-template #tooltip_template>
                <div [ngStyle]="{ width: tooltipWidth }">
                    <ng-container *ngTemplateOutlet="
                        tooltipTemplate;
                        context: { data: dropdownSelectedObj }
                    ">
                </ng-container>
                </div>
                
            </ng-template>
            
            <!-- <div class="tooltip-custom-content" [ngStyle]="{ width: tooltipWidth }">
                <div class="tooltip-arrow"></div>
                <ng-container *ngTemplateOutlet="
                        tooltipTemplate;
                        context: { data: dropdownSelectedObj }
                    ">
                </ng-container>
            </div> -->
        </span>
        <span class="x-close" [class.x-close-editable-table]="isTableEditable"
            [class.x-close-has-tooltip]="tooltipTemplate && myControl.value && dropdownSelectedObj"
            *ngIf="myControl.value && !readonly && !viewMode" (click)="deleteSearch()"><img
                src="assets/images/x-close-black.svg" /></span>
        <span class="dropdown-arrow" *ngIf="!readonly && !viewMode"
            [class.dropdown-arrow-has-tooltip]="tooltipTemplate && myControl.value && dropdownSelectedObj" (click)="arrowClick()"><img
                src="/assets/images/dropdown-icon.svg" /></span>
        <ul class="dropdown-menu-static" id="dropdown-menu" #list [ngStyle]="{ 'max-height': height }"
            [class.d-none]="!showDropdownMenu || readonly || viewMode">
            <!-- (filteredOptions | async)?.length == 0 || -->
            <div class="list-item" (scroll)="onScroll($event)">
                <ng-container *ngIf="
                    showDropdownMenu &&
                    !readonly &&
                    !viewMode &&
                    !((filteredOptions | async)?.length == 0)
                ">
                    <ng-container *ngIf="this.autocompleteDropdownConfig?.searchFromApi;else localSearch">
                        <li *ngFor="
                        let option of optionsList
                        let i = index
                    " (click)="changeValue(option.value)" id="menu-item-{{ i + 1 }}" [attr.value]="option.value">
                            <a class="dropdown-item" href="javascript:void(0)"
                                (keyup.enter)="changeValue(option.value)">
                                <span class="body-short-1">{{ option.text | translate }}</span>
                                <span class="helper-text-1 item-desc" *ngIf="option.description"
                                    [innerHTML]="option.description"></span>
                            </a>
                            <div class="line" *ngIf="(filteredOptions | async)?.length != i + 1">
                                <hr />
                            </div>
                        </li>
                    </ng-container>
                    <ng-template #localSearch>
                        <li *ngFor="
                        let option of optionsList
                            | myfilter
                                : myControl.value
                        let i = index
                    " (click)="changeValue(option.value)" id="menu-item-{{ i + 1 }}" [attr.value]="option.value">
                            <a class="dropdown-item" href="javascript:void(0)"
                                (keyup.enter)="changeValue(option.value)">
                                <span class="body-short-1">{{ option.text | translate }}</span>
                                <span class="helper-text-1 item-desc" *ngIf="option.description"
                                    [innerHTML]="option.description"></span>
                            </a>
                            <div class="line" *ngIf="(filteredOptions | async)?.length != i + 1">
                                <hr />
                            </div>
                        </li>
                    </ng-template>
                </ng-container>
                <li class="spinner" *ngIf="isLoading"></li>
                <li *ngIf="autocompleteDropdownConfig && autocompleteDropdownConfig.allowAddNew" class="custom-add-item"
                    (click)="addNew()">
                    <div class="line">
                        <hr />
                    </div>
                    <a class="dropdown-item" href="javascript:void(0)">
                        <i class="icon-plus icon-s-m"></i>
                        <span
                            class="body-short-1">{{autocompleteDropdownConfig.addNewLabel ?
                            (autocompleteDropdownConfig.addNewLabel | translate) : ""}}</span>
                    </a>
                </li>
            </div>
        </ul>
    </div>
</div>