export interface EldMonitoringOverview {
    violationCount: number
    verifiedLogsPercent: number
    unverifiedLogsPercent: number
    unidentifiedLogsCount: number
    previousUnidentifiedLogsCount: number
}

export enum IntervalEnum {
    Today = 'Today',
    Week = 'Week',
    Month = 'Month',
    Year = 'Year'
}

export interface HosComplianceDateSummary {
    dateScores: {
        date: string
        scorePercent: number
    }[]
}

export interface HosComplianceDriverSummary {
    driverScores: {
        driverName: string
        scorePercent: number
    }[]
}

export interface ELDTimeFilter {
    label: string
    value: IntervalEnum
    isActive: boolean
}


export interface HosViolationSummary {
    violationsCount: number
    perviousViolationsCount: number
    violatorsCount: number
    previousViolatorsCount: number
    mostCommonViolationTypes: string[]
}


export interface HosViolationDriverChart {
    driverName: string
    violationsCount: string
    violationTypeCounts: {
        violationsCount: number
        violationsType: string
    } []
}

export interface HosViolationDriverTable {
    id: number
    driverName: string
    violationsCount: number
    mostCommonViolationType: string
}

export interface HosUnverifiedLogsSummary {
    id: number
    driverName: string
    unverifiedLogsCount: number
    totalLogsCount: number
}

export interface HosUnidentifiedLogsSummary {
    id: number
    assetCode: string
    unIdentifiedLogsCount: number
}