import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, lastValueFrom } from 'rxjs';
import { DownloadReportAdvance, DownloadReportOptionSub } from 'src/app/shared/model/download-report-dialog.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

const APIURL = `${environment.apiUrl}/cmp/`;

@Component({
    selector: 'app-download-report-advance',
    templateUrl: './download-report-advance.component.html',
    styleUrls: ['./download-report-advance.component.scss']
})
export class DownloadReportAdvanceComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<DownloadReportAdvanceComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DownloadReportAdvance,
        private http: HttpClient,
        private sharedService: SharedService,
        private translate: TranslateService
    ) { }

    @ViewChild('progressBar') progressBar: ElementRef | null = null;
    isBusy: number = 0;
    activeTab: number = 1;
    isExportDone: boolean = false;
    allComplete: boolean = false;
    showAttachments: boolean = false;
    hasApplication: boolean = false;
    isExportFail: boolean = false;

    ngOnInit(): void {
        this.showAttachmentsChange();
    }

    close() {
        this.resetChecked();
        this.dialogRef.close();
    }

    goToTab(index: number) {
        this.activeTab = index;
    }

    someComplete(): boolean {
        if (this.dialogData.downloadReportOptions.sub == null) {
            return false;
        }
        return this.dialogData.downloadReportOptions.sub.filter(t => t.checked).length > 0 && !this.allComplete;
    }

    setAll(completed: boolean) {
        this.allComplete = completed;
        if (this.dialogData.downloadReportOptions.sub == null) {
            return;
        }
        if (this.showAttachments) {
            this.dialogData.downloadReportOptions.sub.forEach(t => {
                t.checked = completed
            });
        } else {
            this.dialogData.downloadReportOptions.sub.forEach(t => {
                if (t.isAttachment == false)
                    t.checked = completed
            });
        }
    }

    updateAllComplete() {
        if (this.showAttachments) {
            this.allComplete = this.dialogData.downloadReportOptions.sub != null && this.dialogData.downloadReportOptions.sub.every(t => t.checked);
        } else {
            let downloadReportOptionsSubCopy: DownloadReportOptionSub[] = JSON.parse(JSON.stringify(this.dialogData.downloadReportOptions.sub));
            downloadReportOptionsSubCopy = downloadReportOptionsSubCopy.filter(t => t.isAttachment == false)
            this.allComplete = this.dialogData.downloadReportOptions.sub != null && downloadReportOptionsSubCopy.every(t => t.checked);
        }
    }

    showAttachmentsChange() {
        this.updateAllComplete();
        this.hasApplication = this.dialogData.downloadReportOptions.sub.find(e => e.isApplication == true) ? true : false;
        this.dialogData.downloadReportOptions.sub.forEach(e => {
            if (e.isAttachment) {
                e.show = this.showAttachments;
                if (!this.showAttachments) {
                    e.checked = false;
                }
            } else {
                e.show = true;
            }
        })
    }

    checkSelected() {
        if (this.dialogData.downloadReportOptions.sub.filter(t => t.checked).length > 0)
            return true
        else
            return false
    }

    checkAttachment() {
        if (this.dialogData.downloadReportOptions.sub.filter(t => t.isAttachment).length > 0)
            return true
        else
            return false
    }

    // async continue() {
    //     try {
    //         this.isBusy++;
    //         setTimeout(() => {
    //             this.progressBar?.nativeElement.animate(
    //                 [{ width: '0%' }, { width: '100%' }],
    //                 {
    //                     duration: 1500,
    //                     iterations: 100000000000,
    //                 }
    //             );
    //         });
    //         let selectedIds: number[] = [];
    //         let selectedColumns: string[] = [];
    //         this.dialogData.selectedItems.forEach((e: any) => {
    //             selectedIds.push(e.id);
    //         });
    //         // this.columns.forEach((e, i: number) => {
    //         //     if (e.checked && e.key) {
    //         //         let columnName = e.key.charAt(0).toUpperCase() + e.key.slice(1);
    //         //         selectedColumns.push(columnName);
    //         //     }
    //         // });
    //         let response = await this.downloadFile(selectedIds, selectedColumns, this.dialogData.sortString);
    //         if (response.sucsses) {
    //             this.isExportDone = true;
    //             this.isExportFail = false;
    //         } else {
    //             this.isExportFail = true;
    //         }
    //     } catch (error: any) {
    //         this.isExportFail = true;
    //     }
    //     finally {
    //         this.isBusy--;
    //     }
    // }

    async downloadReport() {
        try {
            this.isBusy++;
            setTimeout(() => {
                this.progressBar?.nativeElement.animate(
                    [{ width: '0%' }, { width: '100%' }],
                    {
                        duration: 1500,
                        iterations: 100000000000,
                    }
                );
            });
            let selectedIds: number[] = [];
            this.dialogData.selectedItems.forEach((e: any) => {
                selectedIds.push(e.id);
            });
            let resultJson: any = {
                objectIds: selectedIds,
                isShowAttachment: this.showAttachments
            }
            this.dialogData.downloadReportOptions.sub.forEach(s => {
                if (!s.isAttachment) {
                    resultJson[s.key] = {
                        isMainPage: s.checked
                    }
                } else {
                    if (s.parent) {
                        resultJson[s.parent][s.key] = s.checked;
                    }
                }
            });
            let response = await this.downloadFile(resultJson);
            if (response.sucsses) {
                this.isExportDone = true;
            } else {
                this.isExportFail = true;
            }
        } catch (error: any) {
            this.isExportFail = true;
        } finally {
            this.isBusy--;
            this.resetChecked();
        }
    }

    async downloadFile(body: {}) {
        try {
            let response = await lastValueFrom(this.getReport(body));
            // var newBlob = new Blob([response.body], { type: response.body.type });
            // const data = window.URL.createObjectURL(newBlob);
            // var link = document.createElement('a');
            // link.href = data;
            // let year = new Date().getFullYear();
            // let month = (1 + new Date().getMonth()).toString().padStart(2, '0');
            // let day = new Date().getDate().toString().padStart(2, '0');
            // let date = month + '/' + day + '/' + year;
            // link.download = this.translate.instant(this.dialogData.title) + " " + date;
            // link.dispatchEvent(
            //     new MouseEvent('click', {
            //         bubbles: true,
            //         cancelable: true,
            //         view: window,
            //     })
            // );

            // await setTimeout(() => {
            //     window.URL.revokeObjectURL(data);
            //     link.remove();
            // }, 100);
            return {
                sucsses: true
            }
        } catch (err) {
            this.sharedService.alertDangerMessage(
                'Download Failed',
                'There was an error downloading the file, please try again'
            );
            return {
                sucsses: false
            }
        } finally {
        }
    }

    getReport(
        body: {}
    ): Observable<Blob | any> {
        let url = APIURL + this.dialogData.reportAPI;
        return this.http.post(url, body, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    resetChecked() {
        this.dialogData.downloadReportOptions.sub.forEach(e => {
            e.checked = false;
        })
    }
}
