<ng-container *ngIf="isGroupBy">
    <div class="tc-multi-select">
        <p-multiSelect [id]="id" appendTo="body" [options]="dropdownList" [group]="isGroupBy" [formControl]="myControl"
            placeholder="{{placeholder |translate}}" [disabled]="isReadonly" [panelStyle]="{'width':width+'px'}"
            scrollHeight="250px;" (onChange)="onChangeValue()">
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <span class="group-label">{{ group.label }}</span>
                </div>
            </ng-template>
        </p-multiSelect>
    </div>
</ng-container>
<ng-container *ngIf="!isGroupBy">
    <div class="tc-multi-select">
            <p-multiSelect [id]="id" appendTo="body"  [options]="dropdownList" [group]="isGroupBy" (click)="init()"
            [formControl]="myControl" placeholder="{{placeholder |translate}}" [disabled]="isReadonly" optionLabel="description"
            [panelStyle]="{'width':width+'px'}" scrollHeight="250px;" (onChange)="onChangeValue()" [showToggleAll]="true">
            <ng-template let-value pTemplate="selectedItems">
                @if(value && value.length > 0){
                <div class="selected-items">
                    {{selectedItemslabel |translate}} <span class="bold">{{value.length}}</span>
                </div>
                } @else {
                {{placeholder |translate}}
                }
            </ng-template>
            <ng-template let-item pTemplate="item">
                <div>
                    <div class="heading-7">{{ item.text }}</div>
                    <div class="label-1" [innerHTML]="item.description"></div>
                </div>
            </ng-template>
        </p-multiSelect>
        </div>

</ng-container>