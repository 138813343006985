<div class="input-contain-hint" *ngIf="!viewMode">
        <input type="text" class="form-control" [(ngModel)]="inputUnitValue" (focus)="onFocus()" (blur)="onBlur()"
            onkeypress="return event.charCode != 45" (ngModelChange)="inputChange($event)" placeholder="{{
        placeholder | translate
    }}" [readonly]="readonly" [id]="id" (keydown)="checkLength2($event, inputText)" #inputText />
        <span *ngIf="inputUnitType" class="input-hint body-short-1">{{inputUnitType}}</span>
</div>

<div class="input-hint-view" *ngIf="viewMode">
    <ng-container *ngIf="inputUnitValue;else unfilled">
        <ng-container *ngIf="inputUnitType == InputUnitTypeEnum.currency;else other">
            <span class="body-short-1">{{inputUnitType +""+ inputUnitValue}}</span>
        </ng-container>
        <ng-template #other>
            @if(inputUnitType) {
            <span class="body-short-1">{{inputUnitValue + " " + inputUnitType}}</span>
            } @else {
            <span class="body-short-1">{{inputUnitValue}}</span>
            }
        </ng-template>
    </ng-container>
    <ng-template #unfilled>
        <span class="unfilled">Unfilled</span>
    </ng-template>

</div>