import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Output } from '@angular/core';
import {
    distinctUntilChanged,
    map,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { fromEvent } from 'rxjs';
@Directive({
    selector: '[resizable]',
})
export class ColResizeDirective {
    constructor(
        @Inject(DOCUMENT) private readonly documentRef: Document,
        @Inject(ElementRef)
        private readonly elementRef: ElementRef<HTMLElement>
    ) {}

    @Output()
    readonly resizable = fromEvent<MouseEvent>(
        this.elementRef.nativeElement,
        'mousedown'
    ).pipe(
        tap((e) => e.preventDefault()),
        switchMap(() => {
            const { width, right } = this.elementRef.nativeElement
                .closest('th')
                ?.getBoundingClientRect() ?? { width: 0, right: 0 };

            return fromEvent<MouseEvent>(this.documentRef, 'mousemove').pipe(
                map(({ clientX }) => width + clientX - right),
                distinctUntilChanged(),
                takeUntil(fromEvent(this.documentRef, 'mouseup'))
            );
        })
    );
}
