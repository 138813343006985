import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormControl,
    UntypedFormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import {
    AppFilesList,
    FilesListAutocompleteConfig,
} from '../../model/shared.model';
import { FileService } from '../../services/file.service';

@Component({
    selector: 'app-files-list',
    templateUrl: './files-list.component.html',
    styleUrls: ['./files-list.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FilesListComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => FilesListComponent),
        },
    ],
})
export class FilesListComponent
    implements OnInit, ControlValueAccessor, Validator
{
    @Input('additionalFields') additionalFields = {};
    @Input() id: string = '';
    @Input() fileLabel: string = 'Document';
    @Input() labelAfterUpload: string = 'Document';
    @Input() submitted: boolean = false;
    @Input() buttonCol: string = 'col-md-9';
    @Input() filesCol: string = 'col-md-12';
    @Input() fieldName: string | null = null;
    @Input() readonly: boolean = false;
    @Input() icon: string = '';
    @Input() viewMode: boolean = false;
    @Input() fileType: string[] | null = null;
    @Input() hideReupload: boolean = false;
    @Input() hideDownload: boolean = false;
    @Input() hideDelete: boolean = false;
    @Input() disabledReupload: boolean = false;
    @Input() disabledDownload: boolean = false;
    @Input() disabledDelete: boolean = false;
    @Input() subtitle: string | null = null;
    @Input() isRequired: boolean = false;
    @Input() disabled: boolean = false;
    @Input() buttonLable: string = 'UPLOAD_DOCUMENT';
    @Input() autocompleteConfig: FilesListAutocompleteConfig | null = null;
    @Input() allowTextField: boolean = false;
    @Input() buttonWidth: string = '100%';
    @Input() allowDeleteBtn: boolean = false;
    @Output() valueChange: EventEmitter<any> = new EventEmitter();

    onTouched = () => {};
    docsList: AppFilesList[] = [];
    docsIndex: number = 0.5;
    isFirstCall: boolean = true;
    docListForm: UntypedFormGroup = new UntypedFormGroup({});
    constructor(private fileService: FileService) {}

    ngOnInit(): void {
        if (this.fieldName || this.autocompleteConfig)
            this.filesCol = 'col-md-6';
    }

    onChange = (docList: any[] = []) => {
        this.writeValue(this.docsList);
        // this.docListForm.controls[''];
    };

    writeValue(docList: any = []): void {
        if (docList?.length > 0 && this.isFirstCall) {
            docList.forEach((doc: AppFilesList) => {
                this.addDocToList(doc);
            });
        }
        // this.docsList = docList;
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    async onFileUpload(event: any) {
        if (event.target.files[0]) {
            const resposne = await this.fileService.saveFile(
                event.target.files[0]
            );

            if (resposne) {
                this.addDocToList({
                    documentId: resposne.id,
                    id: 0,
                });
                // this.labelAfterUpload = event.target.files[0].name
            }
        }
    }

    onDocChange(event: any, docId: number) {
        // this.unavailabilityDocs.splice(
        //     this.unavailabilityDocs.findIndex((doc) => doc.id == docId),
        //     1
        // );

        if (event.newFileId == null) {
            this.docsList.splice(
                this.docsList.findIndex((doc: any) => doc.localId == docId),
                1
            );
        } else {
            this.docsList.find((doc: any) => doc.localId == docId)!.documentId =
                this.docListForm.controls[`docNumber-${docId}`].value ?? 0;
        }
        this.onChange(this.docsList);
        this.valueChange.emit(this.docsList);
    }

    // this.addDocToList(doc.documentId, doc.id);
    addDocToList(doc: AppFilesList) {
        this.docsIndex++;
        this.docListForm.addControl(
            `docNumber-${this.docsIndex}`,
            new UntypedFormControl(doc.documentId)
        );
        if (this.fieldName)
            this.docListForm.addControl(
                `textField-${this.docsIndex}`,
                new UntypedFormControl(doc.textField ? doc.textField : '')
            );

        if (this.autocompleteConfig) {
            this.docListForm.addControl(
                `autocomplete-${this.docsIndex}`,
                new UntypedFormControl(
                    doc.autocompleteValue ? doc.autocompleteValue : null,
                    this.autocompleteConfig.required
                        ? [Validators.required]
                        : []
                )
            );
        }

        if (!this.docsList) this.docsList = [];
        this.docsList.push({
            ...this.additionalFields,
            localId: this.docsIndex,
            documentId: doc.documentId ? doc.documentId : 0,
            // documentType: DrugTestDocumentTypeEnum.NotificationNote,
            id: doc.id ?? 0,
            autocompleteValue: doc.autocompleteValue ?? '',
            textField: doc.textField ?? '',
        });
        this.isFirstCall = false;
        this.onChange(this.docsList);
        this.valueChange.emit(this.docsList);
    }

    onFieldInput(event: any, docId: number) {
        this.docsList.find((el: any) => el.localId == docId)!.textField =
            this.docListForm.controls[`textField-${docId}`].value;

        this.onChange(this.docsList);
    }

    onAutocompleteInput(event: any, docId: number) {
        this.docsList.find(
            (el: AppFilesList) => el.localId == docId
        )!.autocompleteValue =
            this.docListForm.controls[`autocomplete-${docId}`].value;

        this.onChange(this.docsList);
    }

    validate(c: AbstractControl): ValidationErrors | null {
        if (this.docsList && this.docsList?.length > 0) {
            if (
                this.docsList?.some(
                    (doc) =>
                        this.docListForm.controls[`autocomplete-${doc.localId}`]
                            ?.errors
                )
            ) {
                return {
                    required: true,
                };
            }
            return null;
        }

        return null;
    }

    onDelete(event: any, docId: number) {
        this.docsList.splice(
            this.docsList.findIndex((el: any) => el.localId == docId),
            1
        );
        this.onChange(this.docsList);
        this.valueChange.emit(this.docsList);
    }

    getviewModeLabel(value: string) {
        return this.autocompleteConfig?.optionsList.find(
            (el) => el.value == value
        )?.text ?? value != ''
            ? value
            : this.labelAfterUpload ?? this.fileLabel;
    }

    getFileLabel(doc: AppFilesList): string {
        if (this.viewMode) {
            if (this.autocompleteConfig) {
                return this.getviewModeLabel(
                    doc.autocompleteValue ??
                        'this.fileLabel ?? this.labelAfterUpload'
                );
            } else if (this.allowTextField) {
                return doc.textField ?? this.labelAfterUpload ?? this.fileLabel;
            }
        } else {
            return (
                this.labelAfterUpload ?? this.labelAfterUpload ?? this.fileLabel
            );
        }
        return this.fileLabel ?? this.labelAfterUpload;
    }
}
