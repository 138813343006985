import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { environment } from 'src/environments/environment';
import { DocumentsFpList } from '../model/shared.model';
import { lastValueFrom } from 'rxjs';

const APIURL = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(private http: HttpClient) {}

    async getTruckDocuments(
        truckId: number
    ): Promise<ApiResponse<DocumentsFpList[]>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<DocumentsFpList[]>>(
                `${APIURL}/cmp/TruckAux/${truckId}/Document/FpList`
            )
            );

        return response;
    }

    async getTrailerDocuments(
        trailerId: number
    ): Promise<ApiResponse<DocumentsFpList[]>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<DocumentsFpList[]>>(
                `${APIURL}/cmp/TrailerAux/${trailerId}/Document/FpList`
            )
            );

        return response;
    }

    async getDriverDocuments(
        driverId: number
    ): Promise<ApiResponse<DocumentsFpList[]>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<DocumentsFpList[]>>(
                `${APIURL}/cmp/EmployeeAux/${driverId}/Document/FpList`
            )
            );

        return response;
    }

    async cloneDocument(docId: number): Promise<ApiResponse<{ id: number }>> {
        const response = await lastValueFrom(this.http
            .post<ApiResponse<{ id: number }>>(
                `${APIURL}/api/file/${docId}/Clone`,
                {}
            ));
        return response;
    }
}
