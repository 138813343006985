import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { PhoneNumberService } from '../../services/phone-number.service';

@Component({
    selector: 'app-phone-number-input',
    templateUrl: './phone-number-input.component.html',
    styleUrls: ['./phone-number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => PhoneNumberInputComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => PhoneNumberInputComponent),
        },
    ],
})
export class PhoneNumberInputComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() id: string = '';
    @Input() readonly: boolean = false;
    @Input() placeHolder: string = '';
    phoneNumberValue: string = "";
    constructor(
        private phoneService: PhoneNumberService
    ) { }
    onChange = (value: string) => {
        this.writeValue(value);
    };
    onTouched = () => { };
    touched = false;
    disabled = false;
    validate(c: AbstractControl): ValidationErrors | null {
        return null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        // console.log('Method not implemented.');
    }

    writeValue(value: string): void {
        if(value){
            this.phoneNumberValue = this.phoneService.phoneNumberFromServer(value);
        }
    }
    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    markAsTouched() {
        if (!this.touched) {
            // console.log('touched.');
            this.onTouched();
            this.touched = true;
        }
    }
    ngOnInit(): void {
    }

    inputChange(value: string){
        this.markAsTouched();
        value = this.phoneService.phoneNumberFromLocalToServer(value)
        this.onChange(value);
    }
    
}
