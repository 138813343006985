import { Injectable } from '@angular/core';
import { ContextService } from './context.service';
import { TableOptions } from '../model/app-table.model';

const COMPANY_USER_PREFERENCES = "company-user-preferences";

interface CompanyUserPreferencesServiceValue {
    key: string
    value: string | boolean | number
}

@Injectable({
    providedIn: 'root'
})
export class CompanyUserPreferencesService {

    constructor(
        private contextService: ContextService
    ) { }

    private generateKey(key: string) {
        let conmpanyId = this.contextService.getCompanyId();
        let userId = this.contextService.getUserId();
        return `${conmpanyId}_${userId}_${key}`;
    }

    setValue(key: string, value: string | boolean | number | {} | []) {
        let objValue = window.localStorage.getItem(this.generateKey(key));
        if (objValue) {
            objValue = JSON.stringify(value);
            window.localStorage.setItem(this.generateKey(key), JSON.stringify(value));
        } else {
            if (!key.includes("_")) {
                window.localStorage.setItem(this.generateKey(key), JSON.stringify(value));
            }
        }
    }

    getValue(key: string) {
        let data = window.localStorage.getItem(this.generateKey(key));
        if (data)
            return JSON.parse(data);
        else
            return null;
    }

    setTableSettings(key: string, value: TableOptions<any> | undefined) {
        let columns: any = [];
        if(value?.column){
            value.column.forEach(e =>{
                if(e.key){
                    columns.push({
                        key: e.key,
                        sortType: e.sortType,
                        visible: e.visible
                    })
                }
            })
        }
        let tableObj: any = {
            column: columns,
            showAs: value?.showAs,
            isActive: value?.isActive
        }
        let objValue = window.localStorage.getItem(this.generateKey(key));
        if (objValue) {
            objValue = JSON.stringify(tableObj);
            window.localStorage.setItem(this.generateKey(key), JSON.stringify(tableObj));
        } else {

            if (!key.includes("_")) {
                window.localStorage.setItem(this.generateKey(key), JSON.stringify(tableObj));
            }
        }
    }

    removeItem(key: string){
        window.localStorage.removeItem(this.generateKey(key))
    }
}
