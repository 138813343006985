<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">
            {{ title | translate }}
        </h5>
    </div>
    <div class="close-icon" (click)="cancel()">
        <img (click)="cancel()" src="/assets/images/x-close-black.svg" />
    </div>
</div>
<div class="dialog-body">
    <div class="row mb-3">
        <div class="col-12 body-short-2">
            {{ messageHeader | translate }}
            <br /><br />
            {{ messageBody | translate }}
        </div>
    </div>
</div>
<div class="dialog-footer">
    <div class="app-actions-button">
        <button class="app-close-modal-button" (click)="cancel()">
            {{ "CANCEL" | translate }}
        </button>
        <button class="app-save-changes-modal-button" (click)="save()">
            {{ addBtnTxt | translate }}
        </button>
    </div>
</div>
