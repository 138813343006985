import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DriverService } from 'src/app/modules/driver/services/driver.service';
import { ChangeEmail } from 'src/app/modules/employees/models/employee.model';
import { EmployeesApiService } from 'src/app/modules/employees/services/employees-api.service';
import { SharedService } from 'src/app/shared/services/shared.service';

interface DialogData {
    from: string,
    id: number,
    oldEmail: string
}

@Component({
    selector: 'app-e-mail-changing',
    templateUrl: './e-mail-changing.component.html',
    styleUrl: './e-mail-changing.component.scss'
})
export class EMailChangingComponent {

    constructor(
        public dialogRef: MatDialogRef<EMailChangingComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        private sharedService: SharedService,
        private employeesApiService: EmployeesApiService,
        private driverService: DriverService
    ) { }

    step: number = 1;
    submitted: boolean = false;
    isBusy: number = 0;
    errorMessages = new Set<string>();
    form = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
    });

    get email() {
        return this.form.get('email');
    }

    ngOnInit(): void {
        if(this.dialogData.oldEmail == null || this.dialogData.oldEmail == "") {
            this.step = 2;
        }
    }

    close() {
        this.dialogRef.close();
    }

    continue() {
        this.step = 2;
    }

    async save() {
        this.errorMessages.clear();
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        if(this.dialogData.oldEmail == this.form.value.email) {
            this.errorMessages.add("The new e-mail address and the old e-mail address are the same.");
            return;
        }
        try {
            this.isBusy++;
            let body: ChangeEmail = {
                email : this.form.value.email!
            }
            let response = this.dialogData.from == "employee" ?
             await this.employeesApiService.changeEmail(this.dialogData.id,body) : await this.driverService.changeEmail(this.dialogData.id,body);
            if (response.success) {
                this.sharedService.alertSuccessMessage();
                this.dialogRef.close({
                    action: "saved",
                    email: this.form.value.email!
                });
            }
        } catch (error: any) {
            this.sharedService.alertDangerMessage();
            this.errorMessages.add(
                error?.error?.error.isUserError
                    ? error.error.error.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        } finally {
            this.isBusy--;
        }
    }

}
