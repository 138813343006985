import { Component, OnInit, Input } from '@angular/core';
import { TableDataModel } from '../../model/app-table.model';
import { CardActionList, CardConfig, FunctionTypeEnum } from '../../model/card-data.model';
import { ThrottleCallService } from '../../services/throttle-call.service';
import { PagingData } from 'src/app/core/models/api-response.model';
import { ActionResponse, GetDataOptions } from '../../model/shared.model';

@Component({
    selector: 'app-card',
    templateUrl: './app-card.component.html',
    styleUrls: ['./app-card.component.scss']
})
export class AppCardComponent<T extends TableDataModel> implements OnInit {

    currentPage: number = 0;
    maxSize: number = 7;
    isBusy: boolean = false;
    constructor() { }
    @Input() set cardOptions(data: CardConfig<T>) {
        this.cardConfig = data;
        this.pageSize = this.cardConfig.pageLength;
        this.options = {
            sort: "",
            pageSize: this.pageSize,
            pageIndex: this.pageIndex,
            searchString: this.searchInput || ''
        }
        this.getData();
    }
    cardConfig: CardConfig<T> | undefined | null;
    cardsData: T[] = [];
    searchInput: string = "";
    // pagenation
    pageIndex: number = 0;
    pages: number = 1
    totalCount: number = 0;
    pageSize: number = 10;
    options: GetDataOptions = {
        sort: "",
        pageSize: 0,
        pageIndex: 0,
        searchString: ''
    };
    //
    showRowFrom: number = 0;
    showRowTo: number = 0;
    startLoading: boolean = true;

    ngOnInit(): void {
    }

    private isPaginated = (object: PagingData<T> | T[]): object is PagingData<T> => {
        return "pageData" in object;
    }

    private tc = new ThrottleCallService<ActionResponse<T>>();
    async getData(delay = 0) {
        if (this.cardConfig && delay === 0) {
            this.isBusy = true;

            //console.log('fetch from database')
            let response = await this.tc.call(async () =>
                await this.cardConfig!.getData(this.options), delay);
            //console.log('completed fetch from database')

            if (response && response.data) {
                if (this.isPaginated(response.data)) {
                    this.pages = response.data.totalPages;
                    this.cardsData = response.data.pageData;
                    this.pageIndex = response.data.pageIndex;
                    this.totalCount = response.data.totalCount;
                    this.updatePagination();
                } else {
                    this.pages = 0;
                    this.cardsData = response.data;
                    this.pageIndex = 0;
                    this.totalCount = 0;
                    this.updatePagination();
                }
            }
            this.isBusy = false
        }
    }


    updatePagination() {
        this.showRowFrom = (this.pageIndex) * this.pageSize + 1;
        this.showRowTo =
            (this.pageIndex) * this.pageSize + 1 + this.cardsData?.length - 1;
    }

    searchApi() {
        this.options.searchString = this.searchInput;
        this.getData(1000);
    }


    changePage(pageNumber: number) {
        this.options.pageIndex = pageNumber - 1;
        this.getData();
    }

    async removeRow(item: T) {
        if (this.cardConfig && this.cardConfig.removeRow) {
            let removeActionResp = null;
            if (item.id !== undefined)
                removeActionResp = await this.cardConfig.removeRow(item);
            if (removeActionResp == null || removeActionResp.success == true) {
                this.cardsData.splice(this.cardsData.indexOf(item), 1);
                this.getData();
            } else if (
                removeActionResp != null &&
                removeActionResp.success == false
            ) {
                // this.cardService.alert(removeActionResp.errMsg ?? 'General error', AlertTypeEnum.Danger)
            }
            this.updatePagination();
        }
    }

    async callAction(action: CardActionList<T>, item: T) {
        if (action.functionType == FunctionTypeEnum.remove)
            await this.removeRow(item);
        else if (action.actionFunc) {
            if (action.isFuncAsync) {
                let response = await action.actionFunc(item);
                if (response.isRefresh) this.refresh();
            } else {
                let response = <ActionResponse<T>>action.actionFunc(item);
                if (response.isRefresh)
                    this.refresh();
            }
        }
    }

    public refresh(resetPage = false) {
        if (resetPage) {
            this.options.pageIndex = 0;
        }
        this.getData();
    }


}
