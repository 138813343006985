<div class="dialog-header">
    <div class="row">
        <span class="dialog-title" *ngIf="data.customTitle;else showTitle">{{data.customTitle |translate}}</span>
        <ng-template #showTitle>
            <span class="dialog-title">{{ data.actionTitle | translate }} {{"ENTRY" |translate}}?</span>
        </ng-template>
    </div>
</div>
<div class="dialog-body">
    <div class="row">
        <span class="body-short-2" *ngIf="data.customBody;else showBody">
            {{data.customBody |translate}}
        </span>
        <ng-template #showBody>
            <span class="body-short-2">
                {{"CONFIRM_CHANGES_DESC" |translate}}
                {{ data.actionTitle }}
                {{"THIS" |translate}}?
            </span>
        </ng-template>
    </div>
</div>
<div class="dialog-footer">
    <div class="row">
        <div class="col-12">
            <div class="invalid-form-warning" *ngIf="errorMsg">
                <div class="invalid-icon"></div>
                {{ errorMsg | translate }}
            </div>
        </div>
    </div>
    <div class="app-actions-button">
        <button class="app-close-modal-button" id="cancel-action" (click)="cancel()">
            {{"CANCEL" |translate}}
        </button>
        <button class="primary-danger-button-large" id="confirm-action" (click)="confirm()">
            {{ data.actionTitle }}
        </button>
    </div>
</div>