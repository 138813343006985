import {
    Directive,
    ElementRef,
    HostListener,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[appPhoneNumberMask]',
})
export class PhoneNumberMaskDirective implements OnInit, OnDestroy {
    oldValue: string = '';
    valueSubscription?: Subscription;

    isPreviewKeyABackspace: boolean = false;
    isPreviewKeyADelete: boolean = false;

    constructor(
        public ngControl: NgControl,
        private el: ElementRef /*, private renderer: Renderer2*/
    ) { }

    ngOnInit(): void {
        this.valueSubscription = this.ngControl.control?.valueChanges.subscribe(
            (value) => {
                this.onInputChange(value, false, false);
            }
        );
    }

    ngOnDestroy(): void {
        this.valueSubscription?.unsubscribe();
    }

    // @HostListener('ngModelChange', ['$event'])
    // onModelChange(event: string) {
    //     this.onInputChange(event, false);
    // }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event: any) {
        this.onInputChange(event.target.value, true, false);
    }

    @HostListener('keydown.delete', ['$event'])
    keydownDelete(event: any) {
        this.onInputChange(event.target.value, false, true);
    }

    onInputChange(value: string, isBackspace: boolean, isDelete: boolean) {
        if(value == null)
            return;
        let start = this.el.nativeElement.selectionStart;
        let end = this.el.nativeElement.selectionEnd;

        if (isBackspace) {
            // don't consider it a backspace unless it makes a change
            //  even if we are going to undo the change in the subsequent call
            if (start != end || start > 1)
                this.isPreviewKeyABackspace = isBackspace;
            return;
        }

        if (isDelete) {
            // don't consider it a delete unless it makes a change
            //  even if we are going to undo the change in the subsequent call
            if (start != end || start < value.length)
                this.isPreviewKeyADelete = isDelete;
            return;
        }

        let newVal = value.replace(/\D/g, '');
        let rawVal = newVal;

        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length <= 3) {
            if (newVal.length == 1 && (newVal == "1" || newVal == "0")) {
                newVal = "";
            }
            newVal = newVal.replace(/^(\d{0,3})/, '($1');
        } else if (newVal.length <= 6) {
            if (newVal[0] == "1" || newVal[0] == "0") {
                newVal = this.setCharAt(newVal, 0, '');
            }
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else if (newVal.length <= 10) {
            if (newVal[0] == "1" || newVal[0] == "0") {
                newVal = this.setCharAt(newVal, 0, '');
            }
            newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                '($1) $2-$3'
            );
        } else {
            // Queue : entering text at the end will trim first ones
            //newVal = newVal.substring(newVal.length - 10, newVal.length);

            // Block : stops user from entering new numbers
            newVal = newVal.substring(0, 10);
            if (newVal[0] == "1" || newVal[0] == "0") {
                newVal = this.setCharAt(newVal, 0, '');
            }
            newVal = newVal.replace(
                /^(\d{0,3})(\d{0,3})(\d{0,4})/,
                '($1) $2-$3'
            );
        }

        this.ngControl.valueAccessor?.writeValue(newVal);
        this.ngControl.control?.setValue(newVal, { emitEvent: false });

        let offset = 0;
        if (this.isPreviewKeyADelete == true) {
            offset = 0;
        } else if (this.isPreviewKeyABackspace == true) {
            if (start == 1) offset = 0;
            // sep 1 : 1
            //else if (start > 1 && start < 5) offset = 0; // section 1 : 2 to 4
            else if (start >= 5 && start < 7) offset = 4 - start;
            // sep 2 : 5 and 6
            //else if (start >= 7 && start < 10) offset = 0; // section 2 : 7 to 9
            else if (start >= 10 && start < 11) offset = 9 - start; // sep 3 : 10
            //else if (start >= 11) offset = 0; // section 3 : 10 to 13
        } else {
            // Just in case the user typed in a letter then we should undo the progress
            if (rawVal === this.oldValue.replace(/\D/g, '')) offset = -1;
            // because a paranthesis is introduced, we should progress one step
            else if (start == 1) offset = 1;
            // sep 1 : 1
            //else if (start > 1 && start < 5) offset = 0; // section 1 : 2 to 4
            else if (start >= 5 && start < 7) offset = 7 - start;
            // sep 2 : 5 and 6
            //else if (start >= 7 && start < 10) offset = 0; // section 2 : 7 to 9
            else if (start >= 10 && start < 11) offset = 11 - start; // sep 3 : 10
            //else if (start >= 11) offset = 0; // section 3 : 10 to 13
        }
        this.oldValue = newVal;
        this.el.nativeElement.setSelectionRange(start + offset, end + offset);

        this.isPreviewKeyABackspace = isBackspace;
        this.isPreviewKeyADelete = isDelete;
    }

    setCharAt(str: string, index: number, chr: string) {
        if (index > str.length - 1) return str;
        return str.substring(0, index) + chr + str.substring(index + 1);
    }
}
