import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    Inject,
} from '@angular/core';
import { ImportExportAlertComponent } from '../import-export-alert/import-export-alert.component';
import readXlsxFile from 'read-excel-file';
import { DropdownList } from '../../model/shared.model';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import {
    ColumnsFromAPI,
    ImporListtModalData,
    ImportListMappedData,
} from '../../model/import-export-list.model';
import { FileService } from '../../services/file.service';
import { lastValueFrom } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-import-list',
    templateUrl: './import-list.component.html',
    styleUrls: ['./import-list.component.scss'],
})
export class ImportListComponent implements OnInit {
    //@ViewChild('dragOverlay') dragOverlay: ElementRef | null = null;
    @ViewChild('columnMappingTabButton') columnMappingTabButton: ElementRef | null = null;
    @ViewChild('importReportTabButton') importReportTabButton: ElementRef | null = null;
    @ViewChild('progressBar') progressBar: ElementRef | null = null;

    constructor(
        private dialogRef: MatDialogRef<ImportListComponent>,
        private matDialog: MatDialog,
        private fileService: FileService,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: ImporListtModalData
    ) {}

    importListForm = this.fb.group({
        alreadyExistItems: ['skip'],
    });
    
    missingRequiredFields: string = '';
    isMissingRequiredFields: boolean = false;
    isDataImported: boolean = false;
    tabCount: number = 0;
    isStarted: boolean = false;
    typeNotSupported: boolean = false;
    isImportDone: boolean = false;
    fileId: number | null = null;
    importedFile: File | null = null;
    convertedFile: any[] = [];
    filerowsLength?: number;
    errorMessages = new Set<string>();
    columnErrors: {
        totalRowsCount: number;
        importedRowsCount: number;
        skippedRowsCount: number;
        overwrittenRowsCount: number;
        erroneousRowsCount: number;
        errorRows: {
            rowNumber: number;
            errorMessage: string;
        }[];
    } = {
        totalRowsCount: 0,
        importedRowsCount: 0,
        skippedRowsCount: 0,
        overwrittenRowsCount: 0,
        erroneousRowsCount: 0,
        errorRows: [],
    };

    columnsDataFromAPI: ColumnsFromAPI[] = [];
    tableColumns: DropdownList[] = [
        {
            text: 'Do Not Include',
            value: null,
        },
    ];
    fileColumns: DropdownList[] = [];
    requiredColumns: {
        selected: boolean;
        data: ColumnsFromAPI;
    }[] = [];
    showRequiredFields: boolean = false;

    async ngOnInit() {
        this.columnsDataFromAPI = await this.dialogData.getData();
        this.columnsDataFromAPI.forEach((el: ColumnsFromAPI) => {
            this.tableColumns.push({
                text: el.displayName + (el.isRequired ? '*' : ''),
                value: el.fieldName,
                data: el,
            });
            if (el.isRequired)
                this.requiredColumns.push({
                    data: el,
                    selected: false,
                });
        });
    }

    get f() {
        return this.importListForm.controls;
    }

    cancel() {
        this.dialogRef.close(this.isDataImported ? 'confirm': 'cancel');
    }

    async onDrop(event: any) {
        event.preventDefault();

        if (event?.dataTransfer?.files?.length > 0) {
            const file = event.dataTransfer.files[0];
            await this.uploadCSVFile(file);
        }
    }

    onDregOver(event: any) {
        event.preventDefault();
    }
    
    async onFileChange(event: any) {
        if (event?.target?.files?.length > 0) {
            const file = event.target.files[0];
            await this.uploadCSVFile(file);
        }
    }

    private async uploadCSVFile(file: File) {
        if (!file.type.includes('csv') &&
            !file.type.includes('spreadshee') &&
            !file.type.includes('excel')) {
            this.typeNotSupported = true;
        } else {
            this.typeNotSupported = false;
            this.resetImportReportTab();
            this.importedFile = file;
            if (await this.uploadDoc(file)) {
                this.importedFile = file;
                await this.fileToJSON();
            }
        }
    }

    async onCancelImport() {
        let result = await lastValueFrom(
            this.matDialog
                .open(ImportExportAlertComponent, {
                    data: {
                        type: 'import',
                    },
                    width: '41.6rem',
                    height: '21.8rem',
                    disableClose: true,
                })
                .afterClosed()
        );

        if (result == 'cancel') {
            this.dialogRef.close(this.isDataImported ? 'confirm': 'cancel');
        } else {
        }
    }

    navColumnMappingTabButton() {
        this.tabCount = 1;
        // we need to imitate the click to trigger the tab
        this.columnMappingTabButton?.nativeElement.click();
    }

    navImportReport() {
        this.tabCount = 2;
        setTimeout(() => {
            this.importReportTabButton?.nativeElement.click();
        }, 50);
    }

    private parseTokens(input: string): string[] {
        // Split the input by carriage returns and take the first line
        const firstLine = input.split('\r\n')[0] || input.split('\n')[0];
      
        const regex = /"([^"]+)"|'([^']+)'|([^,]+)/g;
        const tokens: string[] = [];
        let match;
      
        while ((match = regex.exec(firstLine)) !== null) {
          tokens.push(match[1] ?? match[2] ?? match[3]);
        }
      
        return tokens;
    }

    private async fileToJSON() {
        this.fileColumns = [];
        this.convertedFile = [];
        let fileReader = new FileReader();
        if (this.importedFile?.type.includes('csv')) {
            fileReader.readAsText(this.importedFile!);
            fileReader.onload = () => {
                const csvString: string = fileReader.result as string;
                const matches = csvString.match(/\r?\n/g);
                this.filerowsLength = matches ? matches.length : 0;
                const fileheaderparsed = this.parseTokens(csvString);
                fileheaderparsed.forEach((el) => {
                    this.fileColumns.push({
                        text: el,
                        value: this.stripString(el),
                        data: el,
                    });
                });
                this.createFormControls();
            };
        } else if (
            this.importedFile?.type.includes(
                'openxmlformats-officedocument.spreadsheetml.sheet'
            )
        ) {
            //xlsx
            // readXlsxFile(this.importedFile!).then((rows) => console.log(rows));
            this.convertedFile = await readXlsxFile(this.importedFile);
            if (this.convertedFile.length > 0) {
                this.filerowsLength = this.convertedFile.length - 1;
                this.convertedFile[0].forEach((el: string) => {
                    this.fileColumns.push({
                        text: el,
                        value: this.stripString(el),
                        data: el,
                    });
                });
                this.createFormControls();
            }
        }
    }

    private createFormControls() {
        this.fileColumns.forEach((el) => {
            let value = el.value;
            let foundTableColumn = this.tableColumns
                .filter(tcol => (tcol?.hide ?? false) == false)
                .find(tcol => this.fieldCompare(tcol.data, el.text));
            if (foundTableColumn) {
                value = foundTableColumn.data.fieldName;
                foundTableColumn.hide = true;
            } else {
                foundTableColumn = this.tableColumns
                    .filter(tcol => (tcol?.hide ?? false) == false)
                    .find(tcol => this.fieldCompare(tcol.data, el.text, true));
                if (foundTableColumn) {
                    value = foundTableColumn.data.fieldName;
                    foundTableColumn.hide = true;
                }
            }
            
            this.importListForm.addControl(
                String(el.value),
                new UntypedFormControl(value)
            );  
        });
        this.columnDropdownChange('');
    }

    private async uploadDoc(file: File | null): Promise<boolean> {
        if (file) {
            const response = await this.fileService.saveFile(file);
            this.fileId = response.id;
            if (this.fileId) return true;
            return false;
        }
        return false;
    }

    columnDropdownChange(event: any) {
        this.requiredColumns.forEach((el) => (el.selected = false));
        this.tableColumns.forEach((el) => (el.hide = false));

        this.fileColumns.forEach((col) => {

            // Check required fields if they are selected in the form's fields
            const foundRequired = this.requiredColumns.find((el) => this.fieldCompare(el.data, this.f[col.value!].value));
            if (foundRequired) foundRequired.selected = true;

            // Hide the table's fields from the dropdown list if they are already selected in the form's fields
            const itemToHide = this.tableColumns.find((tCol) => tCol.value != null && this.fieldCompare(tCol.data, this.f[col.value!].value));
            if (itemToHide) itemToHide.hide = true;

            // Hide the table's fields from the dropdown list if one of there alternatives is selected in the form's fields
            this.columnsDataFromAPI.forEach((el) => {
            
                // Let's say that we found FullName was selected in a form's field
                if (this.fieldCompare(el, this.f[col.value!].value)) {

                    // FullName has two elements in "replaces".  It has First Name and Last Name.
                    //  For each element, we are going to hide it from in the dropdown list.
                    el.replaces.forEach((second) => {
                        const foundAlternate = this.tableColumns.find((tCol) => this.fieldCompare(tCol.data, second));
                        if (foundAlternate) foundAlternate.hide = true;

                        const foundRequired = this.requiredColumns.find((el) => this.fieldCompare(el.data, second));
                        if (foundRequired) foundRequired.selected = true;
                    });
                }
            });
        });

        this.missingRequiredFields = this.requiredColumns.filter(el => el.selected == false).map(el => el.data.displayName).join(',');
        this.isMissingRequiredFields = this.requiredColumns.some(el => el.selected == false);
    }

    fieldCompare(data: ColumnsFromAPI, value: string, isEasy: boolean = false): boolean {
        if (data) {
            if (data.fieldName == value ||
                this.stripString(data.fieldName) == this.stripString(value) ||
                data.alternatives.some(alt => this.stripString(alt) === this.stripString(value)))
                return true;
            else if (isEasy &&
                (this.stripString(value).includes(this.stripString(data.fieldName)) ||
                 data.alternatives.some(alt => this.stripString(value).includes(this.stripString(alt)))))
                 return true;
        }
        return false;
    }
    
    confirm() {
        this.dialogRef.close(this.isDataImported ? 'confirm': 'cancel');
    }

    async onImportList() {
        this.columnErrors.errorRows = [];
        this.errorMessages.clear();
        if (this.requiredColumns.some((el) => el.selected == false)) {
            this.showRequiredFields = true;
            return;
        }
        this.navImportReport();
        let mappedColumns: {
            sourceColumn: string;
            targetColumn: string;
        }[] = [];

        this.fileColumns.forEach((col) => {
            const foundColumn = this.tableColumns.find((tCol) => this.fieldCompare(tCol.data, this.f[col.value!].value));
            if (foundColumn && foundColumn.data) {
                mappedColumns.push({
                    sourceColumn: col.text,
                    targetColumn: foundColumn.data.fieldName,
                });
            }
        });

        let mappedColumnsModel = new ImportListMappedData({
            documentId: this.fileId!,
            mappedColumns: mappedColumns,
            alreadyExistItems: this.f.alreadyExistItems.value,
        });

        this.progressBar?.nativeElement.animate(
            [{ width: '0%' }, { width: '100%' }],
            {
                duration: 1800,
                iterations: 10000,
            }
        );

        setTimeout(async () => {
            try {
                const response = await this.dialogData.importFunc(mappedColumnsModel);
                this.isImportDone = true;

                if (response.success) {
                    if (response.data) {
                        this.columnErrors = response.data;
                        this.filerowsLength = this.columnErrors.totalRowsCount;
                        if (response.data.importedRowsCount > 0 || response.data.overwrittenRowsCount > 0)
                            this.isDataImported = true;
                    }
                } else {
                    this.columnErrors = {
                        totalRowsCount: 0,
                        importedRowsCount: 0,
                        skippedRowsCount: 0,
                        overwrittenRowsCount: 0,
                        erroneousRowsCount: this.convertedFile.length,
                        errorRows: [],
                    };
                    this.convertedFile.forEach((el, i) => {
                        this.columnErrors.errorRows.push({
                            errorMessage: 'could not be imported',
                            rowNumber: i + 1,
                        });
                    });
                }
            } catch (error: any) {
                this.errorMessages.add(
                    error?.error?.error?.isUserError
                        ? error?.error?.error?.message
                        : 'SOMETHING_WENT_WRONG_TRY_LATER'
                );
                this.columnErrors = {
                    totalRowsCount: 0,
                    importedRowsCount: 0,
                    skippedRowsCount: 0,
                    overwrittenRowsCount: 0,
                    erroneousRowsCount: this.convertedFile.length,
                    errorRows: [],
                };
                this.convertedFile.forEach((el, i) => {
                    this.columnErrors.errorRows.push({
                        errorMessage: 'could not be imported',
                        rowNumber: i + 1,
                    });
                });
                this.columnErrors.errorRows.pop();
            } finally {
                this.isImportDone = true;
            }
        }, 1000);

        return true;
    }

    private stripString(str: string) {
        return str?.replace(/[^a-zA-Z0-9-]/g, '').toLowerCase();
    }

    resetImportReportTab() {
        this.isImportDone = false;
        if (this.progressBar)
            this.progressBar.nativeElement.style.width = '0%';
        //this.columnsDataFromAPI = [];
        //this.requiredColumns = [];
    }
}
