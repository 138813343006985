import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
    selector: 'tc-checkbox',
    templateUrl: './tc-checkbox.component.html',
    styleUrl: './tc-checkbox.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TcCheckboxComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => TcCheckboxComponent),
        },
    ],
})
export class TcCheckboxComponent implements OnInit, ControlValueAccessor, Validator {

    @Input() label: string = '';
    @Input() id: string | number = '';
    @Input() isRequired: boolean = false;
    @Input() isRequiredBlack: boolean = false;
    // @Input() isViewMode: boolean = false;
    @Input() disabled: boolean = false;
    @Input() readOnly: boolean = false;
    @Output() changeValue = new EventEmitter();

    myControl = new FormControl();

    ngOnInit(): void {

    }

    onChangeValue(event: any) {
        let value = event.currentTarget.checked;
        this.onChange(value);
        this.changeValue.next(value);
    }

    /*************************  ****************************/
    onTouched = () => { };
    onChange = (date: any) => { };
    touched = false;

    validate(c: AbstractControl): ValidationErrors | null {
        return null;
    }

    writeValue(val: string): void {
        this.myControl.setValue(val);
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    // setDisabledState?(isDisabled: boolean): void {
    //     this.disabled = isDisabled;
    // }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
    /*************************  ****************************/

}
