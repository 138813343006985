<div class="dialog-header">
    <div class="row">
        <div class="col-6">
            <h5 class="dialog-title">{{dialogData.title | translate}}</h5>
        </div>
    </div>
    <div class="close-icon" (click)="close()">
        <img (click)="close()" src="/assets/images/x-close-black.svg" />
    </div>
    <div class="report-tabs">
        <ul class="mt-3">
            <!-- <li *ngFor="let item of reportTabs" class="body-short-1" [class.active]="activeTab == item.index"
                (click)="goToTab(item.index)">
                {{item.title}}
            </li> -->
            <li class="body-short-1" [class.active]="activeTab == 1"
                [class.disabled]="isExportDone || isBusy > 0 || isExportFail" (click)="goToTab(1)">
                Select Tabs
            </li>
            <li class="body-short-1" [class.active]="activeTab == 2" [class.disabled]="activeTab == 1">
                Results
            </li>
        </ul>
    </div>
</div>
<div class="dialog-body">
    <ng-container *ngIf="activeTab == 1">
        <section class="select-tabs">
            <div class="row select-all">
                <div class="col-6">
                    <mat-checkbox [checked]="allComplete" [indeterminate]="someComplete()"
                        (change)="setAll($event.checked)">
                        {{dialogData.downloadReportOptions.name | translate}}
                    </mat-checkbox>
                </div>
                <div class="col-6 show-attachments" *ngIf="checkAttachment()">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="show-attachments"
                            [(ngModel)]="showAttachments" (ngModelChange)="showAttachmentsChange()"
                            [disabled]="isBusy > 0" />
                        <label class="form-check-label body-short-1" for="show-attachments">{{"SHOW_ATTACHMENTS" |
                            translate}}</label>
                    </div>
                </div>
            </div>
            <!-- <hr> -->
            <ul>
                <li *ngFor="let sub of dialogData.downloadReportOptions.sub;let i = index">
                    <ng-container *ngIf="!sub.isApplication;">
                        <ng-container *ngIf="sub.show">
                            <mat-checkbox [(ngModel)]="sub.checked" (ngModelChange)="updateAllComplete()">
                                <ng-container *ngIf="sub.isAttachment">
                                    <i class="icon-attachment-01" style="font-size: 1.3rem;padding-right: 0.4rem;"></i>
                                </ng-container>
                                {{sub.name |translate}}
                            </mat-checkbox>
                        </ng-container>
                    </ng-container>
                    <hr *ngIf="sub.isContainHr">
                </li>
                <li *ngIf="hasApplication">
                    <hr>
                    <div class="heading-7">{{"APPLICATION" |translate}}</div>
                </li>
                <li *ngFor="let sub of dialogData.downloadReportOptions.sub">
                    <ng-container *ngIf="sub.isApplication">
                        <ng-container *ngIf="sub.show">
                            <mat-checkbox [(ngModel)]="sub.checked" (ngModelChange)="updateAllComplete()">
                                <ng-container *ngIf="sub.isAttachment">
                                    <i class="icon-attachment-01" style="font-size: 1.3rem;padding-right: 0.4rem;"></i>
                                </ng-container>
                                {{sub.name |translate}}
                            </mat-checkbox>
                        </ng-container>
                    </ng-container>
                </li>
            </ul>
        </section>
    </ng-container>
    <!-- <ng-container>
        <div cdkDropList (cdkDropListDropped)="drop($event)">
            <div *ngFor="let item of columns" cdkDrag cdkDragBoundary=".sort-column">
                <div class="sort-column">
                    <img class="drag-image" src="../../../../../assets/images/drag-drop-small-icon.svg" cdkDragHandle />
                    <div class="body-short-1">
                        {{item.label |translate}}
                    </div>
                </div>
            </div>
        </div>
    </ng-container> -->
    <ng-container *ngIf="activeTab == 2">
        <ng-container *ngIf="!isExportFail">
            <div class="results">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <img src="/assets/images/research-paper.gif" class="front-img" alt="" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 front-title heading-5 d-flex justify-content-center">
                        {{ "REPORT_GENERATION_IN_PROGRESS" | translate}}
                    </div>
                    <div class="col-12 body-short-1 d-flex justify-content-center mt-2">
                        {{ "REPORT_GENERATION_IN_PROGRESS_DESC_1" | translate}}
                    </div>
                    <div class="col-12 body-short-1 d-flex justify-content-center">
                        {{ "REPORT_GENERATION_IN_PROGRESS_DESC_2" | translate}}
                    </div>

                    <ng-container *ngIf="!isExportDone">
                        <div class="col-12 d-flex justify-content-center mt-4">
                            <!-- <div class="progress">
                                <div #progressBar class="progress-bar width-100" role="progressbar" aria-valuenow="75"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div> -->
                            <div class="progress-bar-container">
                                <div class="progress-bar">
                                    <div class="progress-bar-value"></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isExportFail">
            <div class="results">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <img src="assets/images/amico.svg" class="front-img" alt="" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 front-title heading-5 d-flex justify-content-center">
                        Something went wrong!
                    </div>
                    <div class="col-12 body-short-1 d-flex justify-content-center">
                        We weren’t able to download your report. Please try again!
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
<div class="dialog-footer">
    <div class="app-actions-button">
        <button type="submit" class="primary-button-large" (click)="activeTab = 2;downloadReport()"
            [disabled]="isBusy > 0 || !checkSelected()" *ngIf="!isExportDone && !isExportFail">
            <span>{{"GENERATE_REPORT" | translate }}</span>
        </button>
        <button type="submit" class="secondary-button-large" (click)="close()" *ngIf="isExportDone || isExportFail">
            <span>{{"CLOSE" | translate }}</span>
        </button>
    </div>
</div>