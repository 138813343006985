import { TemplateRef } from '@angular/core';
import { ActionResponse, GetDataOptions } from './shared.model';


export enum AlertTypeEnum {
  Info,
  Warning,
  Danger,
  Success
}

export enum ActionTypeEnum {
  None,
  String,
  Icon
}

export enum ColumnTypeEnum {
  text,
  number,
  date,
  dropdown,
  autoComp,
  action
}

export enum SortTypeEnum {
  asc,
  desc
}

export enum FunctionTypeEnum {
  remove,
  custom
}

export interface CardDataModel {
  id?: number
}

export interface CardActionList<T> {
  functionType?: FunctionTypeEnum
  actionFunc?: ((item: T) => Promise<ActionResponse<T>>) | ((item: T) => ActionResponse<T>)
  label: string
  icon?: string
  class?: string
  isFuncAsync?: boolean
  hideAction?: (item: T) => boolean
}

export class ColumnSortOptions {
  key: string = ""
  sortType: string = ""
}

/***** ******/

export interface CardConfig<T> {
  class: string
  template: TemplateRef<any> | null
  allowCardAction?: boolean
  cardActionIcon?: string
  actionsList?: CardActionList<T>[]
  allowSearch: boolean
  allowPaging: boolean
  pageLength: number
  allowInfo: boolean
  allowWrapperDisabled?: boolean
  getData: (options: GetDataOptions) => Promise<ActionResponse<T>>
  removeRow?: (item: T) => Promise<ActionResponse<T>>
  cardHeaderAction?: TemplateRef<any> | null
}