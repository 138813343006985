<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">{{ "CHANGE_EMAIL" | translate }}</h5>
    </div>
    <div class="close-icon" (click)="close()">
        <img (click)="close()" src="/assets/images/x-close-black.svg" />
    </div>
</div>
<div class="dialog-body" [ngClass]="isBusy ? 'wrapper-disabled' : ''">
    <ng-container *ngIf="step == 1;else step2">
        <div class="body-short-1 mt-2 mb-4">
            {{"CHANGE_EMAIL_DESC" |translate: {email: dialogData.oldEmail} }}
        </div>
    </ng-container>
    <ng-template #step2>
        <div class="body-short-1 mt-2 mb-2">
            {{"THE_USER_MUST_VERIFY_THE_NEW_E_MAIL_ADDRESS_BEFORE_LOGGING_IN" |translate}}
        </div>
        <form [formGroup]="form">
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="form-group">
                        <label for="new-email" [ngClass]="submitted && email?.errors ? 'required-text': ''">
                            {{ "NEW_EMAIL" | translate }}
                        </label>
                        <span class="required">*</span>
                        <input type="email" class="form-control" id="new-email" maxlength="320"
                            formControlName="email" [ngClass]="submitted && email?.errors? 'error is-invalid': ''"
                            placeholder="{{ 'NEW_EMAIL' | translate }}" />
                        <div *ngIf="submitted && email?.errors" class="validation-msg">
                            <div *ngIf="email?.errors?.required" class="validation-error">
                                {{ "NEW_EMAIL" | translate }}
                                {{ "IS_REQUIRED" | translate }}
                            </div>
                            <div *ngIf="email?.errors?.email" class="validation-error">
                                {{ "INVALID_EMAIL" | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>

</div>
<div class="dialog-footer">
    <div class="row">
        <div class="col-12">
            <div class="invalid-form-warning" *ngIf="
                            form.invalid && submitted
                        ">
                <div class="invalid-icon"></div>
                <div class="warning-message">
                    {{ "GLOBAL_FORM_ERROR" | translate }}
                </div>
            </div>
        </div>
        <div class="col-12">
            <ng-container *ngIf="errorMessages.size > 0">
                <div class="invalid-form-warning" *ngFor="let message of errorMessages">
                    <div class="invalid-icon"></div>
                    <div class="warning-message">
                        {{ message | translate }}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="app-actions-button">
        <button type="button" class="app-close-modal-button" (click)="close()">
            {{ "CANCEL" | translate }}
        </button>
        <button type="button" class="primary-button-large" (click)="continue()" *ngIf="step == 1">
            <span>{{ "CONTINUE" | translate }}</span>
        </button>
        <button type="button" class="primary-button-large" (click)="save()" [disabled]="isBusy" *ngIf="step == 2">
            <span>{{ "SAVE" | translate }}</span>
        </button>
    </div>
</div>