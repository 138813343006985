import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SharedTemplatesService } from 'src/app/shared/services/shared-templates.service';

@Component({
    selector: 'app-welcome-to-truckin-central',
    templateUrl: './welcome-to-truckin-central.component.html',
    styleUrl: './welcome-to-truckin-central.component.scss'
})
export class WelcomeToTruckinCentralComponent {

    constructor(
        public sharedTemplatesService: SharedTemplatesService<WelcomeToTruckinCentralComponent>,
        private dialogRef: MatDialogRef<WelcomeToTruckinCentralComponent>
    ) { }

    close(action: string = 'close') {
        this.dialogRef.close(action);
    }
}
