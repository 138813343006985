<input
    type="text"
    class="form-control autocomplete-address"
    [class.filed-error]="filedError"
    [id]="id"
    [(ngModel)]="autocompleteInput"
    #addresstext
    (keydown.enter)="$event.preventDefault()"
    placeholder="{{ placeholder | translate }}"
    (ngModelChange)="updateValue()"
    [readonly]="readonly"
    (blur)="blurInput($event)"
    [maxlength]="maxlength"
    [disabled]="disabled"
/>
