import { Component, Inject, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SharedApiService } from '../../services/shared-api.service';
import { SignatureDetials } from 'src/app/core/models/shared.model';
import { FileService } from '../../services/file.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-signature-details',
    templateUrl: './signature-details.component.html',
    styleUrls: ['./signature-details.component.scss'],
})
export class SignatureDetailsComponent implements OnInit {
    signatureDetials: SignatureDetials | null = null;
    signatureImgLink: string = '';

    constructor(
        private dialogRef: MatDialogRef<SignatureDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { objectId: number },
        private sharedAPIServie: SharedApiService,
        private fileService: FileService
    ) {}

    async ngOnInit(): Promise<void> {
        this.signatureDetials = (await this.getSignatureDetials()).data;
        if (this.signatureDetials?.signedDocumentId) {
            const response = await lastValueFrom(this.fileService
                .getFile(this.signatureDetials.signedDocumentId));
            this.signatureImgLink = URL.createObjectURL(response);
        }
    }

    cancel() {
        this.dialogRef.close(null);
    }

    async getSignatureDetials() {
        const response = await this.sharedAPIServie.getSignatureDetials(
            this.data.objectId
        );
        return response;
    }
}
