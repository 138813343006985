import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-table-errors',
    templateUrl: './app-table-errors.component.html',
    styleUrls: ['./app-table-errors.component.scss']
})
export class AppTableErrorsComponent {

    @Input() errors: any[] = [];
    @Input() warnings: any[] = [];

}
