import {
    Company,
    EmployeeCompany,
} from './../../../../core/models/company.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextService } from 'src/app/shared/services/context.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { DropdownList } from 'src/app/shared/model/shared.model';

@Component({
    selector: 'app-select-company',
    templateUrl: './select-company.component.html',
    styleUrls: ['./select-company.component.scss'],
})
export class SelectCompanyComponent implements OnInit {
    userCompanies: DropdownList[] = [];
    employeeCompanies: EmployeeCompany[] = [];
    compnayLocationsList: DropdownList[] = [];
    selectedCompany: EmployeeCompany | null = null;


    constructor(
        public contextService: ContextService,
        public dialog: MatDialogRef<SelectCompanyComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: {
        closeButton:boolean;
        },
    ) { }

    ngOnInit(): void {
        let companies = this.contextService.employeeCompanies$.getValue();
        companies.forEach((company) => {
            this.userCompanies.push({
                text: company.name,
                value: company.id!,
            });
        });
        this.selectCompanyForm.patchValue({
            company: this.userCompanies[0].value,
            // companyLocation: companies[0]?.employeeCompanyLocations[0].companyLocationID,
        });
        if (companies.length == 1) {
            this.changeCompany(companies[0].id);
        }
    }

    selectCompanyForm = new UntypedFormGroup({
        company: new UntypedFormControl(''),
        companyLocation: new UntypedFormControl(''),
    });

    get company() {
        return this.selectCompanyForm.get('company');
    }
    checkValidation() {
        if (this.company?.value) return true;
        else return false;
    }

    changeCompany(companyId: string | number | null) {
        this.selectedCompany =
            this.contextService.employeeCompanies$
                .getValue()
                .find((item) => item.id == companyId) ?? null;
        // this.contextService.companyId$.next(Number(companyId!) ?? 0);
        if (this.selectedCompany) {
            this.compnayLocationsList = [];
            // selectedCompany.employeeCompanyLocations.forEach((e) => {
            //     this.compnayLocationsList.push(
            //         {
            //             text: e.locationName,
            //             value: e.companyLocationID
            //         }
            //     )
            // });
            // this.selectCompanyForm.patchValue({
            // companyLocation: this.compnayLocationsList[0].value,
            // });
        }
    }

    onSubmit() {
        let formValue = this.selectCompanyForm.value;
        this.contextService.companyId$.next(this.selectedCompany?.id ?? 0);
        this.changeCompany(formValue.company);
        this.dialog.close();
        this.contextService.selectCompany(this.selectedCompany?.id ?? 0);
        this.contextService.companyName$.next(this.selectedCompany?.name ?? "Select Company");
        this.contextService.companyLogoId$.next(this.selectedCompany?.logoDocumentId ?? 0);
    }
    close(){
        this.dialog.close("close");
    }
}
