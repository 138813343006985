import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { DropdownList, GetDataOptions, TCDropdown } from '../../model/shared.model';

@Component({
    selector: 'tc-autocomplete-dropdown',
    templateUrl: './tc-autocomplete-dropdown.component.html',
    styleUrl: './tc-autocomplete-dropdown.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TcAutocompleteDropdownComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => TcAutocompleteDropdownComponent),
        },
    ],
})
export class TcAutocompleteDropdownComponent<T> implements OnInit, ControlValueAccessor, Validator {

    

    constructor(
        private cdr: ChangeDetectorRef
    ) { }

    @Input() placeholder: string = 'CHOOSE_AN_OPTION';
    @Input() dropdownList: DropdownList[] = [];
    @Output() changeValueEvent = new EventEmitter<string | number | null>();
    tcDropdownConfig: TCDropdown<T> | null = null;
    @Input() set tcDropdownOptions(data: TCDropdown<T> | null) {
        if (data) {
            this.tcDropdownConfig = data;
            this.getList();
        }
    }
    @Input() set readonly(data: boolean) {
        if (data) {
            this.myControl.disable();
        } else {
            this.myControl.enable();
        }
    }
    filteredDropdownList: any[] = [];
    optionsApi: GetDataOptions = {
        sort: '',
        pageSize: 222222224,
        pageIndex: 0,
        searchString: '',
    };

    isBusy: boolean = false;
    myControl = new FormControl();
    currentValue: any;

    ngOnInit() {
    }

    lodaingMessage() {
        let elm = document.getElementsByClassName("p-dropdown-empty-message");
        if (elm[0] && this.isBusy) {
            elm[0].innerHTML = "Loading ...";
        } else if (elm[0]) {
            elm[0].innerHTML = "No Result Found";
        }
        this.cdr.detectChanges();
    }



    onTouched = () => { };
    onChange = (date: any) => { };
    touched = false;
    disabled = false;

    validate(c: AbstractControl): ValidationErrors | null {
        return null;
    }

    writeValue(val: string): void {
        this.currentValue = val;
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    async getList() {
        try {
            this.isBusy = true;
            this.lodaingMessage();
            if (this.tcDropdownConfig) {
                let response = await this.tcDropdownConfig!.getData(this.optionsApi)
                if (response) {
                    if (response.autoCompList) {
                        if (response.autoCompList){
                            this.dropdownList = response.autoCompList;
                            this.filteredDropdownList = response.autoCompList;
                            this.myControl.setValue(this.currentValue)
                        }
                    }
                }
            }
        }
        catch (error) {

        } finally {
            this.isBusy = false;
            this.lodaingMessage();
        }
    }

    filterCountry(searchString: string) {
        let filtered: any[] = [];
        let query = searchString;
        if (query) {
            for (let i = 0; i < (this.dropdownList as any[]).length; i++) {
                let country = (this.dropdownList as any[])[i];
                if (country.text.toLowerCase().includes(query.toLowerCase())) {
                    filtered.push(country);
                }
            }
            this.filteredDropdownList = filtered;
        } else {
            this.filteredDropdownList = this.dropdownList;
        }
    }

    onChangeValue(event: any) {
        let value = this.myControl.value.value;
        this.onChange(value);
    }


}
