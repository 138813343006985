<!-- <div class="tc-autocomplete-dropdown">
    <div class="autocomplete-icon-box" (click)="autoComplete.show()">
        <i class="icon-chevron-down" ></i>
    </div>
    <p-autoComplete appendTo="body" #autoComplete (onFocus)="autoComplete.show()" placeholder="Hint: type 'a'" [(ngModel)]="selectedCountryAdvanced"
        [suggestions]="filteredCountries" (ngModelChange)="filterCountry($event);" field="name">
        <ng-template let-country pTemplate="item">
            <div class="heading-7">{{ country.name }}</div>
        </ng-template>
    </p-autoComplete>
</div> -->
<div class="tc-dropdown">
    <!-- <div class="autocomplete-icon-box" (click)="autoComplete.show()">
        <i class="icon-chevron-down"></i>
    </div> -->
    <p-dropdown appendTo="body" #autoComplete (ngModelChange)="filterCountry($event);" [options]="filteredDropdownList" (click)="autoComplete.show()"
        [formControl]="myControl" optionLabel="text" [showClear]="true" (click)="lodaingMessage()"
        placeholder="{{placeholder | translate}}" (onChange)="onChangeValue($event)" [editable]="true"
        [virtualScroll]="true" [virtualScrollItemSize]="38">
        <ng-template pTemplate="selectedItem">
            <div class="flex align-items-center gap-2" *ngIf="myControl.value">
                <div>{{ myControl.value['text'] }}</div>
            </div>
        </ng-template>
        <ng-template let-item pTemplate="item">
            <div>
                <div class="heading-7">{{ item.text }}</div>
                <div class="label-1" [innerHTML]="item.description"></div>
            </div>
        </ng-template>
    </p-dropdown>
</div>