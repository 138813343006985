<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">
            {{ "IMPORT_LIST" | translate }}
        </h5>
    </div>
    <div class="close-icon" (click)="cancel()">
        <img (click)="cancel()" src="/assets/images/x-close-black.svg" />
    </div>
    <ng-container *ngIf="isStarted">
        <div class="row">
            <ul class="nav nav-tabs mt-3" id="myTab" role="tablist" style="border-bottom: 0;">
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link active"
                        id="upload-file-tab-button"
                        data-bs-toggle="tab"
                        data-bs-target="#uploadFileTab"
                        type="button"
                        role="tab"
                        aria-controls="uploadFileTab"
                        aria-selected="true"
                        [disabled]="tabCount < 0"
                        (click)="tabCount = 0"
                    >
                        {{ "IMPORT_SOURCE" | translate }}
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="column-mapping-tab-button"
                        #columnMappingTabButton
                        data-bs-toggle="tab"
                        data-bs-target="#columnMappingTab"
                        type="button"
                        role="tab"
                        aria-controls="columnMappingTab"
                        aria-selected="false"
                        (click)="resetImportReportTab();tabCount = 1"
                        [disabled]="!importedFile && tabCount < 1"
                    >
                        {{ "SELECT_COLUMNS" | translate }}
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="import-report-tab-button"
                        #importReportTabButton
                        data-bs-toggle="tab"
                        data-bs-target="#importReportTab"
                        type="button"
                        role="tab"
                        aria-controls="importReportTab"
                        aria-selected="false"
                        [disabled]="tabCount < 2"
                    >
                        {{ "IMPORT" | translate }}
                    </button>
                </li>
            </ul>
        </div>
    </ng-container>
</div>

<div class="dialog-body">
    <ng-container *ngIf="!isStarted; else started">
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <img
                    src="/assets/images/import-front.svg"
                    class="front-img"
                    alt=""
                />
            </div>
        </div>
        <div class="row">
            <div
                class="col-12 front-title heading-5 d-flex justify-content-center"
            >
                {{ "IMPORT_YOUR_DATA" | translate }}
            </div>
            <div
                class="col-12 body-short-1 mb-32 d-flex justify-content-center"
            >
                {{ "IMPORT_YOUR_LIST_FROM_EXCEL_OR_CSV" | translate }}
            </div>
        </div>
        <!-- <div class="row app-actions-button">
            <div class="col-md-3">
                <button (click)="isStarted = true" class="primary-button-large">
                    {{ "GET_STARTED" | translate }}
                </button>
            </div>
        </div> -->
    </ng-container>
    <ng-template #started>
        <div class="tab-content" id="myTabContent">
            <div
                class="tab-pane fade show active"
                id="uploadFileTab"
                role="tabpanel"
                aria-labelledby="upload-file-tab-button"
            >
                <!-- <div class="row">
                    <div class="col-12 source-title body-short-2">
                        {{
                            "TO_SUCCESSFULY_IMPORT_YOUR_CSV_FILE_MAKE_SURE_ALL_MANDATORY_COLUMNS_EXIST_IN_THE_CSV_FILE_FIRST_NAME_LAST_NAME_FIRST_NAME_LAST_NAME"
                                | translate
                        }}
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-12">
                        <div
                            draggable="true"
                            class="file-container"
                            (drop)="onDrop($event)"
                            (dragover)="onDregOver($event)"
                        >
                            <input
                                type="file"
                                #fileImport
                                (change)="onFileChange($event)"
                                class="d-none"
                            />
                            <div class="file-content">
                                <img
                                    src="{{
                                        importedFile
                                            ? '/assets/images/check-icon-app-process.svg'
                                            : '/assets/images/import-file-blue.svg'
                                    }}"
                                    alt=""
                                    class="{{
                                        importedFile
                                            ? 'check-icon-uploaded'
                                            : ''
                                    }}"
                                />
                                <ng-container
                                    *ngIf="!importedFile; else fileUploaded"
                                >
                                    <div class="body-short-1">
                                        {{
                                            "DRAG_&_DROP_FILE_HERE" | translate
                                        }}
                                    </div>
                                    <div class="helper-text-1">
                                        {{
                                            "YOU_CAN_UPLOAD_CSV_XLS_OR_XLSX_FILE"
                                                | translate
                                        }}
                                    </div>
                                    <div class="helper-text-1">
                                        {{ "FILE_LIMIT_2_MB" | translate }}
                                    </div>
                                </ng-container>

                                <ng-template #fileUploaded>
                                    <div class="body-short-1">
                                        {{
                                            "FILE_UPLOADED_SUCCESSFULLY"
                                                | translate
                                        }}
                                    </div>

                                    <div class="helper-text-1">
                                        {{
                                            importedFile?.name +
                                                " " +
                                                ("HAS_BEEN_UPLOADED"
                                                    | translate)
                                        }}
                                    </div>
                                </ng-template>
                            </div>
                            <div class="col-md-3" style="text-align: center;">
                                <button
                                    (click)="fileImport.click()"
                                    class="secondary-button-large"
                                >
                                    {{ "UPLOAD_FILE" | translate }}
                                </button>
                            </div>
                            <ng-container *ngIf="typeNotSupported">
                                <div class="validation-msg mt-4">
                                    {{ "FILE_TYPE_NOT_SUPPORTED" | translate }}
                                </div>
                            </ng-container>
                            <!-- <div #dragOverlay class="drag-overlay">
                                {{ "DROP_FILE_HERE" | translate }}
                            </div> -->
                        </div>
                    </div>
                </div>
                <div
                    class="row mt-32 justify-content-between app-actions-button"
                >
                    <div class="col-md-6">
                        <ng-container *ngIf="convertedFile.length > 500">
                            <div class="import-warning">
                                <img
                                    src="/assets/images/danger-icon.svg"
                                    alt=""
                                />
                                <div>
                                    <div class="heading-7">
                                        {{ "FILE_TOO_LARGE" | translate }}
                                    </div>
                                    <div class="body-short-1">
                                        {{
                                            "WE_ARE_NOT_ABLE_TO_IMPORT_LISTS_WITH_MORE_THAN_500_ITEMS"
                                                | translate
                                        }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <!-- <div class="col-md-3">
                        <button
                            [disabled]="!importedFile"
                            class="primary-button-large"
                            (click)="navColumnMappingTabButton()"
                        >
                            {{ "CONTINUE" | translate }}
                        </button>
                    </div> -->
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="columnMappingTab"
                role="tabpanel"
                aria-labelledby="column-mapping-tab-button"
            >
                <div class="table-container">
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    {{ "COLUMN_HEADERS_FROM_FILE" | translate }}
                                </td>
                                <td>{{ "AVAILABLE_COLUMNS" | translate }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let column of fileColumns">
                                <tr>
                                    <td>{{ column.text }}</td>
                                    <td>
                                        <app-dropdown
                                            id="import-list-drop-down"
                                            [placeholder]="'SELECT_COLUMN'"
                                            [dropdownList]="tableColumns"
                                            [control]="f[column.value!]"
                                            (changeValueEvent)="
                                                columnDropdownChange($event)
                                            "
                                        >
                                            <!-- [dropdownList]="timeZoneList" [control]="timeZone" -->
                                        </app-dropdown>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
                <div class="row">
                    <form [formGroup]="importListForm">
                        <div class="col-12">
                            <div class="form-group">
                                <label
                                    >{{
                                        "IN_CASE_THE_SPREADSHEET_INCLUDES_ITEMS_ALREADY_EXIST"
                                            | translate
                                    }}
                                </label>

                                <div class="form-check">
                                    <input
                                        id="import-list-skip"
                                        class="form-check-input"
                                        type="radio"
                                        value="skip"
                                        formControlName="alreadyExistItems"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="import-list-skip"
                                    >
                                        {{ "SKIP" | translate }}
                                    </label>
                                </div>

                                <div class="form-check">
                                    <input
                                        id="import-list-overwrite"
                                        class="form-check-input"
                                        type="radio"
                                        value="overwrite"
                                        formControlName="alreadyExistItems"
                                    />
                                    <label
                                        class="form-check-label"
                                        for="import-list-overwrite"
                                    >
                                        {{ "OVERWRITE" | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <!-- <ng-container *ngIf="isMissingRequiredFields">
                    <div class="row">
                        <div class="col-12">
                            <div class="invalid-form-warning">
                                <div class="invalid-icon"></div>
                                <div class="warning-message">
                                    {{
                                        "MISSING_REQUIRED_FIELDS"
                                            | translate: { requiredFields: missingRequiredFields }
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="showRequiredFields">
                    <div class="row">
                        <div class="col-12">
                            <div class="invalid-form-warning">
                                <div class="invalid-icon"></div>
                                <div class="warning-message">
                                    {{
                                        "PLEASE_MAKE_SURE_THAT_ALL_MANDATORY_COLUMNS_WITH_STAR_BESIDES_THE_WORD_IN_THE_AVAILABLE_COLUMNS_PANE"
                                            | translate
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container> -->
                <!-- <div class="row app-actions-button">
                    <div class="col-md-3">
                        <button
                            class="primary-button-large"
                            (click)="onImportList()"
                        >
                            {{ "IMPORT_LIST" | translate }}
                        </button>
                    </div>
                </div> -->
            </div>
            <div
                class="tab-pane fade"
                id="importReportTab"
                role="tabpanel"
                aria-labelledby="import-report-tab-button">
                <ng-container
                    *ngIf="
                        errorMessages.size == 0 &&
                        columnErrors.errorRows.length == 0 &&
                        columnErrors.erroneousRowsCount == 0 &&
                        columnErrors.skippedRowsCount == 0 &&
                        columnErrors.overwrittenRowsCount == 0">
                    <div class="row">
                        <div class="col-12 d-flex justify-content-center">
                            <img
                                src="{{
                                    isImportDone
                                        ? '/assets/images/import-done-celebration.svg'
                                        : '/assets/images/list-proccessing.svg'
                                }}"
                                class="front-img"
                                alt=""
                            />
                        </div>
                    </div>
                    <div class="row mb-32">
                        <div
                            class="col-12 front-title heading-5 d-flex justify-content-center"
                        >
                            {{
                                (isImportDone
                                    ? "ALL_DONE_!"
                                    : "YOUR_LIST_IS_BEING_PROCCESSED"
                                ) | translate
                            }}
                        </div>
                        <div
                            class="col-12 body-short-1 d-flex justify-content-center"
                        >
                            {{
                                (isImportDone
                                    ? "YOUR_LIST_HAS_BEEN_IMPORTED_SUCCESSFULLY"
                                    : "YOUR_LIST_WILL_BE_IMPORTED_SOON_PLEASE_WAIT"
                                ) | translate
                            }}
                        </div>

                        <ng-container *ngIf="!isImportDone">
                            <div
                                class="col-12 d-flex justify-content-center mb-32"
                            >
                                <div class="progress">
                                    <div
                                        #progressBar
                                        class="progress-bar width-100"
                                        role="progressbar"
                                        aria-valuenow="75"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="errorMessages.size > 0 ||
                            columnErrors.errorRows.length > 0 ||
                            columnErrors.erroneousRowsCount > 0 ||
                            columnErrors.skippedRowsCount > 0 ||
                            columnErrors.overwrittenRowsCount > 0">
                    <div class="row justify-content-center">
                        <div class="heading-5 d-inline">
                            {{ ((errorMessages.size > 0) ? "IMPORT_FAILED" : "IMPORT_COMPLETED") | translate }}
                        </div>
                        <div class="row" *ngIf="errorMessages.size > 0">
                            <div class="col-12 d-flex justify-content-center">
                                <img
                                    src="{{ '/assets/images/amico.svg' }}"
                                    width="350px"
                                    class="front-img"
                                    alt="" />
                            </div>
                        </div>
                        <div class="row justify-content-center error-title">
                            <div class="col-12 d-inline errors-col">
                                <div class="body-short-1" *ngIf="(+columnErrors.importedRowsCount) > 0">
                                    {{
                                        columnErrors.importedRowsCount + " " + ("OF" | translate) +
                                            " " + filerowsLength + " " + ("ROWS_WERE_SUCCESSFULLY_IMPORTED" | translate)
                                    }}
                                </div>

                                <div class="body-short-1" *ngIf="(+columnErrors.skippedRowsCount) > 0">
                                    {{
                                        columnErrors.skippedRowsCount +
                                            " " +
                                            ("OF" | translate) +
                                            " " +
                                            filerowsLength +
                                            " " +
                                            ("ROWS_WERE_SKIPPED"
                                                | translate)
                                    }}
                                </div>

                                <div class="body-short-1" *ngIf="(+columnErrors.overwrittenRowsCount) > 0">
                                    {{
                                        columnErrors.overwrittenRowsCount +
                                            " " +
                                            ("OF" | translate) +
                                            " " +
                                            filerowsLength +
                                            " " +
                                            ("ROWS_WERE_OVERWRITTEN"
                                                | translate)
                                    }}
                                </div>

                                <div class="body-short-1 failed" *ngIf="(+columnErrors.erroneousRowsCount) > 0">
                                    {{
                                        columnErrors.erroneousRowsCount +
                                            " " +
                                            ("OF" | translate) +
                                            " " +
                                            filerowsLength +
                                            " " +
                                            ("ROWS_FAILED_TO_IMPORT"
                                                | translate)
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="errors-cont" *ngIf="columnErrors.errorRows.length > 0">
                        <ng-container
                            *ngFor="let error of columnErrors.errorRows">
                            <div class="row justify-content-center">
                                <div class="col-md-8">
                                    <div class="import-fail-warning">
                                        <img
                                            src="/assets/images/warning-triangle-icon.svg"
                                            alt=""
                                        />
                                        <div class="text-container">
                                            <div class="heading-7">
                                                {{
                                                    ("UNABLE_TO_IMPORT_ROW" | translate) + ": " + error.rowNumber
                                                }}
                                            </div>
                                            <div class="body-short-1">
                                                {{ error.errorMessage }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <!-- <div class="row">
                        <div
                            class="invalid-form-warning"
                            *ngFor="let message of errorMessages"
                        >
                            <div class="invalid-icon"></div>
                            <div class="warning-message">
                                {{ message | translate }}
                            </div>
                        </div>
                    </div> -->
                </ng-container>
                <!-- <div class="row app-actions-button">
                    <div class="col-md-3">
                        <ng-container
                            *ngIf="
                                isImportDone ||
                                columnErrors.errorRows.length > 0 ||
                                errorMessages.size > 0
                            "
                        >
                            <button
                                (click)="confirm()"
                                class="primary-button-large"
                            >
                                {{ "CLOSE" | translate }}
                            </button>
                        </ng-container>
                    </div>
                </div> -->
            </div>
        </div>
    </ng-template>
</div>
<div class="dialog-footer">
    <ng-container *ngIf="isMissingRequiredFields && tabCount == 1">
        <div class="row">
            <div class="col-12">
                <div class="invalid-form-warning">
                    <div class="invalid-icon"></div>
                    <div class="warning-message">
                        {{
                            "MISSING_REQUIRED_FIELDS"
                                | translate: { requiredFields: missingRequiredFields }
                        }}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showRequiredFields && tabCount == 1">
        <div class="row">
            <div class="col-12">
                <div class="invalid-form-warning">
                    <div class="invalid-icon"></div>
                    <div class="warning-message">
                        {{
                            "PLEASE_MAKE_SURE_THAT_ALL_MANDATORY_COLUMNS_WITH_STAR_BESIDES_THE_WORD_IN_THE_AVAILABLE_COLUMNS_PANE"
                                | translate
                        }}
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div
            class="invalid-form-warning"
            *ngFor="let message of errorMessages"
        >
            <div class="invalid-icon"></div>
            <div class="warning-message">
                {{ message | translate }}
            </div>
        </div>
    </div>
    <div class="app-actions-button">
        <ng-container *ngIf="!isStarted">
            <button (click)="isStarted = true" class="app-save-changes-modal-button">
                {{ "GET_STARTED" | translate }}
            </button>
        </ng-container>
        <ng-container *ngIf="isStarted && tabCount == 0">
            <button
                [disabled]="!importedFile"
                class="app-save-changes-modal-button"
                (click)="navColumnMappingTabButton()"
                >
                {{ "CONTINUE" | translate }}
            </button>           
        </ng-container>
        <ng-container *ngIf="isStarted && tabCount == 1">
            <button
                            class="app-save-changes-modal-button"
                            (click)="onImportList()"
                        >
                            {{ "IMPORT_LIST" | translate }}
                        </button>       
        </ng-container>
            <ng-container
            *ngIf="isStarted && tabCount == 2 &&
                (isImportDone ||
                columnErrors.errorRows.length > 0 ||
                errorMessages.size > 0)
            "
        >
            <button
                (click)="confirm()"
                class="app-save-changes-modal-button"
            >
                {{ "CLOSE" | translate }}
            </button>
    </ng-container>
    </div>
</div>
