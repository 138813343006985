<div [class.view-mode]="viewMode" class="input-container">
    <div class="selected-item">
        <ng-container *ngFor="let item of listOfValues">
            <div>
                <span class="label-1 item" [class.item-readonly]="item.isReadOnly">{{item.customValueLabel ? item.customValueLabel : item.text }}
                    <span class="remove" *ngIf="!item.isReadOnly && !viewMode" (click)="removeItem(item)">
                        <i class="icon-x"></i>
                    </span>
                </span>
            </div>

        </ng-container>
        <div class="body-short-1 add-item-plus" (click)="addItem()" *ngIf="!showInput && !viewMode">
            +
        </div>

        <div class="dropdown" #dropdownContainer *ngIf="showInput">
            <ng-container *ngIf="isPhoneNumber;else default">
                <input #inputSearch id="autocomplete-dropdown-input-{{ id }}" [formControl]="myControl" appPhoneNumberMask
                (click)="inputClick()" [class.input-table-editable]="isTableEditable"
                [class.input-table-editable-error]="filedError"
                [class.input-has-tooltip]="tooltipTemplate && myControl.value" (keyup)="inputClick()" (keydown)="handleInputKey($event)"
                (keyup.enter)="enterClick()" [readonly]="readonly || viewMode" autocomplete="off"
                [placeholder]="placeholder | translate" (input)="onInputSearchChange($event)" [maxlength]="maxlength" />
            </ng-container>
            <ng-template #default>
                <input #inputSearch id="autocomplete-dropdown-input-{{ id }}" [formControl]="myControl"
                (click)="inputClick()" [class.input-table-editable]="isTableEditable"
                [class.input-table-editable-error]="filedError"
                [class.input-has-tooltip]="tooltipTemplate && myControl.value" (keyup)="inputClick()" (keydown)="handleInputKey($event)"
                (keyup.enter)="enterClick()" [readonly]="readonly || viewMode" autocomplete="off"
                [placeholder]="placeholder | translate" (input)="onInputSearchChange($event)" [maxlength]="maxlength" />
            </ng-template>
            <span class="tooltip-custom" *ngIf="tooltipTemplate && myControl.value">
                <img src="/assets/images/info-icon-empty.svg" alt="" [ngbPopover]="tooltip_template"
                    triggers="mouseenter" placement="end" />
                <ng-template #tooltip_template>
                    <div [ngStyle]="{ width: tooltipWidth }">
                        <ng-container *ngTemplateOutlet="
                        tooltipTemplate;
                        context: { data: dropdownSelectedObj }
                    ">
                        </ng-container>
                    </div>
                </ng-template>
            </span>
            <span class="x-close" [class.x-close-editable-table]="isTableEditable"
                [class.x-close-has-tooltip]="tooltipTemplate && myControl.value"
                *ngIf="myControl.value && !readonly && !viewMode" (click)="deleteSearch()"><img
                    src="assets/images/x-close-black.svg" /></span>
            <!-- (click)="arrowClick()" -->
            <span class="dropdown-arrow" *ngIf="!readonly && !viewMode"
                [class.dropdown-arrow-has-tooltip]="tooltipTemplate && myControl.value"><img
                    src="/assets/images/dropdown-icon.svg" /></span>
            <ul class="dropdown-menu-static" id="dropdown-menu" #list [ngStyle]="{ 'max-height': height }"
                [class.d-none]="!showDropdownMenu || readonly || viewMode || optionsList.length == 0" (keydown)="handleKeydown($event)">
                <!-- (filteredOptions | async)?.length == 0 || -->
                <div class="list-item" (scroll)="onScroll($event)">
                    <ng-container *ngIf="
                    showDropdownMenu &&
                    !readonly &&
                    !viewMode
                ">
                        <ng-container *ngIf="this.autocompleteDropdownConfig?.searchFromApi;else localSearch">
                            <ng-container *ngFor="let option of optionsList;let i = index">
                                <li *ngIf="!option.selected" (click)="changeValue(option)" id="menu-item-{{ i + 1 }}"
                                    [attr.value]="option.value">
                                    <a class="dropdown-item" href="javascript:void(0)"
                                        (keyup.enter)="changeValue(option)">
                                        <span class="body-short-1">{{ option.text | translate }}</span>
                                        <span class="helper-text-1 item-desc" *ngIf="option.description"
                                            [innerHTML]="option.description"></span>
                                    </a>
                                    <div class="line">
                                        <hr />
                                    </div>
                                </li>
                            </ng-container>
                        </ng-container>
                        <ng-template #localSearch>
                            <ng-container *ngFor="let option of optionsList | myfilter: myControl.value let i = index">
                                <li *ngIf="!option.selected" (click)="changeValue(option)" id="menu-item-{{ i + 1 }}"
                                    [attr.value]="option.value">
                                    <a class="dropdown-item" href="javascript:void(0)"
                                        (keyup.enter)="changeValue(option)">
                                        <span class="body-short-1">{{ option.text | translate }}</span>
                                        <span class="helper-text-1 item-desc" *ngIf="option.description"
                                            [innerHTML]="option.description"></span>
                                    </a>
                                    <div class="line">
                                        <hr />
                                    </div>
                                </li>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                    <li class="spinner" *ngIf="isLoading"></li>
                    <li *ngIf="autocompleteDropdownConfig && autocompleteDropdownConfig.allowAddNew"
                        class="custom-add-item" (click)="addNew()">
                        <div class="line">
                            <hr />
                        </div>
                        <a class="dropdown-item" href="javascript:void(0)">
                            <i class="icon-plus icon-s-m"></i>
                            <span
                                class="body-short-1">{{autocompleteDropdownConfig.addNewLabel ?
                                (autocompleteDropdownConfig.addNewLabel | translate) : ""}}</span>
                        </a>
                    </li>
                </div>
            </ul>
            
        </div>
    </div>
</div>
<div class="row mt-2">
    <div
        class="invalid-form-warning"
        *ngFor="let message of validationMessages"
    >
        <!-- <div class="invalid-icon"></div> -->
        <div class="warning-message">
            {{ message | translate }}
        </div>
    </div>
</div>