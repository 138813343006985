<div class="dialog-header">
    <div class="row">
        <span class="dialog-title">{{ "SAVE_CHANGES" | translate }}?</span>
    </div>
</div>
<div class="dialog-body">
    <div class="row mb-4">
        <div class="body-short-2">
            {{"SAVE_CHANGES_DESC" |translate}}
        </div>
    </div>
</div>
<div class="dialog-footer">
    <div class="app-actions-button">
        <button class="app-close-modal-button" (click)="cancel()">{{"CANCEL" |translate}}</button>
        <button class="app-close-modal-button" (click)="discard()">{{"LEAVE" |translate}}</button>
        <button class="app-save-changes-modal-button" (click)="save()">{{"SAVE" |translate}}</button>
    </div>
</div>