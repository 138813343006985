import { lastValueFrom } from 'rxjs';
import { PhoneNumberService } from './../../services/phone-number.service';
import {
    TableActionList,
    ActionTypeEnum,
    ColumnSortOptions,
    ColumnTypeEnum,
    TableDataModel,
    FunctionTypeEnum,
} from './../../model/app-table.model';
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
} from '@angular/core';
import { ThrottleCallService } from '../../services/throttle-call.service';
import {
    ColumnTableEditable,
    TableOptionsEditable,
} from '../../model/table-editable.model';
import { TranslateService } from '@ngx-translate/core';
import { ActionResponse, AddressObject, GetDataOptions } from '../../model/shared.model';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { PagingData } from 'src/app/core/models/api-response.model';

@Component({
    selector: 'app-table-editable',
    templateUrl: './app-table-editable.component.html',
    styleUrls: ['./app-table-editable.component.scss'],
})
export class AppTableEditableComponent<T extends TableDataModel>
    implements OnInit, AfterViewInit {
    currentPage: number = 0;
    maxSize: number = 7;
    showFilterBox: boolean = false;
    constructor(
        public phoneNumberService: PhoneNumberService,
        private translate: TranslateService
    ) { }
    @Input() set tableOptions(data: TableOptionsEditable<T>) {
        this.dataTableConfig = data;
        // this.dataTableConfig?.column.forEach((e: ColumnTable<T>, i) => {
        //     e.order = i;
        // });
        if (typeof this.dataTableConfig.allowEditRow == 'boolean')
            this.dataTableConfig.allowEditRow =
                this.dataTableConfig.allowEditRow;
        else this.dataTableConfig.allowEditRow = true;
        this.pageSize =
            this.dataTableConfig && this.dataTableConfig?.pageLength
                ? this.dataTableConfig?.pageLength
                : 50;
        this.options = {
            sort: '',
            pageSize: this.pageSize,
            pageIndex: this.pageIndex,
            searchString: this.searchInput || '',
        };
        this.getData();
        // this.dataTableConfig.allowOrdering = false; // change by tareq
    }
    @Output() onSelectRow = new EventEmitter();
    @Output() onEditRow = new EventEmitter();
    @Output() onDiscardRow = new EventEmitter();
    @Output() editSaveChanges = new EventEmitter();
    @Output() onRemoveRow = new EventEmitter();

    dataTableConfig: TableOptionsEditable<T> | undefined;
    options: GetDataOptions = {
        sort: '',
        pageSize: 0,
        pageIndex: 0,
        searchString: '',
    };
    pageIndex: number = 0;
    pages: number = 1;
    dropdownControl: UntypedFormControl = new UntypedFormControl();
    totalCount: number = 0;
    pageSize: number = 10;
    hasNextPage: boolean = true;
    searchInput: string | undefined;
    sortArray: ColumnSortOptions[] = [];
    showRowFrom: number | undefined;
    showRowTo: number | undefined;
    tableData: T[] = [];
    sortString: string = '';
    isBusy: boolean = false;
    selectedItems: T[] = [];
    isCards: boolean = false;
    showFilter: boolean = false;
    showAs: string = 'list';
    showInactive: boolean = false;
    tableValid: boolean = true;
    arrayOfCellsErrors: string[] = [];
    submited: boolean = false;
    allComplete: boolean = false;
    public get columnTypeEnum(): typeof ColumnTypeEnum {
        return ColumnTypeEnum;
    }

    public get actionTypeEnum(): typeof ActionTypeEnum {
        return ActionTypeEnum;
    }

    public get functionTypeEnum(): typeof FunctionTypeEnum {
        return FunctionTypeEnum;
    }

    public get hackTableData(): any[] {
        return this.tableData;
    }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        var tables = document.getElementsByTagName('table');
        for (var i = 0; i < tables.length; i++) {
            this.resizableGrid(tables[i]);
            // tables[i].style.overflow = 'clip';
        }
    }

    updateAllComplete(item?: T) {
        this.allComplete =
            this.tableData.length != 0 &&
            this.tableData.every((t) => t.isChecked);
        if (item) {
            if (item.isChecked) {
                this.selectedItems.push(item);
            } else {
                let index = this.selectedItems.findIndex(function (e) {
                    return e.id == item.id;
                });
                if (index !== -1) this.selectedItems.splice(index, 1);
            }
        }
        this.onSelectRow.emit(this.selectedItems);
        // this.setSelectedItems();
    }

    someComplete(): boolean {
        if (this.tableData.length == 0) {
            return false;
        }
        return (
            this.tableData.filter((t) => t.isChecked).length > 0 &&
            !this.allComplete
        );
    }

    setAll(completed: boolean) {
        // if (completed == false) {
        //     this.selectedItems = [];
        // }
        this.allComplete = completed;
        if (this.tableData.length == 0) {
            return;
        }
        this.tableData.forEach((t) => (t.isChecked = completed));
        this.setSelectedItems();
    }

    setSelectedItems() {
        let selectedItems: T[] = [];
        this.tableData.forEach((e) => {
            let indexItem = this.selectedItems.findIndex(i => i.id == e.id);
            if (indexItem != -1) {
                this.selectedItems.splice(indexItem, 1);
            }
            selectedItems.push(e);
        });
        const mergedArray = [...selectedItems, ...this.selectedItems];
        let uniqueArray: T[] = [];
        mergedArray.forEach((obj) => {
            const exists = uniqueArray.find((item) => item.id === obj.id);
            if (!exists && obj.isChecked) {
                uniqueArray.push(obj);
            }
        });
        this.selectedItems = uniqueArray;
        this.onSelectRow.emit(this.selectedItems);
    }

    resizableGrid(table: any) {
        var row = table.getElementsByTagName('tr')[0],
            cols = row ? row.children : undefined;
        if (!cols) return;

        // table.style.overflow = 'hidden';

        var tableHeight = table.offsetHeight;
        for (var i = 0; i < cols.length; i++) {
            var div = createDiv(tableHeight);
            cols[i].appendChild(div);
            cols[i].style.position = 'relative';
            setListeners(div);
        }

        function setListeners(div: any) {
            let pageX: any,
                curCol: any,
                nxtCol: any,
                curColWidth: any,
                nxtColWidth: any;

            div.addEventListener('mousedown', (e: any) => {
                curCol = e.target.parentElement;
                nxtCol = curCol.nextElementSibling;
                pageX = e.pageX;

                var padding = paddingDiff(curCol);

                curColWidth = curCol.offsetWidth - padding;
                if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
            });

            div.addEventListener('mouseover', (e: any) => {
                e.target.style.borderRight = '5px solid #525252';
            });

            div.addEventListener('mouseout', (e: any) => {
                e.target.style.borderRight = '';
            });

            document.addEventListener('mousemove', (e: any) => {
                if (curCol) {
                    var diffX = e.pageX - pageX;
                    if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + 'px';

                    curCol.style.width = curColWidth + diffX + 'px';
                }
            });

            document.addEventListener('mouseup', function (e) {
                curCol = undefined;
                nxtCol = undefined;
                pageX = undefined;
                nxtColWidth = undefined;
                curColWidth = undefined;
            });
        }

        function createDiv(height: any) {
            var div = document.createElement('div');
            div.style.top = '0';
            div.style.right = '-1px';
            div.style.width = '5px';
            div.style.position = 'absolute';
            div.style.cursor = 'col-resize';
            div.style.userSelect = 'none';
            div.style.height = '100%';
            return div;
        }

        function paddingDiff(col: any) {
            if (getStyleVal(col, 'box-sizing') == 'border-box') {
                return 0;
            }

            var padLeft = getStyleVal(col, 'padding-left');
            var padRight = getStyleVal(col, 'padding-right');
            // console.log(padLeft);
            // console.log(padRight);
            return parseInt(padLeft) + parseInt(padRight);
        }

        function getStyleVal(elm: any, css: any) {
            return window.getComputedStyle(elm, null).getPropertyValue(css);
        }
    }

    private isPaginated = (
        object: PagingData<T> | T[]
    ): object is PagingData<T> => {
        return 'pageData' in object;
    };

    public refresh(resetPage = false) {
        // if (resetPage) {
        //     this.options.pageIndex = 0;
        // }
        this.resetOptionsAndData();
        this.getData();
    }

    private tc = new ThrottleCallService<ActionResponse<T>>();
    async getData(delay = 0, fromSearch: boolean = false) {
        try {
            if (this.dataTableConfig) {
                if (this.options.pageIndex == 0 && delay === 0)
                    this.isBusy = true;

                //console.log('fetch from database')
                let response = await this.tc.call(
                    () =>
                        this.dataTableConfig!.getData(
                            this.options,
                            this.showInactive
                        ),
                    delay
                );
                //console.log('completed fetch from database')
                if (fromSearch) {
                    this.resetOptionsAndData();
                }
                if (response && response.data) {
                    if (this.isPaginated(response.data)) {
                        this.pages = response.data.totalPages;
                        this.hasNextPage = response.data.hasNextPage;
                        this.tableData.push(...response.data.pageData);
                        this.pageIndex = response.data.pageIndex;
                        this.totalCount = response.data.totalCount;
                        this.updatePagination();
                        this.updateAllComplete();
                    } else {
                        this.pages = 0;
                        this.tableData = response.data;
                        this.pageIndex = 0;
                        this.totalCount = 0;
                        this.updatePagination();
                        this.updateAllComplete();
                    }
                }
            }
        } catch (e) {
            if (typeof e === 'string' && e == 'aborted') console.log('aborted');
        } finally {
            this.isBusy = false;
        }
    }

    updatePagination() {
        this.showRowFrom = this.pageIndex * this.pageSize + 1;
        this.showRowTo =
            this.pageIndex * this.pageSize + 1 + this.tableData?.length - 1;
    }

    searchApi() {
        this.options.searchString = this.searchInput;
        this.options.pageSize = 50;
        this.getData(1000, true);
    }

    changePage(pageNumber: number) {
        this.options.pageIndex = pageNumber - 1;
        this.getData();
    }

    firstCall: boolean = true;
    scrollTo: number = 0;
    async onScroll(event: any) {
        if (
            event.target.offsetHeight + event.target.scrollTop >=
            event.target.scrollHeight - 1000 &&
            this.firstCall
        ) {
            this.firstCall = false;
            this.options.pageIndex = this.options.pageIndex + 1;
            if (!this.dataTableConfig?.staticData && this.hasNextPage) {
                await this.getData();
                this.firstCall = true;
            } else {
                this.firstCall = true;
            }
        }
    }

    sort(column: ColumnTableEditable<T>) {
        if (this.dataTableConfig?.allowOrdering) {
            column.isSorting = true;
            switch (column.sortType) {
                case 'asc':
                    column.sortType = 'desc';
                    this.generateSortArray(column);
                    break;
                case 'desc':
                case undefined:
                    column.sortType = 'asc';
                    this.generateSortArray(column);
                    break;
            }
        }
    }

    generateSortArray(column: ColumnTableEditable<T>) {
        let sortingIndex = this.sortArray.findIndex(
            (e: ColumnSortOptions) => e.key == column.key
        );
        if (sortingIndex >= 0) {
            this.sortArray.splice(sortingIndex, 1);
        }
        this.sortArray.unshift({
            key: column.key,
            sortType: column.sortType ?? 'asc',
        });

        let sortString: string = '';
        this.sortArray.forEach((e: ColumnSortOptions, i: number) => {
            sortString +=
                e.key +
                ' ' +
                e.sortType +
                (this.sortArray.length - 1 == i ? '' : ',');
        });
        this.sortString = sortString;
        this.options.sort = this.sortString || '';
        this.resetOptionsAndData();
        this.getData();
    }
    async callActionColumn(column: ColumnTableEditable<T>, item: T) {
        // if (column && column.action)
        //     column.action(item);
    }
    async callAction(action: TableActionList<T>, item: T) {
        if (action.functionType == FunctionTypeEnum.remove)
            await this.removeRow(item);
        else if (action.actionFunc) {
            if (action.isFuncAsync) {
                let response = await action.actionFunc(item);
                if (response.isRefresh) this.refresh();
            } else {
                let response = <ActionResponse<T>>action.actionFunc(item);
                if (response.isRefresh) this.refresh();
            }
        }
    }

    async addRow() {
        if (this.dataTableConfig && this.dataTableConfig.addRow) {
            await this.dataTableConfig.addRow();
        }
    }

    async removeRow(item: T) {
        try {
            if (this.dataTableConfig && this.dataTableConfig.removeRow) {
                let removeActionResp = null;
                if (item.id !== undefined)
                    removeActionResp = await this.dataTableConfig.removeRow(
                        item
                    );
                if (
                    removeActionResp == null ||
                    removeActionResp.success == true
                ) {
                    // this.isBusy = true;
                    this.tableData.splice(this.tableData.indexOf(item), 1);
                    this.tableData = [];
                    this.getData();
                    this.tableValid = true;
                    this.activeYEdit = null;
                    this.activeXEdit = null;
                    this.onRemoveRow.emit(item)
                } else if (
                    removeActionResp != null &&
                    removeActionResp.success == false
                ) {
                    // this.sharedService.alert(
                    //     removeActionResp.errMsg ?? 'General error',
                    //     AlertTypeEnum.Danger
                    // );
                }
                this.updatePagination();
            }
        } catch {
        } finally {
            // this.isBusy = false;
        }
    }


    showArchived() {
        this.resetOptionsAndData();
        this.showInactive = !this.showInactive;
        this.refresh();
    }

    resetOptionsAndData() {
        this.options.pageIndex = 0;
        this.tableData = [];
    }

    //////////////////////////////////*****/ */
    activeYEdit: number | null = null;
    activeXEdit: number | null = null;
    backupRowValue: T | null = null;

    allowEdit(x: number, y: number, item: T, type?: ColumnTypeEnum) {
        this.backupRowValue = Object.assign({}, item);
        this.activeYEdit = y;
        this.activeXEdit = x;
        let id = `input-${item.id}`;
        if (type == undefined) {
            let foundType: boolean = false;
            this.dataTableConfig?.column.forEach((e) => {
                if (e.allowEdit && foundType == false) {
                    type = e.type;
                    foundType = true;
                }
            });
        }
        if (type == ColumnTypeEnum.autoComp)
            id = `autocomplete-dropdown-input-${item.id}`;
        setTimeout(() => {
            document.getElementById(id)?.focus();
        }, 100);
        this.onEditRow.emit(item);
    }

    async updateValue(
        item: T,
        column: ColumnTableEditable<T>,
        event?: any,
        autoCompControl?: any
    ) { }

    tabEvent(column: ColumnTableEditable<T>, x: number, y: number) { }

    saveInputChanges(item: any) {
        // let numberOfErrors: number = 0;
        let fildsError: string[] = [];
        this.hackTableData.forEach((e, i) => {
            if (i == this.activeYEdit) {
                this.dataTableConfig?.column.forEach(async (c, i2) => {
                    if (c.isRequired) {
                        if (this.checkEmpty(e[c.key])) {
                            // numberOfErrors++;
                            fildsError.push(i + '' + i2);
                            c.valid = false;
                            let filedLabel = await lastValueFrom(this.translate
                                .get(c.label)
                            );
                            let isRequiredLabel = await lastValueFrom(this.translate
                                .get('CONNOT_BE_EMPTY')
                            );
                            c['errorMessage'] =
                                filedLabel + ' ' + isRequiredLabel;
                        } else {
                            c.valid = true;
                            let indexOfErrors = fildsError.findIndex(e => e == i + '' + i2);
                            if (indexOfErrors != -1)
                                fildsError.splice(indexOfErrors, 1);
                        }
                    }
                    // if (c.valid) {
                    if (c.customValidation) {
                        c.valid = c.customValidation(e);
                        if (c.valid) {
                            // if (numberOfErrors > 0)
                            //     numberOfErrors--;

                            let indexOfErrors = fildsError.findIndex(e => e == i + '' + i2);
                            if (indexOfErrors != -1)
                                fildsError.splice(indexOfErrors, 1);
                            let index = this.arrayOfCellsErrors.findIndex(e => e == i + '' + i2);
                            this.arrayOfCellsErrors.splice(index, 1);
                        } else {
                            // numberOfErrors++;
                            fildsError.push(i + '' + i2);
                            c['errorMessage'] = c.customErrorMessage;
                        }
                    }
                    // }
                    if (c.type == ColumnTypeEnum.number) {
                        e[c.key] = Number(e[c.key]);
                    }
                });
            }
        });
        if (fildsError.length == 0) {
            this.tableValid = true;
            this.activeYEdit = null;
            this.activeXEdit = null;
        } else {
            this.tableValid = false;
        }
        this.editSaveChanges.emit(item);
        this.dropdownControl.setValue("");
    }

    checkEmpty(value: null | string | undefined | number) {
        if (value == null || value == '' || value == undefined) {
            return true;
        }
        return false;
    }

    discardInputChanges(item: T, column: ColumnTableEditable<T>) {
        if (this.activeYEdit != null)
            this.hackTableData[this.activeYEdit] = this.backupRowValue;
        this.activeYEdit = null;
        this.activeXEdit = null;
        this.arrayOfCellsErrors = [];
        this.hackTableData.forEach((e, i) => {
            this.dataTableConfig?.column.forEach(async (c) => {
                c.valid = true;
            });
        });

        this.onDiscardRow.emit(item);
    }

    deleteNewRow(item: T) {
        let itemDeleted = this.tableData.splice(this.tableData.length - 1, 1);
        this.onDiscardRow.emit(itemDeleted);
        this.activeYEdit = null;
        this.activeXEdit = null;
    }

    onAddressChange(
        addressObj: AddressObject,
        item: T,
        column: ColumnTableEditable<T>
    ) {
        item[column.key as keyof typeof item] = <any>addressObj.formattedAddress;
    }

    async closeFilter() {
        this.isBusy = true;
        this.showFilter = false;
        this.searchInput = '';
        this.tableData = [];
        await this.searchApi();
        this.isBusy = false;
    }

    checkColumnsErrors(x: number, y: number) {
        // console.log(x+""+y)
        let location = x + '' + y;
        return this.arrayOfCellsErrors.find((value) => value == location)
            ? true
            : false;
    }

    checkTableValidation() {
        this.submited = true;
        let numberOfErrors: number = 0;
        this.arrayOfCellsErrors = [];
        this.hackTableData.forEach((e, index1) => {
            this.dataTableConfig?.column.forEach(async (c, index2) => {
                if (c.isRequired) {
                    if (this.checkEmpty(e[c.key])) {
                        numberOfErrors++;
                        this.arrayOfCellsErrors.push(index1 + '' + index2);
                        // c.valid = false;
                        let filedLabel = await lastValueFrom(this.translate
                            .get(c.label)
                        );
                        let isRequiredLabel = await lastValueFrom(this.translate
                            .get('CONNOT_BE_EMPTY')
                        );
                        c['errorMessage'] = filedLabel + ' ' + isRequiredLabel;
                    } else {
                        // c.valid = true;
                    }
                }
                if (c.customValidation) {
                    if (!c.customValidation(e)) {
                        numberOfErrors++;
                        this.arrayOfCellsErrors.push(index1 + '' + index2);
                        c['errorMessage'] = c.customErrorMessage;
                    }
                }
                if (c.type == ColumnTypeEnum.number) {
                    e[c.key] = Number(e[c.key]);
                }
            });
        });
        if (numberOfErrors == 0) {
            this.tableValid = true;
            this.activeYEdit = null;
            this.activeXEdit = null;
            return true;
        } else {
            this.tableValid = false;
            return false;
        }
    }

    blurInputAutoCompleteDropdown(item: any) {
        if (this.dataTableConfig && this.dataTableConfig.saveInputAfterBlur)
            this.saveInputChanges(item);
    }

    selectOption(value: string | any, column: ColumnTableEditable<T>) {
        if (value && !column.reuseOption) {
            // if(column.optionsList)
            // column.optionsList = column.optionsList.filter(function (e) { return e.value != value });
        }
    }

    selectDropDownOption(value: string | any, column: ColumnTableEditable<T>, item: any) {
        item[column.key] = value;
    }
}
