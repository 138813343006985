<p-table [columns]="cols" [loading]="loading" [value]="tableData" [resizableColumns]="true"  [scrollable]="true" styleClass="p-datatable-gridlines" 
     columnResizeMode="expand"
    [virtualScroll]="true" [lazy]="true" [virtualScrollDelay]="0"
    (onLazyLoad)="loadCarsLazy($event)" [virtualScrollItemSize]="46">
    <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
            Products
            <p-button icon="pi pi-refresh" />
        </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.key"
                [ngStyle]="{'min-width': col.width ? col.width : '20rem'}">
                {{col.label |translate}} 
                <p-sortIcon [field]="col.key" />
            </th>
            <th pFrozenColumn alignFrozen="right" [frozen]="true" style="width: 20rem;">
                
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns;let i = index">
                {{rowData[col.key]}}
            </td>
            <td pFrozenColumn alignFrozen="right" [frozen]="true">
                Actions
            </td>
        </tr>
    </ng-template>
</p-table>