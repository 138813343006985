<div class="dialog-header">
    <ng-container
        *ngTemplateOutlet="sharedTemplatesService.$dialog_header;context: { comp: this,title: 'WELCOME_TRUCKIN_CENTRAL' }">
    </ng-container>
</div>
<div class="dialog-body">
    <div class="body-short-1">
        {{"WELCOME_TRUCKIN_CENTRAL_DESC" |translate}}
    </div>
</div>
<div class="dialog-footer">
    <div class="app-actions-button">
        <button class="app-close-modal-button" (click)="close()">
            {{ "SKIP" | translate }}
        </button>
        <button class="primary-button-large" (click)="close('start')">
            {{"START_ONBOARDING" |translate}}
        </button>
    </div>
</div>