import { Completer } from '../model/shared.model';

class Runner<T> {
    constructor(
        public completer: Completer<T>,
        public timeoutId: NodeJS.Timeout | null,
        public isStarted: boolean) {
    }
}

export class ThrottleCallService<T> {

    private runners: Runner<T>[] = [];

    async call(func: () => Promise<T>, period: number, isKill: boolean = true): Promise<T> {

        if (this.runners.length > 0) {
            if (isKill) {
                // if a previous call was made then kill it
                this.killAllRunners();
            } else {
                // if a previous call was made but timeout was not
                // due yet (i.e. did started the execution of its function) then kill it
                this.SoftkillAllRunners();
         
                // if a previous call was made then wait for it
                await Promise.all(this.runners.map(p => p.completer.promise.then(() => 'completed', () => 'failed')));
            }
        }

        // Use a completer to be able to resolve(kill) the promise from outside a Promise
        let runner = new Runner<T>(new Completer<T>(), null, false);
        this.runners.push(runner);

        runner.timeoutId = setTimeout(async (me: ThrottleCallService<T>) => {

            // a flag to indicate that a timeout is executing
            runner.isStarted = true;

            // I reset it to null because after the callback of the timer is called, the timer is useless
            runner.timeoutId = null;

            try {
                let resp = await func();
                if (resp) {
                    runner.completer.complete(resp);
                } else {
                    // Most likely this is not going to be called
                    runner.completer.reject(new Error('Function passed to throttle returned null'));
                }
            } catch (e) {
                console.log(e)
                runner.completer.reject(e);
            } finally {
                const index = this.runners.indexOf(runner, 0);
                if (index > -1) {
                   this.runners.splice(index, 1);
                   // this will only be called if neither complete or reject was called
                   runner.completer.reject('aborted')
                }
            }
        }, period, this);

        return runner.completer.promise;
    }

    private killAllRunners() {
        let runner = this.runners.pop();
        while (runner != undefined) {
            if (runner.timeoutId) {
                clearTimeout(runner.timeoutId);
                runner.timeoutId = null;
            }
            if (!runner.completer.isResolved)
                runner.completer.reject('aborted');
            runner = this.runners.pop();
        }
    }

    private SoftkillAllRunners() {
        let remove: Runner<T>[] = [];
        this.runners.forEach(runner => {
            if (runner.timeoutId && runner.isStarted === false) {
                clearTimeout(runner.timeoutId);
                runner.timeoutId = null;
                remove.push(runner);
            }
        });
        while (remove.length > 0)
            this.runners.splice(this.runners.indexOf(remove.pop()!, 0), 1);
    }
}
