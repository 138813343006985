import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'th[resizable]',
  templateUrl: './col-resize.component.html',
  styleUrls: ['./col-resize.component.scss']
})
export class ColResizeComponent {

    @HostBinding("style.width.px")
    width: number | null = null;
  
    onResize(width: any) {
      this.width = width;
    }

}
