import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TrailerAux } from 'src/app/modules/Trailers/models/trailers.model';
import { TrailersApiService } from 'src/app/modules/Trailers/services/trailers-api.service';
import { InputUnitTypeEnum } from 'src/app/shared/model/shared.model';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
    selector: 'app-trailer-quick-add',
    templateUrl: './trailer-quick-add.component.html',
    styleUrls: ['./trailer-quick-add.component.scss'],
})
export class TrailerQuickAddComponent implements OnInit {
    allowEdit: boolean = true;
    editMode: boolean = true;
    submitted: boolean = false;
    todayDate: Date = new Date();
    errorMessages: Set<string> = new Set();
    constructor(
        private dialogRef: MatDialogRef<TrailerQuickAddComponent>,
        private fb: UntypedFormBuilder,
        private trailerApiService: TrailersApiService,
        private formService: FormService) {}
    quickAddTrailerForm = this.fb.group({
        trailerId: [null, Validators.required],
        make: [],
        model: [],
        year: [],
        vin: [],
        value: [],
    });
    ngOnInit(): void {}

    cancel() {
        this.dialogRef.close(null);
    }

    get f() {
        return this.quickAddTrailerForm.controls;
    }

    populateForm() {
        let model: TrailerAux = {
            assetCode: this.f.trailerId.value,
            vin: this.f.vin.value,
            make: this.f.make.value,
            model: this.f.model.value,
            modelYear: this.f.year.value,
            assetValue: Number(this.f.value.value),
            assetId: 0,
            id: 0,
        };
        return model;
    }

    async saveChanges() {
        this.submitted = true;
        if (this.quickAddTrailerForm.invalid) return;

        try {
            
            const response = await this.trailerApiService.addTrailerAux(
                this.populateForm()
            );
            if (response.success) this.dialogRef.close(response.data);
        } catch (error: any) {
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        }
    }
    get InputUnitTypeEnum() {
        return InputUnitTypeEnum;
    }

}
