import { moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, lastValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

interface DownloadAdvanceReport {
    selectedItems: any
    title: string
    columns: DownloadAdvanceReportColumns[],
    reportAPI: string
    sortString: string
}

interface DownloadAdvanceReportColumns {
    label: string
    key: string
    visible?: boolean
    checked: boolean
    sortType: string
}

const APIURL = `${environment.apiUrl}/cmp/`;

@Component({
    selector: 'app-download-list-report',
    templateUrl: './download-list-report.component.html',
    styleUrls: ['./download-list-report.component.scss']
})
export class DownloadListReportComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<DownloadListReportComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DownloadAdvanceReport,
        private http: HttpClient,
        private translate: TranslateService,
        private sharedService: SharedService
    ) {
        dialogData.columns.forEach(e => {
            if (e.key) {
                this.columns.push(
                    {
                        label: e.label,
                        key: e.key,
                        checked: e.visible ? true : false,
                        sortType: e.sortType
                    }
                )
            }
        });
        this.columns = this.columns.sort((a, b) => (a.checked === b.checked) ? 0 : a.checked ? -1 : 1);
        this.updateAllComplete();
    }

    @ViewChild('progressBar') progressBar: ElementRef | null = null;
    isBusy: number = 0;
    activeTab: number = 1;
    selectAllColumns: boolean = false;
    isExportDone: boolean = false;
    reportTabs = [
        {
            title: "Select Columns",
            key: 'selectColumns',
            index: 1,
            disabled: this.isExportDone
        },
        {
            title: "Results",
            key: 'results',
            index: 2,
            disabled: true
        },
    ];
    columns: DownloadAdvanceReportColumns[] = [];
    isExportFail: boolean = false;
    ngOnInit(): void {

    }

    close() {
        this.dialogRef.close();
    }

    goToTab(index: number) {
        this.activeTab = index;
    }

    someComplete(): boolean {
        if (this.columns == null) {
            return false;
        }
        return this.columns.filter(t => t.checked).length > 0 && !this.selectAllColumns;
    }

    setAll(completed: boolean) {
        this.selectAllColumns = completed;
        if (this.columns == null) {
            return;
        }
        this.columns.forEach(t => {
            t.checked = completed
        });
    }

    updateAllComplete() {
        this.selectAllColumns = this.columns != null && this.columns.every(t => t.checked);
    }

    checkSelected() {
        if (this.columns.filter(t => t.checked).length > 0)
            return true
        else
            return false
    }

    drop(event: any) {
        moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    }

    async continue() {
        try {
            this.isBusy++;
            setTimeout(() => {
                this.progressBar?.nativeElement.animate(
                    [{ width: '0%' }, { width: '100%' }],
                    {
                        duration: 1500,
                        iterations: 100000000000,
                    }
                );
            });
            let selectedIds: number[] = [];
            let selectedColumns: string[] = [];
            this.dialogData.selectedItems.forEach((e: any) => {
                selectedIds.push(e.id);
            });
            this.columns.forEach((e, i: number) => {
                if (e.checked && e.key) {
                    let columnName = e.key.charAt(0).toUpperCase() + e.key.slice(1);
                    selectedColumns.push(columnName);
                }
            });
            let response = await this.downloadFile(selectedIds, selectedColumns, this.dialogData.sortString);
            if (response.sucsses) {
                this.isExportDone = true;
                this.isExportFail = false;
            } else {
                this.isExportFail = true;
            }
        } catch (error: any) {
            this.isExportFail = true;
        }
        finally {
            this.isBusy--;
        }
    }


    getReport(
        objectIds: number[],
        objectColumns: string[],
        orderBy: string
    ): Observable<Blob | any> {
        let url = APIURL + this.dialogData.reportAPI;
        return this.http.post(url, {
            objectIds: objectIds,
            objectColumns: objectColumns,
            orderBy
        }, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    async downloadFile(
        objectIds: number[],
        objectColumns: string[],
        orderBy: string
    ) {
        try {
            let response = await lastValueFrom(this.getReport(objectIds, objectColumns, orderBy));
            var newBlob = new Blob([response.body], { type: response.body.type });
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = this.translate.instant(this.dialogData.title);
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );

            await setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            return {
                sucsses: true
            }
        } catch (err) {
            this.sharedService.alertDangerMessage(
                'Download Failed',
                'There was an error downloading the file, please try again'
            );
            return {
                sucsses: false
            }
        } finally {
        }
    }

}
