import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Self,
    ViewChild,
    forwardRef,
} from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    NgControl,
} from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { DropdownList } from '../../model/shared.model';

// const REGEXP = new RegExp(',', 'g');
@Component({
    selector: 'app-tag-input',
    templateUrl: './tag-input.component.html',
    styleUrls: ['./tag-input.component.scss'],
})
export class TagInputComponent
    implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy {
    isOpened: boolean = false;
    positionInterval: any;
    fromFail: boolean = false;
    showDropdownMenu: boolean = false;
    containerTopInit: number | null = null;
    @ViewChild('dropdownContainer') dropdownContainer: ElementRef | null = null;
    @ViewChild('tagInput') tagInput?: ElementRef;
    @ViewChild('dropdownMenu') dropdownMenuElement?: ElementRef;
    @Input() type: string = 'text';
    @Input() minlength: number = 0;
    @Input() isPhoneNumber: boolean = false;
    @Input() id: string = '';
    @Input() hasDropdownList: boolean = false;
    @Input() name: string = '';
    @Input() class: string = '';
    @Input() placeholder: string = '';
    @Input() disabled: boolean | string = false;
    @Input() strictValue: boolean = false;
    @Input() readonly: boolean | string = false;
    @Input() viewMode: boolean = false;
    @Input() maxSelected: number | null = null;
    @Input() tagsClass: string | null = null;
    @Input() removedItems: DropdownList[] = [];
    @Input() openlistInTop: boolean = false;
    @Output() input: EventEmitter<Event> = new EventEmitter();
    @Input('aria-describedby') userAriaDescribedBy: string = '';
    @Output() change: EventEmitter<Event | DropdownList[]> = new EventEmitter();
    @Input() autocompleteList: (searchText: string) => Promise<DropdownList[]> =
        (searchText: string) =>
            new Promise<DropdownList[]>((resolve, reject) => resolve([]));
    @Input()
    get value(): DropdownList[] | null {
        const listValue = this.tagList;
        return listValue;
    }
    set value(value: DropdownList[] | null | any) {
        const listValue = (value == '' ? [] : value) ?? [];
        this.tagList = listValue;
        this.stateChanges.next();
    }

    @HostListener('window:scroll', []) onWindowScroll() {
        this.showDropdownMenu = false;
        this.relocateList();
    }

    @HostListener('window:resize', []) onWindowResize() {
        this.showDropdownMenu = false;
        this.relocateList();
    }

    @HostListener('document:click', ['$event']) onDocClick(event: any) {
        const outsideClick =
            typeof event.composedPath === 'function' &&
            !event
                .composedPath()
                .includes(this.dropdownContainer?.nativeElement);

        if (outsideClick) {
            this.showDropdownMenu = false;
            this.relocateList();
        }
    }

    tagList: DropdownList[] | null = [];
    validationMessages: Set<string> = new Set();
    //autoCompleteList: string[] | null = [];
    //filteredOptions?: Observable<string[]>;
    isDisabled: boolean = false;
    onChange = (_: DropdownList[]) => { };
    onTouched = () => { };
    stateChanges = new Subject<void>();

    constructor(
        private fb: UntypedFormBuilder,
        @Optional() @Self() public ngControl: NgControl,
        @Inject(DOCUMENT) private document: any
    ) {
        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }
    }
    tagsForm = this.fb.group({
        tagInput: [['']],
    });

    async ngOnInit() {
        if (this.hasDropdownList) {
            this.UpdateFilteredOptions();
        }
        if (this.tagInput) this.tagInput.nativeElement.value = null;
        this.isDisabled = this.disabled == 'true' || this.disabled == true;
        this.readonly = this.readonly == 'true' || this.readonly == true;
        if (this.disabled) {
            this.tagsForm.controls.tagInput.disable();
        } else this.tagsForm.controls.tagInput.enable();

        this.tagList = this.value;

        await this.UpdateFilteredOptions();
        // this.filteredOptions =
        //     this.tagsForm.controls.tagInput?.valueChanges.pipe(
        //         startWith(''),
        //         map((value: string) => this._filter(value || '') ?? [])
        //     );
        this.positionInterval = setInterval(() => {
            this.relocateList();
        }, 1);
    }

    ngAfterViewInit() {
        this.relocateList();
    }

    async UpdateFilteredOptions() {
        // this.showDropdownMenu = true;

        if (this.autocompleteList == null) return;
        let value =
            this.tagInput?.nativeElement.value.toLowerCase().trim() ?? '';
        let res = await this.autocompleteList(value);
        let indexItems: any = [];
        if (this.tagList && this.tagList.length > 0) {
            // this.showDropdownMenu = true;
            // for (let i = 0; i < res.length; i++) {
            //     for (let y = 0; y < this.tagList.length; y++) {
            //         if (res[i]?.value == this.tagList[y]?.value) {
            //             if (res[i]?.value) indexItems.push(res[i].value);
            //         }
            //     }
            // }
        }
        if (this.removedItems) {
            this.removedItems.forEach((e) => {
                indexItems.push(e.value);
            });
        }
        if (indexItems.length) {
            res = res.filter(
                (item) => !indexItems.includes(item.value ? +item.value : 0)
            );
        }
        this.filteredOptions.next(res);
        if (
            res.length == 0 &&
            this.strictValue &&
            this.tagInput?.nativeElement.value
        ) {
            this.fromFail = true;
            this.validationMessages.add('PLEASE_SELECT_FROM_AVAILABLE_OPTIONS');
        }
        return res ?? [];
    }

    filteredOptions: BehaviorSubject<DropdownList[]> = new BehaviorSubject<
        DropdownList[]
    >([]);

    async onInputChange(event: Event) {
        if (event.type == 'change') this.change.emit(event);
        if (event.type == 'input') this.input.emit(event);

        await this.UpdateFilteredOptions();
    }

    // private _filter(value: string): string[] | undefined {
    //     const filterValue = value.toLowerCase();

    //     return this.autoCompleteList?.filter((option: string) =>
    //         option.toLowerCase().includes(filterValue)
    //     );
    // }

    async onDropdown(event?: any) {
        // event.stopPropagation();
        let list = await this.UpdateFilteredOptions();
        if (list!.length > 0) this.showDropdownMenu = true;
        // if (this.inputAutoComplete?.panelOpen) {
        //     this.inputAutoComplete?.closePanel();
        // } else this.inputAutoComplete?.openPanel();
    }

    onInputBlur() {
        // setTimeout(() => {
        //     this.dropdownMenuElement?.nativeElement.classList.add('collapse');
        //     this.dropdownMenuElement?.nativeElement.classList.remove('show');
        // this.showDropdownMenu = false;
        // }, 200);
    }

    onInputFocus() {
        // this.dropdownMenuElement?.nativeElement.classList.remove('collapse');
        // this.dropdownMenuElement?.nativeElement.classList.add('show');
        this.showDropdownMenu = true;
    }

    onFocusOutEvent() {
        if(this.tagsForm.controls.tagInput.value == ''){
            return;
        }
        if (this.hasDropdownList && !this.strictValue) {
            setTimeout(() => {
                this.onAddTag();
            }, 150);
        }
    }

    async onAddTag(tag?: DropdownList) {
        this.validationMessages.clear();
        let alreadyExits = false;

        if (this.strictValue) {
            let autocompleteList = await this.UpdateFilteredOptions();
            let noMatch = true;
            autocompleteList?.forEach((au) => {
                if (tag?.value == au.value) {
                    noMatch = false;
                }
            });
            if (noMatch) {
                this.fromFail = true;
                this.validationMessages.add(
                    'PLEASE_SELECT_FROM_AVAILABLE_OPTIONS'
                );
                if (this.tagInput) this.tagInput.nativeElement.value = null;

                return;
            } else {
                this.validationMessages.delete(
                    'PLEASE_SELECT_FROM_AVAILABLE_OPTIONS'
                );
            }
        }

        if (this.maxSelected != null) {
            if (this.tagList?.length! >= this.maxSelected) {
                this.fromFail = true;
                this.validationMessages.add(
                    `You cannot choose more than ${this.maxSelected}`
                );
                return;
            }
        }

        if (this.isPhoneNumber) {
            if (this.tagInput?.nativeElement.value.length == 0) {
                return;
            }
            if (this.tagInput?.nativeElement.value.length < 14) {
                this.validationMessages.add(
                    `Please lengthen this text to 14 characters or more (you are currently using ${this.tagInput?.nativeElement.value.length} characters).`
                );
                this.fromFail = true;
                return;
            }
        }
        if (!this.tagInput?.nativeElement.validity.valid) {
            this.fromFail = true;
            this.validationMessages.add(this.tagInput?.nativeElement.validationMessage);
            return;
        } else {
            this.fromFail = false;
        }
        if (!Array.isArray(this.tagList)) {
            this.tagList = [];
        }
        this.tagList?.forEach((t: DropdownList) => {
            if (
                t.text.toLowerCase().trim() ==
                this.tagInput?.nativeElement.value.toLowerCase().trim() ||
                t.value == tag?.value
            ) {
                alreadyExits = true;
            }
        });
        if (alreadyExits) {
            if (this.tagInput) this.tagInput.nativeElement.value = null;
            return;
        }
        if (tag) {
            this.tagList?.push({
                text: tag.text.trim(),
                value: tag.value,
            });

            this._handleInput();

            this.onChange(this.tagList!);
            if (this.tagInput) this.tagInput.nativeElement.value = null;
        } else {
            if (this.tagInput?.nativeElement.value.trim())
                this.tagList?.push({
                    text: this.tagInput?.nativeElement.value.trim(),
                    value: this.tagInput?.nativeElement.value.trim(),
                });
            this._handleInput();
            this.onChange(this.tagList!);
        }
        if (this.tagInput) this.tagInput.nativeElement.value = null;
        this.UpdateFilteredOptions();
        this.change.emit(this.tagList ?? []);
        // this.inputAutoComplete?.closePanel();
        this.showDropdownMenu = false;
    }

    onRemoveTag(tag: DropdownList) {
        this.tagList?.splice(
            this.tagList.findIndex((t) => t == tag),
            1
        );
        this.change.emit(Array.from(this.tagList!) ?? []);
        this._handleInput();
        this.validationMessages.delete(
            `You cannot choose more than ${this.maxSelected}`
        );
    }

    onAutoSelect(event: any) {
        if (this.tagInput) this.tagInput.nativeElement.value = null;
        this.onAddTag(event);
        this.showDropdownMenu = false;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(tagList: DropdownList[] | null): void {
        this.value = tagList;
        this.tagList = tagList;
    }

    _handleInput(): void {
        this.onChange(this.tagList!);
    }

    openDropDownMenu() {
        this.UpdateFilteredOptions();
        // let el = document.getElementById("dropdownMenuLink");
        // if (this.isOpened) {
        //     this.document
        //         .getElementById('tagDropdownMenu')
        //         .classList.add('show');
        //     this.document
        //         .getElementById('tagDropdownMenu')
        //         .classList.add('static-dropdown');
        //     document.getElementById('dropdown-toggle')?.classList.add('show');
        //     document
        //         .getElementById('dropdown-toggle')
        //         ?.setAttribute('aria-expanded', 'false');
        //     document
        //         .getElementById('tagDropdownMenu')
        //         ?.setAttribute('data-popper-placement', 'bottom');
        // } else {
        //     this.document
        //         .getElementById('tagDropdownMenu')
        //         .classList.remove('show');
        //     this.document
        //         .getElementById('tagDropdownMenu')
        //         .classList.remove('static-dropdown');
        //     document
        //         .getElementById('dropdown-toggle')
        //         ?.classList.remove('show');
        //     document
        //         .getElementById('dropdown-toggle')
        //         ?.setAttribute('aria-expanded', 'true');
        // }
        // this.isOpened = !this.isOpened;
    }

    private relocateList() {
        let width =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().width;
        let top =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().top;
        let left =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().left;
        if (this.dropdownMenuElement) {
            this.dropdownMenuElement.nativeElement.style.width! = width + 'px';
            // this.dropdownMenuElement.nativeElement.style.top! = top + 32 + 'px';
            this.dropdownMenuElement.nativeElement.style.left! = left + 'px';
            this.dropdownMenuElement?.nativeElement.addEventListener(
                'focusout',
                (event: any) => {
                    this.showDropdownMenu = false;
                }
            );
            let listHeghit = this.dropdownMenuElement?.nativeElement.getBoundingClientRect().height;
            if (this.openlistInTop) {
                this.dropdownMenuElement.nativeElement.style.top! = top - listHeghit - 3 + 'px';
            } else {
                this.dropdownMenuElement.nativeElement.style.top! = top + 32 + 'px';
            }
            // this.list?.nativeElement.addEventListener('mouseover', (event: any) => {
        }
        if (this.containerTopInit) {
            if (this.containerTopInit != top && this.showDropdownMenu) {
                this.showDropdownMenu = false;
            }
        } else {
            this.containerTopInit = top;
        }
        this.containerTopInit = top;
    }

    ngOnDestroy(): void {
        clearInterval(this.positionInterval);
    }
}
