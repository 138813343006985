import { ActivatedRoute, Router } from '@angular/router';
import { RequiredList } from './../../model/shared.model';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../../services/shared.service';

@Component({
    selector: 'app-required-box',
    templateUrl: './required-box.component.html',
    styleUrls: ['./required-box.component.scss']
})
export class RequiredBoxComponent<T> implements OnInit, AfterViewInit, OnDestroy {

    @Input() requiredList: RequiredList[] = [];
    @Input() title: string = "";
    @Input() description: string = "";
    @Input() parentRoute: string = "";
    @Input() hide: boolean = true;
    @Output() doneAllRequiredFields = new EventEmitter<boolean>();
    requiredFieldCount: number = 0;
    requiredFieldDoneCount: number = 0;
    progressWidthDone: string = "0%";
    allFieldDone: boolean = true;
    routeSub?: Subscription | null = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private sharedService: SharedService
    ) { }

    ngAfterViewInit(): void {
        this.routeSub = this.route.queryParamMap.subscribe(params => {
            let id = params.get("id") ?? "";
            if (id.length > 0) {
                setTimeout(() => {
                    const el = document.getElementById(id);
                    if (el) {
                        this.sharedService.elementHighlight(el);
                    }
                }, 900);
            }
        });
    }

    ngOnInit(): void {
    }

    goToLink(link: string, id: string) {
        let sameLink = this.router.url.includes(link) ? true : false;
        if (sameLink) {
            let el = document.getElementById(id);
            if (el) {
                this.sharedService.elementHighlight(el);
            }
        }
        else
            this.router.navigate([this.parentRoute + link], { queryParams: { id: id } });
    }

    checkRequired(data: any) {
        this.requiredFieldCount = 0;
        this.requiredFieldDoneCount = 0;
        this.requiredList.forEach(e => {
            e.pageFields.forEach(field => {
                this.requiredFieldCount++;
                if (Array.isArray(field.fieldKey)) {
                    field.fieldKey.forEach(e => {
                        if (data[e.key] != null && data[e.key] != "") {
                            e.isEmpty = false
                        } else {
                            e.isEmpty = true
                        }
                    })
                    if (field.fieldKey.filter(e => e.isEmpty == true).length > 0) {
                        field.isEmpty = true;
                    } else {
                        field.isEmpty = false;
                        this.requiredFieldDoneCount++;
                    }
                } else {
                    if (data[field.fieldKey] != null && data[field.fieldKey] != "") {
                        field.isEmpty = false;
                        this.requiredFieldDoneCount++;
                    } else {
                        field.isEmpty = true;
                    }
                }
            })
        });
        if (this.requiredFieldCount == this.requiredFieldDoneCount) {
            this.allFieldDone = true;
        } else {
            this.allFieldDone = false;
        }
        this.doneAllRequiredFields.emit(this.allFieldDone);
        let sum = (100 / this.requiredFieldCount) * this.requiredFieldDoneCount;
        this.progressWidthDone = sum + '%';

    }


    ngOnDestroy(): void {
        if (this.routeSub) this.routeSub.unsubscribe();
    }

}
