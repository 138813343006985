<div class="relative" #dropdownContainer>
    <form [formGroup]="tagsForm">
        <ng-container *ngIf="!readonly && !viewMode">
            <ng-container *ngIf="isPhoneNumber; else notPhone">
                <input
                    #tagInput
                    #inputAutoComplete
                    formControlName="tagInput"
                    (change)="onInputChange($event)"
                    (input)="onInputChange($event)"
                    [disabled]="isDisabled"
                    (cuechange)="onAddTag()"
                    (keyup.enter)="onAddTag()"
                    [type]="type"
                    (blur)="onInputBlur()"
                    (focus)="onInputFocus()"
                    [readonly]="readonly"
                    [placeholder]="placeholder"
                    [id]="id"
                    [class]="'form-control tag-input ' + class"
                    [minlength]="minlength"
                    appPhoneNumberMask
                    (focusout)="onFocusOutEvent()"
                    autocomplete="off"
                />
                <!-- [matAutocomplete]="tagAuto" -->
            </ng-container>
            <ng-template #notPhone>
                <input
                    #tagInput
                    #inputAutoComplete
                    formControlName="tagInput"
                    (change)="onInputChange($event)"
                    (input)="onInputChange($event)"
                    (input)="onDropdown()"
                    (click)="onDropdown()"
                    [disabled]="isDisabled"
                    (cuechange)="onAddTag()"
                    (blur)="onInputBlur()"
                    (focus)="onInputFocus()"
                    (keyup.enter)="onAddTag()"
                    (click)="openDropDownMenu()"
                    [type]="type"
                    [readonly]="readonly"
                    [placeholder]="placeholder"
                    [id]="id"
                    [class]="'form-control tag-input ' + class"
                    [minlength]="minlength"
                    class="dropdown-toggle"
                    (focusout)="onFocusOutEvent()"
                    autocomplete="off"
                />
                <!-- data-bs-toggle="dropdown"
                    autocomplete="off"
                    id="dropdown-toggle"
                    aria-expanded="false"
                    aria-controls="tagDropdownMenu" -->
                <!-- [matAutocomplete]="tagAuto" -->
            </ng-template>

            <ul
                class="dropdown-menu show"
                #dropdownMenu
                *ngIf="showDropdownMenu"
                [class.d-none]="(filteredOptions | async)?.length == 0 || !showDropdownMenu"
            >
                <li *ngFor="let option of filteredOptions | async">
                    <a
                        class="dropdown-item"
                        (click)="onAutoSelect(option)"
                        (keyup.enter)="onAutoSelect(option)"
                    >
                        <span class="body-short-1">{{ option.text }}</span>
                        <br />
                        <span
                            class="body-small item-desc"
                            *ngIf="option.description"
                            >{{ option.description }}</span
                        >
                    </a>
                    <div class="line">
                        <hr />
                    </div>
                </li>
            </ul>
            <div class="row mt-2" *ngIf="fromFail">
                <div
                    class="invalid-form-warning"
                    *ngFor="let message of validationMessages"
                >
                    <!-- <div class="invalid-icon"></div> -->
                    <div class="warning-message">
                        {{ message | translate }}
                    </div>
                </div>
            </div>

            <!-- <div class="validation-msg mb-2" *ngIf="fromFail">
                {{ validationMessage }}
            </div> -->
            <!-- <mat-autocomplete
                (optionSelected)="onAutoSelect($event)"
                #tagAuto="matAutocomplete"
            >
                <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option"
                >
                    {{ option.text }}
                </mat-option>
            </mat-autocomplete> -->
        </ng-container>
        <div class="input-btns">
            <ng-container
                *ngIf="
                    tagInput?.nativeElement?.value != '' &&
                    tagInput?.nativeElement?.value != null && !strictValue
                "
            >
                <img
                    (click)="onAddTag()"
                    src="/assets/images/add-button-icon.svg"
                    alt=""
                />
            </ng-container>
            <ng-container *ngIf="hasDropdownList && !disabled && !readonly && !viewMode">
                <a
                    data-bs-toggle="collapse"
                    role="button"
                    
                    >
                    <!-- id="dropdown-toggle" -->
                    <img
                        (click)="onDropdown()"
                        class="dropdown-btn"
                        src="/assets/images/dropdown-icon.svg"
                        alt=""
                    />
                </a>
            </ng-container>
        </div>
        <ng-container *ngIf="!disabled || (readonly && tagList!.length > 0)">
            <div class="tags-list" [ngClass]="{ mt: readonly }">
                <ng-container *ngFor="let tag of tagList">
                    <div
                        class="tag"
                        [ngClass]="tagsClass ? tagsClass : 'blue-tag-filled'"
                    >
                        {{ tag.text }}
                        <ng-container *ngIf="!readonly && !disabled && !viewMode">
                            <span class="remove" [ngClass]="{ disabled: readonly || disabled }" (click)="onRemoveTag(tag)">
                                <i class="icon-x"></i>
                            </span>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="(readonly || viewMode) && tagList!.length == 0">
            <div class="no-data">
                {{ "NO_DATA" | translate }}
            </div>
        </ng-container>
    </form>
</div>
