import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { ColumnTable } from 'src/app/shared/model/app-table.model';
import { ExportColumns } from 'src/app/shared/model/import-export-list.model';
import { FileService } from 'src/app/shared/services/file.service';
import { environment } from 'src/environments/environment';

interface ExportList {
    selectedItems: any
    exportColumnApi: string
    exportApi: string
    fileName: string
}


const APIURL = `${environment.apiUrl}/cmp/`;

@Component({
    selector: 'app-export-list',
    templateUrl: './export-list.component.html',
    styleUrls: ['./export-list.component.scss']
})
export class ExportListComponent {

    constructor(
        private dialogRef: MatDialogRef<ExportListComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: ExportList,
        private http: HttpClient,
        private fileService: FileService,
        private cdr: ChangeDetectorRef
    ) {
        // dialogData.columns.forEach(e => {
        //     if (e.key) {
        //         this.columns.push(
        //             {
        //                 id: e.id,
        //                 label: e.label,
        //                 key: e.key,
        //                 checked: e.visible ? true : false
        //             }
        //         )
        //     }
        // });
        // this.columns = this.columns.sort((a, b) => (a.checked === b.checked) ? 0 : a.checked ? -1 : 1);
        // this.updateAllComplete();
    }

    @ViewChild('progressBar') progressBar: ElementRef | null = null;
    isBusy: number = 0;
    activeTab: number = 1;
    selectAllColumns: boolean = false;
    isExportDone: boolean = false;
    isStarted: boolean = false;
    selectedIds: number[] = [];
    exportColumns: ExportColumns[] = [];
    allowExport: boolean = false;
    isStartExport: boolean = false;
    errorMessages: Set<string> = new Set();
    // columns: DownloadAdvanceReportColumns[] = [];

    ngOnInit(): void {
        this.getExportColumns();
    }

    async getExportColumns() {
        try {
            let response = await this.getExportColumn();
            if (response.success) {
                response.data?.forEach((e) => {
                    this.exportColumns.push({
                        dbColumnName: e.dbColumnName,
                        aliasColumnName: e.aliasColumnName,
                        isChecked: false,
                    });
                });
            }
        } catch (error: any) {
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
         }
    }

    close() {
        this.dialogRef.close();
    }

    goToTab(index: number) {
        this.activeTab = index;
    }

    someComplete(): boolean {
        if (this.exportColumns == null) {
            return false;
        }
        return this.exportColumns.filter(t => t.isChecked).length > 0 && !this.selectAllColumns;
    }

    setAll(completed: boolean) {
        this.allowExport = completed;
        this.selectAllColumns = completed;
        if (this.exportColumns == null) {
            return;
        }
        this.exportColumns.forEach(t => {
            t.isChecked = completed
        });
    }

    updateAllComplete() {
        this.selectAllColumns = this.exportColumns != null && this.exportColumns.every(t => t.isChecked);
        if (this.exportColumns.filter(t => t.isChecked).length > 0) {
            this.allowExport = true;
            this.cdr.detectChanges();
        }
    }

    drop(event: any) {
        moveItemInArray(this.exportColumns, event.previousIndex, event.currentIndex);
    }

    async export() {
        this.errorMessages.clear();
        this.activeTab = 2;
        try {
            this.isStartExport = true;
            setTimeout(() => {
                this.progressBar?.nativeElement.animate(
                    [{ width: '0%' }, { width: '100%' }],
                    {
                        duration: 1500,
                        iterations: 100000000000,
                    }
                );   
            });
            let columnsName: string[] = [];
            this.exportColumns.forEach(e => {
                if (e.isChecked) {
                    columnsName.push(e.dbColumnName);
                }
            });
            this.dialogData.selectedItems.forEach((e: any) => {
                this.selectedIds.push(e.id);
            });
            await this.exportEmployeeList(this.selectedIds, columnsName);
            this.isExportDone = true;
        } catch (error: any) {
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        } finally {

        }

    }

    async exportEmployeeList(
        employeeIdList: number[],
        columns: string[]
    ): Promise<any> {
        const response = await lastValueFrom(this.http
            .post(
                `${APIURL + this.dialogData.exportApi}`,
                {
                    ids: employeeIdList,
                    columns: columns,
                },
                { responseType: 'blob' }
            ));
        this.fileService.downloadExportedFile(response, this.dialogData.fileName ?? 'exported-list');
        return response;
    }

    async getExportColumn(): Promise<ApiResponse<ExportColumns[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<ExportColumns[]>>(
                `${APIURL + this.dialogData.exportColumnApi}`
            )
        );
        return response;
    }

}
