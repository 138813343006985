<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">
            {{ "ATTENTION" | translate }}
        </h5>
    </div>
    <div class="close-icon" (click)="cancel()">
        <img (click)="cancel()" src="/assets/images/x-close-black.svg" />
    </div>
</div>

<div class="dialog-body">
    <div class="row body-long-1">
        <div class="col-12">
            {{
                (dialogData.type == "export"
                    ? "TRUCKIN_CENTRAL_WILL_NOT_EXPORT_YOUR_LISTIF_YOU_CANCEL"
                    : "TRUCKIN_CENTRAL_WILL_NOT_IMPORT_YOUR_LISTIF_YOU_CANCEL"
                ) | translate
            }}
            <br />
            <br />
            {{ "ARE_YOU_SURE_YOU_WANT_TO_CNTINUE" | translate }}
        </div>
    </div>

    <div class="row app-actions-button">
        <div class="col-md-4">
            <button class="secondary-button-large" (click)="confirm()">
                {{
                    (dialogData.type == "export"
                        ? "CONTINUE_EXPORT"
                        : "CONTINUE_IMPORT"
                    ) | translate
                }}
            </button>
        </div>
        <div class="col-md-4">
            <button class="primary-danger-button-large" (click)="cancel()">
                {{
                    (dialogData.type == "export"
                        ? "CANCEL_EXPORT"
                        : "CANCEL_IMPORT"
                    ) | translate
                }}
            </button>
        </div>
    </div>
</div>
