import { ColumnTable, } from './app-table.model';
import { GetDataOptions } from './shared.model';

export class ImportListMappedData {
    id?: number;
    documentId?: number;
    alreadyExistItems?: string;
    mappedColumns?: {
        sourceColumn: string;
        targetColumn: string;
    }[];

    constructor(init?: Partial<ImportListMappedData>) {
        Object.assign(this, init);
    }
}

export interface ExportListModalData {
    tableData: ColumnTable<any>[];
    getData: (options: GetDataOptions, showArchiver?: boolean) => Promise<any>;
    exportFunc: (IdList: number[], columns: string[]) => Promise<any>;
    exportColumns: ExportColumns[];
}

export enum ImportObjectTypeEnum {
    driver = 'Driver',
    truck = 'Truck',
    trailer = 'Trailer',
}

export interface ImporListtModalData {
    // tableData: ColumnTable<any>[];
    objectType: ImportObjectTypeEnum;
    getData: () => Promise<ColumnsFromAPI[]>;
    importFunc: (mappedData: ImportListMappedData) => Promise<any>;
}

export interface ColumnsFromAPI {
    fieldName: string;
    displayName: string;
    isRequired: boolean;
    replaces: string[];
    alternatives: string[];
}

export interface ExportColumns {
    dbColumnName: string;
    aliasColumnName: string;
    isChecked: boolean;
}
