<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">
            {{ dialogData.objectType + " Documents" }}
        </h5>
    </div>
    <div class="close-icon" (click)="cancel()">
        <img (click)="cancel()" src="/assets/images/x-close-black.svg" />
    </div>
</div>
<div [ngClass]="isBusy ? 'wrapper-disabled' : ''">
    <div class="dialog-body">
        <form [formGroup]="documentForm">
            <ng-container *ngFor="let doc of allDocsList; let i = index">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <tc-checkbox  [formControlName]="'checkbox-' + doc.documentId" [label]="doc.documentTitle" 
                                [id]="'doc-' + i" (changeValue)="onCheckbox($event, doc.documentId)"></tc-checkbox>
                        </div>
                    </div>
                    <ng-container
                        *ngIf="
                            dialogData.dropdownList &&
                            f['checkbox-' + doc.documentId].value == true
                        "
                    >
                        <div class="col-md-6">
                            <app-dropdown
                                (changeValueEvent)="
                                    onDropdownChange($event, doc.documentId)
                                "
                                [control]="f['dropdown-' + doc.documentId]"
                                [dropdownList]="dialogData.dropdownList"
                            ></app-dropdown>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </form>
    </div>
</div>
<div class="dialog-footer">
    <div class="row">
        <ng-container *ngIf="submitted && errorMessages.size > 0">
            <div
                class="invalid-form-warning"
                *ngFor="let message of errorMessages"
            >
                <div class="invalid-icon"></div>
                <div class="warning-message">
                    {{ message | translate }}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="app-actions-button">
        <ng-container>
            <button class="app-close-modal-button" (click)="cancel()">
                {{ "CANCEL" | translate }}
            </button>
            <button
                (click)="saveChanges()"
                class="app-add-changes-modal-button"
            >
                <span>
                    {{ "ADD_DOCUMENTS" | translate }}
                </span>
            </button>
        </ng-container>
        <ng-template #viewMode>
            <button class="app-close-modal-button" (click)="cancel()">
                {{ "CLOSE" | translate }}
            </button>
        </ng-template>
    </div>
</div>
