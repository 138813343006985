import { SharedService } from 'src/app/shared/services/shared.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, map } from 'rxjs';
import {
    ApiResponse,
    ApiResponseAny,
} from 'src/app/core/models/api-response.model';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertTypeEnum } from 'src/app/core/models/shared.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ContextService } from './context.service';
import { tcRequireToken } from 'src/app/core/interceptors/auth.interceptor';

const APIURL = `${environment.apiUrl}/api/`;

@Injectable({
    providedIn: 'root',
})
export class FileService {
    constructor(
        private http: HttpClient,
        private shared: SharedService,
        private spinner: NgxSpinnerService,
        private sanitizer: DomSanitizer,
        private contextService: ContextService
    ) { }

    async saveFile(file: File,isAnonymous: boolean = this.contextService.getUserId() ? false: true): Promise<ApiResponse<{ id: number }> | any> {
        try {
            // this.spinner.show();
            let formData = new FormData();
            formData.append('File', file);
            let response = await lastValueFrom(this.http
                .post<ApiResponse<{ id: number }>>
                (APIURL + (isAnonymous ? `file/token?t=${this.contextService.getAnonymousToken()}` : 'file'),
                formData,{context: tcRequireToken(isAnonymous ? false : true) }));
            this.spinner.hide();
            if (response.success) {
                return response.data;
            } else {
                throw new Error(
                    'file upload was not sucssessful: ' + response.error
                );
            }
        } catch (error: any) {
            console.log(error);
        } finally {
            // this.spinner.hide();
        }
    }

    async deleteFile(fileId: number): Promise<ApiResponseAny | any> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponseAny>(APIURL + 'file/' + fileId));
        if (response.success) {
            return response.success;
        } else {
            throw new Error(
                'delete was not successful: ' +
                response?.error?.code +
                ' : ' +
                response.error?.message
            );
        }
    }

    getFile(id: number,isAnonymous: boolean = this.contextService.getUserId() ? false: true): Observable<Blob> {
        let url = APIURL + (isAnonymous ?`file/${id}/token?t=${this.contextService.getAnonymousToken()}`: `file/${id}`);
        return this.http.get(url, {
            responseType: 'blob',
            context: tcRequireToken(isAnonymous ? false : true) 
        });
    }

    mergeToPdf(body: {}): Observable<Blob | any> {
        let url = APIURL + `file/MergeToPdf`;
        return this.http.post(url, body, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    getCompressFile(body: any): Observable<Blob | any> {
        let uri = APIURL + `file/Compress`;
        return this.http.post(uri, body, {
            observe: 'response',
            responseType: 'blob',
        });
    }

    async downloadFile(fileName: string, id: number | null, viewFileAfterDownload: boolean = false, allowSpinner: boolean = true) {
        if (allowSpinner)
            this.spinner.show();
        if (id == null) return;
        try {
            let response = await lastValueFrom(this.getFile(id));
            let newBlob: any = new Blob([response], { type: response.type });
            const data = window.URL.createObjectURL(newBlob);
            if (viewFileAfterDownload)
                window.open(data, '_blank');
            var link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );

            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        } catch (err) {
            this.shared.alertDangerMessage(
                'Download Failed',
                'There was an error downloading the file, please try again'
            );
        } finally {
            this.spinner.hide();
        }
    }

    async printFile(body: {}) {
        this.spinner.show();
        // if (id == null) return;
        try {
            let response = await lastValueFrom(this.mergeToPdf(body));
            var newBlob = new Blob([response.body], {
                type: response.body.type,
            });
            const data = window.URL.createObjectURL(newBlob);
            let iframe = document.createElement('iframe');
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = data;
            iframe.onload = function () {
                setTimeout(function () {
                    iframe.focus();
                    iframe.contentWindow?.print();
                }, 1);
            };
            return {
                sucsses: true
            }
        } catch (err) {
            this.shared.alert(
                'Error !!',
                'There was an error printing the file, please try again',
                AlertTypeEnum.Danger
            );
            return {
                sucsses: false
            }
        } finally {
            this.spinner.hide();
        }
    }

    getFileUrl(fileId: number): string {
        return `${APIURL}file/${fileId}`;
    }

    async downloadCompressFile(fileName: string, body: number[]) {
        this.spinner.show();
        try {
            let response = await lastValueFrom(this.getCompressFile(body));
            var newBlob = new Blob([response.body], {
                type: response.body.type,
            });
            const data = window.URL.createObjectURL(newBlob);

            var link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );

            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        } catch (err) {
            this.shared.alertDangerMessage(
                'Download Failed',
                'There was an error downloading the file, please try again'
            );
        } finally {
            this.spinner.hide();
        }
    }

    async downloadExportedFile(response: any, fileName: string) {
        this.spinner.show();
        try {
            // let response = await lastValueFrom(this.getFile(id));
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([response], { type: response.type });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //     window.navigator.msSaveOrOpenBlob(newBlob, fileName);
            //     return;
            // }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);

            var link = document.createElement('a');
            link.href = data;
            link.download = fileName;
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );

            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        } catch (err) {
            this.shared.alertDangerMessage(
                'Download Failed',
                'There was an error downloading the file, please try again'
            );
        } finally {
            this.spinner.hide();
        }
    }

    fetchImageWithToken(fileId: number): Observable<SafeUrl> {
        let url = `${APIURL}file/${fileId}`;
        return this.http
            .get(url, { responseType: 'blob' })
            .pipe(map(blob => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))));
    }
}
