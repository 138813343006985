<div class="row">
    <div class="col-12">
        <ul class="breadcrumb">
            <ng-container *ngFor="let item of config.list">
                <li>
                    <a
                        role="button"
                        [routerLink]="
                            item.routerLink ? '/' + item.routerLink : null
                        "
                    >
                        <!-- (click)="backToList()" -->
                        {{ item.title | translate }}
                        <ng-container *ngIf="item.info">
                            {{ item.info && item.title ? "-" : "" }}
                            {{ item.info | translate }}
                        </ng-container>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>
<div class="row">
    <div class="col-12 d-flex align-items-center">
        <span [routerLink]="'/' + config.backButtonLink" class="page-back" *ngIf="config.backButtonLink">
            <i class="icon-arrow-narrow-left"></i>
        </span>

        <div class="page-title">
            <span>{{
                isLoading
                    ? (config.mainTitle | translate) +
                      " - " +
                      ("LOADING" | translate) +
                      "..."
                    : config.editMode
                    ? (config.mainTitle | translate) +
                      (config.mainTitle && config.mainInfo ? " - " : "") +
                      (config.mainInfo | translate)
                    : (config.newObjectTitle | translate) +
                      " - " +
                      ("NEW" | translate)
            }}</span>

            <ng-container *ngIf="config.status">
                <span [class]="config.status.statusClasses">
                    {{ config.status.statusLabel ?? "" | translate }}
                </span>
            </ng-container>
        </div>
    </div>
</div>

<!-- <div class="page-title">
    <span *ngIf="!config?.editMode">{{
        config.newObjectTitle | translate
    }}</span>
    <span *ngIf="config?.editMode"
        >{{ config.mainTitle | translate }} -
        <span *ngIf="config?.mainInfo; else loading">
            {{ config.mainInfo }}
        </span>
        <ng-template #loading>
            <span style="color: #727272"
                >{{ "Loading" | translate }} ...</span
            >
        </ng-template>
    </span>
</div> -->
