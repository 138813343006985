<ng-container
    *ngIf="(errors.length == 1 && warnings.length == 0) || (warnings.length == 1 && errors.length == 0);else showDropdown">
    <div class="row">
        <div class="col-12">
            <ng-container *ngFor="let err of errors;let i = index">
                <!-- <ng-container *ngFor="let errorText of err"> -->
                <div class="error mt-3">
                    <div class="error-icon"></div>
                    {{ err }}
                </div>
                <!-- </ng-container> -->
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ng-container *ngFor="let warn of warnings;let i = index">
                <!-- <ng-container *ngFor="let warning of warn"> -->
                <div class="warning mt-3">
                    <div class="warning-icon"></div>
                    {{ warn }}
                </div>
            </ng-container>
            <!-- </ng-container> -->
        </div>
    </div>
</ng-container>
<ng-template #showDropdown>
    <div class="btn-group dropup mt-3" *ngIf="warnings.length > 0 || errors.length > 0">
        <button type="button" class="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="icon-info-circle" style="color: white;"></i>
            <span>{{warnings.length + errors.length}}</span>
        </button>
        <ul class="dropdown-menu">
            <li>
                <h6 class="heading-6">{{"ERRORS" |translate}}</h6>
            </li>
            <li *ngFor="let warn of warnings;let i = index">
                <div class="warning mt-3">
                    <div class="warning-icon"></div>
                    {{ warn }}
                </div>
            </li>
            <li *ngFor="let err of errors;let i = index">
                <div class="error mt-3">
                    <div class="error-icon"></div>
                    {{ err }}
                </div>
            </li>
        </ul>
    </div>
</ng-template>