import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TruckAux } from 'src/app/modules/Trucks/models/trucks.model';
import { TrucksApiService } from 'src/app/modules/Trucks/services/trucks-api.service';
import { InputUnitTypeEnum } from 'src/app/shared/model/shared.model';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
    selector: 'app-truck-quick-add',
    templateUrl: './truck-quick-add.component.html',
    styleUrls: ['./truck-quick-add.component.scss'],
})
export class TruckQuickAddComponent implements OnInit {
    allowEdit: boolean = true;
    editMode: boolean = true;
    errorMessages: Set<string> = new Set();
    submitted: boolean = false;
    todayDate: Date = new Date();
    constructor(
        private dialogRef: MatDialogRef<TruckQuickAddComponent>,
        private fb: UntypedFormBuilder,
        private truckApiService: TrucksApiService,
        private formService: FormService) {}
    quickAddTruckForm = this.fb.group({
        truckId: [null, Validators.required],
        make: [],
        model: [],
        year: [],
        vin: [],
        value: [],
    });
    ngOnInit(): void {}

    cancel() {
        this.dialogRef.close(null);
    }

    get f() {
        return this.quickAddTruckForm.controls;
    }

    populateForm() {
        let model = new TruckAux({
            assetCode: this.f.truckId.value,
            make: this.f.make.value,
            model: this.f.model.value,
            modelYear: Number(this.f.year.value),
            vin: this.f.vin.value,
            assetValue: Number(this.f.value.value),
        });

        return model;
    }

    async saveChanges() {
        this.submitted = true;
        if (this.quickAddTruckForm.invalid) return;

        try {
            const response = await this.truckApiService.addTruckAux(
                this.populateForm()
            );
            if (response.success) {
                this.dialogRef.close(response.data);
            }
        } catch (error: any) {
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        }
    }
    get InputUnitTypeEnum() {
        return InputUnitTypeEnum;
    }

}
