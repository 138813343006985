import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimZeros'
})
export class TrimZerosPipe implements PipeTransform {
  transform(value: number): string {
    const stringValue = value.toFixed(2); // Ensure exactly 2 decimal places
    return stringValue.replace(/\.0*$/, ''); // Remove trailing zeros and decimal point if necessary
  }
}
