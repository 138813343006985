import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myfilter',
    pure: false
})
export class MyFilterPipe implements PipeTransform {
    transform(items: any[], filter: string): any {
        if (!items || !filter) {
            return items;
        }
        var array = filter.split(' , ');
        if (array.length > 1) {
            return items.filter(f => array.some(item => item == f.text));
        } else {
            return items.filter(item => item.text.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
        }
    }
}