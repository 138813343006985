import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: 'input'
})
export class SpaceFirstLetterDirective {

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === ' ' && this.el.nativeElement.selectionStart === 0) {
            event.preventDefault();
        }
    }

    @HostListener('blur', ['$event.target.value'])
    onBlur(value: string) {
        const trimmedValue = value.trim();
        this.el.nativeElement.value = trimmedValue;
    }
}
