import {
    Component,
    ViewChild,
    EventEmitter,
    Output,
    OnInit,
    AfterViewInit,
    Input,
    forwardRef,
    ChangeDetectorRef,
    ElementRef,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { AddressObject } from '../../model/shared.model';

const validUSStates = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

@Component({
    selector: 'app-autocomplete-address',
    templateUrl: './autocomplete-address.component.html',
    styleUrls: ['./autocomplete-address.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AutocompleteAddressComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => AutocompleteAddressComponent),
        },
    ],
})
export class AutocompleteAddressComponent implements OnInit, AfterViewInit, ControlValueAccessor, Validator {
    constructor(private cdr: ChangeDetectorRef) { }
    @Input() placeholder: string = '';
    @Input() id: string = '';
    @Input() types: string = 'address';
    @Input() localtype: string | null = null;
    @Input() readonly: boolean = false;
    @Input() filedError: boolean = false;
    @Input() maxlength: number = 100;
    @Input() disabled: boolean = false;
    @Output() onAddressChange: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext') addresstext: ElementRef | null = null;

    onTouched = () => { };
    autocompleteInput: string = '';
    queryWait: boolean = false;
    addressObject: AddressObject | null = null;

    ngOnInit() { }

    onChange = (val: string = '') => { };

    writeValue(val: string = ''): void {
        this.autocompleteInput = val;
        this.cdr.detectChanges();
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    ngAfterViewInit() {
        if (!this.readonly) this.getPlaceAutocomplete();
    }

    validate(c: AbstractControl): ValidationErrors | null {
        const stateAbbreviation = c.value;
        if (this.types == 'administrative_area_level_1' && stateAbbreviation) {
            if (validUSStates.includes(String(stateAbbreviation).toUpperCase())) {
                return null; // Validation passed
            } else {
                return { 'invalidUSState': true }; // Validation failed
            }
        } else {
            return null;
        }
    }

    private getPlaceAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(
            this.addresstext!.nativeElement,
            {
                types: [this.types],
                componentRestrictions: { country: 'US' },
                strictBounds: false,
            }
        );
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.invokeEvent(place);
        });
    }

    invokeEvent(place: any) {
        let val: AddressObject = this.getAddressInfo(place);
        this.onAddressChange.emit(val);
        if (this.types == 'address') {
            if (this.localtype == 'fullAddress') {
                this.onChange(val.formattedAddress ? val.formattedAddress : "");
            } else {
                this.onChange(val.address1);
            }
        } else {
            this.onChange(val.state);
            this.addresstext!.nativeElement.value = val.state;
        }
    }

    onInput() {
        if (this.types == 'address' && this.addressObject && !this.localtype) {
            setTimeout(() => {
                this.addresstext!.nativeElement.value =
                    this.addressObject?.address1;
            }, 50);
        } else if (
            this.types == 'address' &&
            this.localtype == 'fullAddress' &&
            this.addressObject
        ) {
            this.onChange(this.addressObject.formattedAddress ? this.addressObject.formattedAddress : "");

            setTimeout(() => {
                this.addresstext!.nativeElement.value =
                    this.addressObject?.formattedAddress ? this.addressObject.formattedAddress : "";
            }, 50);
        }
    }

    getAddressInfo(event: google.maps.places.PlaceResult) {
        let addressObj: AddressObject = {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            lat: event?.geometry?.location?.lat() ?? 0,
            lng: event?.geometry?.location?.lng() ?? 0,
            url: event?.url ?? null
        };
        if (event && event.address_components) {
            event.address_components.forEach((component: any) => {
                if (component.types.findIndex(
                    (type: string) => type == 'administrative_area_level_1') > -1
                ) {
                    addressObj.state = component.short_name;
                }
                if (
                    component.types.findIndex(
                        (type: string) => type == 'locality'
                    ) > -1
                ) {
                    addressObj.city = component.long_name;
                }
                if (
                    component.types.findIndex(
                        (type: string) => type == 'postal_code'
                    ) > -1
                ) {
                    addressObj.zipCode = component.short_name;
                }
                if (
                    component.types.findIndex(
                        (type: string) => type == 'street_number'
                    ) > -1
                ) {
                    addressObj.address1 =
                        (addressObj.address1
                            ? addressObj.address1 + ' '
                            : addressObj.address1) + component.short_name;
                }
                if (
                    component.types.findIndex(
                        (type: string) => type == 'route'
                    ) > -1
                ) {
                    addressObj.address1 =
                        (addressObj.address1
                            ? addressObj.address1 + ' '
                            : addressObj.address1) + component.short_name;
                }
                if (
                    component.types.findIndex(
                        (type: any) => type == 'subpremise'
                    ) > -1
                ) {
                    addressObj.address2 = component.long_name;
                }
            });
            if(!addressObj.city) {
                addressObj.city = event.address_components.find(a => a.types.some( t => t == "neighborhood"))?.long_name ?? "";
            }
        }
        this.addressObject = addressObj;
        this.addressObject.formattedAddress = event.formatted_address;
        this.onInput();
        return addressObj;
    }

    updateValue() {
        if (this.autocompleteInput == '' || this.autocompleteInput == null) {
            this.onChange('');
        } else {
            this.autocompleteInput = this.autocompleteInput.split(' ').map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            }).join(' ');
        }
    }

    blurInput(e: any) {
        this.onChange(e.currentTarget.value);
    }
}
