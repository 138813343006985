import { TrailerAux } from './../../../../modules/Trailers/models/trailers.model';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnTypeEnum, TableOptions, TablesNameEnum } from 'src/app/shared/model/app-table.model';
import { TrailersApiService } from 'src/app/modules/Trailers/services/trailers-api.service';
import { ActionResponse, GetDataOptions } from 'src/app/shared/model/shared.model';

@Component({
    selector: 'app-add-trailer',
    templateUrl: './add-trailer.component.html',
    styleUrls: ['./add-trailer.component.scss']
})
export class AddTrailerComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<AddTrailerComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: {
            selectedTrailersIds: number[]
        },
        private trailersApiService: TrailersApiService
    ) { }
    selectedRows: TrailerAux[] = [];
    dataTableConfig: TableOptions<TrailerAux> | null = null;

    ngOnInit(): void {
        this.dataTableConfig = {
            name: TablesNameEnum.AddTrailer,
            allowSearch: true,
            allowOrdering: true,
            allowSettings: true,
            column: [
                {
                    label: '',
                    key: '',
                    type: ColumnTypeEnum.checkbox,
                    width: "4.5rem",
                    hideSorting: true,
                    visible: true
                },
                {
                    label: 'TRUCK_ID',
                    key: 'assetCode',
                    visible: true,
                },

                {
                    label: 'MAKE',
                    key: 'make',
                    visible: true,
                },
                {
                    label: 'MODEL',
                    key: 'model',
                    visible: true,
                },
                {
                    label: 'YEAR',
                    key: 'modelYear',
                    visible: true,
                },
                {
                    label: 'PLATE_NUMBER',
                    key: 'plateNumber',
                    visible: true,
                },
            ],
            allowActions: false,
            getData: (searchObj: GetDataOptions) => this.getData(searchObj),
            allowCards: false,
            tableHeight: "40rem"
        };
    }

    async getData(options: GetDataOptions): Promise<ActionResponse<TrailerAux>> {
        try {
            let optionsApi = {
                searchString: options?.searchString || '',
                pageIndex: options.pageIndex,
                pageSize: options.pageSize,
                sort: options.sort ?? '',
                searchById: 0
            };
            let response = await this.trailersApiService.getTrailersListAux(
                optionsApi,
                false
            );
            if (response && response.data) {
                if (this.dialogData.selectedTrailersIds) {
                    response.data.pageData = response.data.pageData.filter(
                        (item) => !this.dialogData.selectedTrailersIds.includes(item.assetId ? +item.assetId : 0)
                    );
                }
                return {
                    success: true,
                    data: response.data,
                    isRefresh: true,
                };
            } else {
                return {
                    success: false,
                };
            }
        } catch (error: any) {
            return {
                success: false,
            };
        }
    }

    onSelectRow(list: TrailerAux[]) {
        this.selectedRows = list;
    }

    addTruks() {
        this.dialogRef.close(this.selectedRows);
    }

    close() {
        this.dialogRef.close();
    }

}
