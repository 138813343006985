import {
    Trailers,
    TrailerBasicInformation,
    TrailerRegistration,
    TrailerInformation,
    VinNumberInfo,
    TrailerAux,
} from './../models/trailers.model';

import {
    ApiResponse,
    ApiResponseAny,
    PagingData,
} from './../../../core/models/api-response.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import {
    OtherDocument,
    TruckNotInsured,
    TruckShouldUnInsure,
} from '../../Trucks/models/trucks.model';
import { NotInsured } from '../../driver/models/driverview.model';
import {
    ExportColumns,
    ImportListMappedData,
} from 'src/app/shared/model/import-export-list.model';
import { FileService } from 'src/app/shared/services/file.service';
import { lastValueFrom } from 'rxjs';
import { GetDataOptions } from 'src/app/shared/model/shared.model';
import { RepairsList } from '../../repairs/models/repairs.model';

const APIURL = `${environment.apiUrl}/`;

@Injectable({
    providedIn: 'root',
})
export class TrailersApiService {
    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'json',
        }),
        responseType: 'json' as 'json',
    };
    constructor(private http: HttpClient, private fileService: FileService) { }

    async getTrailersList(
        options: GetDataOptions,
        isActive: boolean = true
    ): Promise<ApiResponse<PagingData<Trailers>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<Trailers>>>(
                APIURL +
                `cmp/AssetTrailer/fplist?searchString=${options.searchString}` +
                `&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}&isActive=${isActive}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getTrailerBasicInformation(
        trailerId: number
    ): Promise<ApiResponse<TrailerBasicInformation>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<TrailerBasicInformation>>(
                APIURL + `cmp/AssetTrailer/${trailerId}`
            ));
        if (response.success) {
            return response;
        } else
            return {
                success: false,
                data: null,
            };
    }

    async updateTrailerBasicInformation(
        trailerId: number,
        body: TrailerBasicInformation
    ): Promise<ApiResponse<TrailerInformation>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<TrailerInformation>>(
                APIURL + `cmp/AssetTrailer/${trailerId}/BasicInformation`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async addTrailerBasicInformation(
        body: TrailerBasicInformation
    ): Promise<ApiResponse<TrailerInformation>> {
        let response = await lastValueFrom(this.http
            .post<ApiResponse<TrailerInformation>>(
                APIURL + `cmp/AssetTrailer/BasicInformation`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async updateTrailerSpecification(
        trailerId: number,
        body: TrailerBasicInformation
    ): Promise<ApiResponse<TrailerInformation>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<TrailerInformation>>(
                APIURL + `cmp/AssetTrailer/${trailerId}/Specification`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async updateTrailerRegistration(
        trailerId: number,
        registrationModel: TrailerRegistration
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${APIURL}cmp/AssetTrailer/${trailerId}/Registration`,
                registrationModel
            ));

        return response;
    }

    async getTrailerInformation(
        trailerId: number
    ): Promise<ApiResponse<TrailerInformation>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<TrailerInformation>>(
                APIURL + `cmp/AssetTrailer/${trailerId}`
            ));
        if (response.success) {
            return response;
        } else
            return {
                success: false,
                data: null,
            };
    }

    async getDecodeVIN(vinNumber: number): Promise<ApiResponse<VinNumberInfo>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<VinNumberInfo>>(
                APIURL + `cmp/AssetTruck/DecodeVIN/${vinNumber}`
            ));
        if (response.success) {
            return response;
        } else
            return {
                success: false,
                data: null,
            };
    }

    async getOtherDocuments(
        assetId: number,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<OtherDocument>>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<OtherDocument>>>(
                `${APIURL}cmp/AssetTrailer/${assetId}/Documents?pageSize=${options?.pageSize
                }&pageIndex=${options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : ''}&searchString=${options?.searchString
                }`
            ));

        return response;
    }

    async updateDocument(
        assetId: number,
        documentModel: OtherDocument
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${APIURL}cmp/AssetTrailer/${assetId}/Document/${documentModel.id}`,
                documentModel
            ));

        return response;
    }

    async addDocument(
        assetId: number,
        documentModel: OtherDocument
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .post<ApiResponseAny>(
                `${APIURL}cmp/AssetTrailer/${assetId}/Document`,
                documentModel
            ));

        return response;
    }

    async deleteDocument(
        assetId: number,
        documentId: number
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .delete<ApiResponseAny>(
                `${APIURL}cmp/AssetTrailer/${assetId}/Document/${documentId}`
            )
        );

        return response;
    }

    async getTrailersListAux(
        options?: GetDataOptions,
        isActive: boolean = true
    ): Promise<ApiResponse<PagingData<TrailerAux>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<TrailerAux>>>(
                APIURL +
                `cmp/TrailerAux/FpList?pageSize=${options?.pageSize ? options?.pageSize : 50
                }&searchById=${options?.searchById ?? 0}&pageIndex=${options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : ''
                }&searchString=${options?.searchString ? options?.searchString : ''
                }
                &isActive=${isActive}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async addTrailerAux(truck: TrailerAux): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .post<ApiResponseAny>(`${APIURL}cmp/TrailerAux`, truck));

        return response;
    }

    async getShouldInsureFpList(): Promise<ApiResponse<TruckNotInsured[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<TruckNotInsured[]>>(
                APIURL + `cmp/AssetTrailer/ShouldInsure/FpList`
            ));
        return response;
    }

    async getShouldUnInsureFpList(): Promise<
        ApiResponse<TruckShouldUnInsure[]>
    > {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<TruckShouldUnInsure[]>>(
                APIURL + `cmp/AssetTrailer/ShouldUnInsure/FpList`
            ));
        return response;
    }

    async checkShouldInsure(trailerId: number): Promise<ApiResponse<boolean>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<boolean>>(
                APIURL + `cmp/AssetTrailer/${trailerId}/ShouldInsure`
            ));
        return response;
    }

    async checkShouldUnInsure(
        trailerId: number
    ): Promise<ApiResponse<boolean>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<boolean>>(
                APIURL + `cmp/AssetTrailer/${trailerId}/ShouldUnInsure`
            ));
        return response;
    }

    /**Import/Export */

    async getExportColumn(): Promise<ApiResponse<ExportColumns[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<ExportColumns[]>>(
                `${APIURL}cmp/AssetTrailer/ExportColumn/FpList`
            )
        );

        return response;
    }

    async getTrailerTableColumnsAux(): Promise<ApiResponse<string[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<string[]>>(
                `${APIURL}cmp/AssetTrailer/ImportColumn/FpList`
            )
        );

        return response;
    }

    async importTrailerList(
        mappedColoums: ImportListMappedData
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${APIURL}cmp/AssetTrailer/Import`,
                mappedColoums
            ));
        return response;
    }

    async exportTrailerList(
        employeeIdList: number[],
        columns: string[]
    ): Promise<any> {
        const response = await lastValueFrom(this.http
            .post(
                `${APIURL}cmp/AssetTrailer/Export`,

                {
                    ids: employeeIdList,
                    columns: columns,
                },
                { responseType: 'blob' }
            ));
        this.fileService.downloadExportedFile(response, 'exported-list');

        return response;
    }

    /** */

    async getRepairHistoryList(
        id: number,
        options: GetDataOptions
    ): Promise<ApiResponse<PagingData<RepairsList>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<RepairsList>>>(
                APIURL +
                `cmp/AssetTruck/${id}/QueryInsight/RepairHistory?searchString=${options.searchString}` +
                `&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }
}
