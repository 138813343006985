<div class="stay-updated-container">
    <div class="header">
        <div class="image">
            <i class="icon-bell-ringing-04"></i>
        </div>
        <div class="desc">
            <h5 class="heading-5">{{"STAY_UPDATED" |translate}}</h5>
            <p class="body-short-1">{{"STAY_UPDATED_DESC" | translate}}</p>
        </div>
    </div>
    <div class="actions">
        <div class="app-actions-button">
            <button type="button" class="app-close-modal-button" (click)="close()">
                {{ "NO_THANKS" | translate }}
            </button>
            <button type="submit" class="app-add-new-on-modal-button" (click)="allow()">
                {{ "ALLOW" | translate }}
            </button>
        </div>
    </div>
</div>
