import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appTimeInput]'
})
export class TimeInputDirective {
  @HostListener('input', ['$event'])
  onInputChange(event: any) {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (value.length === 5) {
      const [hours, minutes] = value.split(':');
      const minutesInt = parseInt(minutes, 10);

      if (minutesInt > 59) {
        input.value = `${hours}:59`;
      }
    }
  }

  @HostListener('blur',['$event'])
  onBlur(event: any): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const [hours, minutes] = value.split(':');
    const hoursInt = parseInt(hours, 10);
    const minutesInt = parseInt(minutes, 10);
    if(hoursInt < 10 && isNaN(minutesInt)){
      input.value = `0${hoursInt}:00`;
    }else if(hoursInt < 10 && !isNaN(minutesInt)){
      input.value = `0${hoursInt}:${minutesInt}`;
    }
    if(hoursInt > 9 && isNaN(minutesInt)){
      input.value = `${hoursInt}:00`;
    }
    if(minutesInt < 10){
      input.value = `${hours}:0${minutesInt}`;
    }    
  }
}
