import { DriverAnnualReview, DriverDocument, DriverStatistics, EmployeeNotInsured, EmployeeShouldUnInsure, NotInsured, UserAux } from './../models/driverview.model';
import { ExportColumns } from './../../../shared/model/import-export-list.model';
import { Subject, BehaviorSubject, lastValueFrom } from 'rxjs';
import { EmployeeTypeEnum, GetDataOptions } from './../../../shared/model/shared.model';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
    ApiResponse,
    ApiResponseAny,
    PagingData,
} from 'src/app/core/models/api-response.model';
import { environment } from 'src/environments/environment';

import { DriverCdl } from '../models/driver-cdl.model';
import { DriverEmployment } from '../models/driver-employment';
import { DriverExperience } from '../models/driver-experience.model';
import { DriverIncident } from '../models/driver-incident.model';
import { DriverResidence } from '../models/driver-residence.model';
import { DriverViolation } from '../models/driver-violation.model';
import { Driverview, EmployeeAux } from '../models/driverview.model';
import { ImportListMappedData } from 'src/app/shared/model/import-export-list.model';
import { FileService } from 'src/app/shared/services/file.service';
import { tcRequireCompany } from 'src/app/core/interceptors/auth.interceptor';
import { ContextService } from 'src/app/shared/services/context.service';
import { DownloadReportOptions } from 'src/app/shared/model/download-report-dialog.model';
import { ChangeEmail } from '../../employees/models/employee.model';
import { IntervalEnum } from '../../eld-monitoring/models/eld-monitoring.model';

const APIURL = `${environment.apiUrl}/cmp/`;

@Injectable({
    providedIn: 'root',
})
export class DriverService implements OnDestroy {
    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'json',
        }),
        responseType: 'json' as 'json',
    };

    constructor(private http: HttpClient, private fileService: FileService, private contextService: ContextService) { }
    // driverName$ = new BehaviorSubject<string>('');
    // driverInfo$ = new Subject<Driverview>();`
    allFieldDone$ = new BehaviorSubject<boolean | null>(null);
    shouldInsure$ = new BehaviorSubject<boolean | null>(null);
    shouldUnInsure$ = new BehaviorSubject<boolean | null>(null);
    inActiveDriver$ = new BehaviorSubject<boolean | null>(null);
    containTerminationLetter$ = new BehaviorSubject<boolean | null>(null);
    terminationReason$ = new BehaviorSubject<string | null>(null);
    driverName:string = '';
    newImportCompletion$ = new Subject<void>();
    driverId: number = 0;

    getDriverName(): string {
        return this.driverName;
    };
    setDriverName(newdriverName: string) {
        this.driverName = newdriverName;
    }
    
    downloadReportOptions: DownloadReportOptions = {
        name: "SELECT_ALL",
        checked: false,
        sub: [
            {
                name: 'PERSONAL_INFORMATION',
                key: "personalInformation",
                checked: false,
                isAttachment: false
            },
            {
                name: 'HIRING_LETTER',
                key: 'isHiringDocument',
                parent: 'personalInformation',
                checked: false,
                isAttachment: true
            },
            {
                name: 'MEDICAL_CARD',
                key: 'isMedicalCard',
                parent: 'personalInformation',
                checked: false,
                isAttachment: true
            },
            {
                name: 'EXPERIENCE_&_TRAINING',
                key: 'experience',
                checked: false,
                isAttachment: false
            },
            {
                name: 'RESIDENCE',
                key: 'residence',
                checked: false,
                isAttachment: false
            },
            {
                name: 'EMPLOYMENT',
                key: 'employment',
                checked: false,
                isAttachment: false
            },
            {
                name: 'CDL_INFORMATION',
                key: 'cdlInformation',
                checked: false,
                isAttachment: false
            },
            {
                name: 'CDL_COPY',
                key: 'isCdlCopy',
                parent: 'cdlInformation',
                checked: false,
                isAttachment: true
            },
            {
                name: 'VIOLATIONS',
                key: 'violations',
                checked: false,
                isAttachment: false
            },
            {
                name: 'INCIDENTS',
                key: 'incidents',
                checked: false,
                isAttachment: false
            },
            {
                name: 'ONBOARDING',
                key: 'onboarding',
                checked: false,
                isAttachment: false
            },
            {
                name: 'ANNUAL_REVIEW',
                key: 'annualReview',
                checked: false,
                isAttachment: false
            },
            {
                name: 'MVR_REPORT',
                key: 'isMvrReport',
                parent: 'annualReview',
                checked: false,
                isAttachment: true
            },
            {
                name: 'CLEARINGHOUSE_REPORT',
                key: 'isClearinghouseReport',
                parent: 'annualReview',
                checked: false,
                isAttachment: true
            },
            {
                name: 'ANNUAL_REVIEW_REPORT',
                key: 'isAnnualReviewReport',
                parent: 'annualReview',
                checked: false,
                isAttachment: true
            },
            {
                name: 'OTHER_DOCUMENTS_LIST',
                key: 'otherDocument',
                checked: false,
                isAttachment: false
            },
            {
                name: 'OTHER_DOCUMENTS',
                key: 'isOtherDocumentAttachment',
                parent: 'otherDocument',
                checked: false,
                isAttachment: true
            },
            /** Application  **/
            {
                name: 'INVESTIGATION_INSURANCE_APPROVAL',
                key: 'investigationInsuranceApproval',
                checked: false,
                isAttachment: false,
                isApplication: true
            },
            {
                name: 'INVESTIGATION_CLEARINGHOUSE',
                key: 'investigationClearinghouse',
                checked: false,
                isAttachment: false,
                isApplication: true
            },
            {
                name: 'CLEARINGHOUSE_AGREEMENT',
                key: 'isClearinghouseAgreement',
                parent: 'investigationClearinghouse',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'CLEARINGHOUSE_REPORT',
                key: 'isClearinghouseReport',
                parent: 'investigationClearinghouse',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'INVESTIGATION_MVR',
                key: 'investigationMvr',
                checked: false,
                isAttachment: false,
                isApplication: true
            },
            {
                name: 'MVR_AGREEMENT',
                key: 'isMvrAgreement',
                parent: 'investigationMvr',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'MVR_REPORT',
                key: 'isMvrReport',
                parent: 'investigationMvr',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'INVESTIGATION_PSP',
                key: 'investigationPsp',
                checked: false,
                isAttachment: false,
                isApplication: true
            },
            {
                name: 'PSP_AGREEMENT',
                key: 'isPspAgreement',
                parent: 'investigationPsp',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'PSP_REPORT',
                key: 'isPspReport',
                parent: 'investigationPsp',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'INVESTIGATION_PREVIOUS_EMPLOYERS',
                key: 'previousEmployer',
                checked: false,
                isAttachment: false,
                isApplication: true,
            },
            {
                name: 'EMPLOYEE_AGREEMENT',
                key: 'isEmployeeAgreement',
                parent: 'previousEmployer',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'SAFETY_PERFORMANCE_HISTORY',
                key: 'isSafetyPerformanceHistory',
                parent: 'previousEmployer',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'TESTING_DRUG_AND_ALCOHOL_TEST',
                key: 'drugAlcoholTest',
                checked: false,
                isAttachment: false,
                isApplication: true,
            },
            {
                name: 'EPASSPORT',
                key: 'isEPassport',
                parent: 'drugAlcoholTest',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'TEST_RECEIPT',
                key: 'isTestReceipt',
                parent: 'drugAlcoholTest',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'TEST_RESULT',
                key: 'isTestResult',
                parent: 'drugAlcoholTest',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
            {
                name: 'TESTING_DRIVING_TEST',
                key: 'drivingTest',
                checked: false,
                isAttachment: false,
                isApplication: true,
            },
            {
                name: 'DRIVING_ROAD_TEST_CERTIFICATE',
                key: 'isDrivingRoadTestCertificate',
                parent: 'drivingTest',
                checked: false,
                isAttachment: true,
                isApplication: true
            },
        ],
    }
   
    public emitImportCompletion() {
        this.newImportCompletion$.next();
    }

    /* Driver Information API Calls */
    async saveDriverInformation(
        // companyId: number,
        driverModel: any
    ): Promise<ApiResponseAny> {
        let response;
        if (driverModel && driverModel.id) {
            response = await lastValueFrom(this.http
                .put<ApiResponseAny>(
                    // APIURL + `Company/${companyId}/Employee/${driverModel.id}`,
                    APIURL + `Employee/${driverModel.id}`,
                    driverModel,
                    this.requestOptions
                ));
        } else {
            response = await lastValueFrom(this.http
                .post<ApiResponseAny>(
                    APIURL + `Employee`,
                    driverModel,
                    this.requestOptions
                ));
        }
        return response;
    }

    // async deleteDriverInformation(
    //     companyId: number,
    //     employeeId: number,
    //     companyLocationId: number
    // ): Promise<ApiResponseAny> {
    //     let response = await lastValueFrom(this.http
    //         .delete<ApiResponseAny>(
    //             APIURL + `Company/${companyId}/Employee/${employeeId}`,
    //             this.requestOptions
    //         )
    //);
    //     return response;
    // }
    /* Driver Incident API Calls */
    async saveDriverIncident(
        employeeId: number,
        incident: DriverIncident
    ): Promise<ApiResponseAny> {
        if (incident.id) {
            let response = await lastValueFrom(this.http
                .put<ApiResponseAny>(
                    APIURL +
                    'Employee/' +
                    employeeId +
                    '/Incident/' +
                    incident.id,
                    incident,
                    this.requestOptions
                ));
            return response;
        } else {
            let response = await lastValueFrom(this.http
                .post<ApiResponseAny>(
                    APIURL + 'Employee/' + employeeId + '/Incident',
                    incident,
                    this.requestOptions
                ));
            return response;
        }
    }

    async deleteDriverIncident(
        employeeId: number,
        incidentId: number
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponseAny>(
                APIURL + 'Employee/' + employeeId + '/Incident/' + incidentId
            ));
        if (response.success) {
            return response;
        } else throw new Error('error deleting incident: ' + response.error);
    }

    async getDriverAllIncidents(
        employeeId: number,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<DriverIncident>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<DriverIncident>>>(
                APIURL +
                'Employee/' +
                employeeId +
                `/Incident/FpList?pageSize=${options?.pageSize}&pageIndex=${options?.pageIndex}&orderBy=${options?.sort}&searchString=${options?.searchString}`,
                this.requestOptions
            ));
        return response;
    }

    /* Driver Experience API Calls */

    async saveDriverExperience(
        employeeId: number,
        experienceModel: DriverExperience
    ): Promise<ApiResponseAny> {
        let response;
        if (experienceModel.id) {
            response = await lastValueFrom(this.http
                .put<ApiResponseAny>(
                    APIURL +
                    'Employee/' +
                    employeeId +
                    '/Training/' +
                    experienceModel.id,
                    experienceModel
                ));
        } else {
            response = await lastValueFrom(this.http
                .post<ApiResponseAny>(
                    APIURL + 'Employee/' + employeeId + '/Training',
                    experienceModel
                ));
        }
        return response;
    }

    async deleteDriverExperience(
        employeeId: number,
        experienceId: number
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponseAny>(
                APIURL + 'Employee/' + employeeId + '/Training/' + experienceId
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
            };
        }
    }

    async getDriverExperience(
        employeeId: number,
        experienceId: number
    ): Promise<ApiResponse<DriverExperience>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<DriverExperience>>(
                APIURL + 'Employee/' + employeeId + '/Training/' + experienceId
            ));
        return response;
    }

    async getDriverAllExperience(
        employeeId: number,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<DriverExperience>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<DriverExperience>>>(
                APIURL +
                'Employee/' +
                employeeId +
                `/Training/FpList?pageSize=${options?.pageSize}&pageIndex=${options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : ''
                }&searchString=${options?.searchString}`
            ));
        return response;
    }

    /* Driver Residence API Calls */
    async saveDriverResidence(
        employeeId: number,
        model: DriverResidence
    ): Promise<ApiResponseAny> {
        if (model.id == 0) {
            // insert new record
            delete model.id;
            let response = await lastValueFrom(this.http
                .post<ApiResponseAny>(
                    APIURL + 'Employee/' + employeeId + '/Residential',
                    model,
                    this.requestOptions
                ));
            return response;
        } else {
            // update record here
            let response = await lastValueFrom(this.http
                .put<ApiResponseAny>(
                    APIURL +
                    'Employee/' +
                    employeeId +
                    '/Residential/' +
                    model.id,
                    model,
                    this.requestOptions
                ));
            return response;
        }
    }

    async getDriverAllResidentials(
        employeeId: number,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<DriverResidence>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<DriverResidence>>>(
                APIURL +
                'Employee/' +
                employeeId +
                `/Residential/FpList?pageSize=${options?.pageSize
                }&pageIndex=${options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : ''
                }&searchString=${options?.searchString}`,
                this.requestOptions
            ));
        return response;
    }

    async deleteDriverResidential(
        employeeId: number,
        residencyId: number
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponseAny>(
                APIURL +
                'Employee/' +
                employeeId +
                '/Residential/' +
                residencyId,
                this.requestOptions
            ));
        if (!response.success) {
        }
        return response;
    }
    /* Date Editing Methods -- old */
    dateDifference(endDate: Date, startDate: Date): string {
        if (endDate == null) {
            //endDate=new Date();
            return 'Present';
        }

        var date2 = new Date(endDate);
        var date1 = new Date(startDate);
        // differnce in years
        var diffYear = (date2.getTime() - date1.getTime()) / 1000;
        diffYear /= 60 * 60 * 24;
        // differnece in months
        var diffMonth = (date2.getTime() - date1.getTime()) / 1000;
        diffMonth /= 60 * 60 * 24 * 7 * 4;
        // calculate the time difference of two dates JavaScript
        var diffTime = date2.getTime() - date1.getTime();
        // calculate the number of days between two dates javascript
        var daysDiff = diffTime / (1000 * 3600 * 24);
        if (Math.abs(Math.round(diffYear / 365.25)) == 0) {
            if (Math.abs(Math.floor(diffMonth)) == 0) {
                // return days
                return daysDiff + ' days';
            } else {
                // return months
                return Math.abs(Math.floor(diffMonth)) + ' months';
            }
        } else {
            // return years
            return Math.abs(Math.round(diffYear / 365.25)) + ' years';
        }
    }

    /* Driver Employment API Calls */
    async saveDriverEmployment(
        model: DriverEmployment
    ): Promise<ApiResponseAny> {
        if (model.id == 0) {
            let response = await lastValueFrom(this.http
                .post<ApiResponseAny>(
                    APIURL + 'Employee/' + model.employeeID + '/Employment/',
                    model,
                    this.requestOptions
                ));
            return response;
        } else {
            let response = await lastValueFrom(this.http
                .put<ApiResponseAny>(
                    APIURL +
                    'Employee/' +
                    model.employeeID +
                    '/Employment/' +
                    model.id,
                    model,
                    this.requestOptions
                ));
            return response;
        }
    }

    async getDriverAllEmployment(
        employeeId: number,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<DriverEmployment>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<DriverEmployment>>>(
                APIURL +
                'Employee/' +
                employeeId +
                `/Employment/FpList?pageSize=${options?.pageSize
                }&pageIndex=${options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : ''
                }&searchString=${options?.searchString}`,
                this.requestOptions
            ));
        return response;
    }

    async deleteDriverEmployement(
        employeeId: number,
        employementId: number
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponseAny>(
                APIURL +
                'Employee/' +
                employeeId +
                '/Employment/' +
                employementId,
                this.requestOptions
            ));
        if (!response.success) {
        }
        return response;
    }

    /* Driver CDL API Calls */
    async getDriverAllCdls(
        employeeId: number,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<DriverCdl>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<DriverCdl>>>(
                // APIURL +
                //     'Employee/' +
                //     employeeId +
                //     '/CDL' +
                //     `?searchString=${options?.searchString}&orderBy=${
                //         options?.sort ? options.sort : 'issueDate desc'
                //     }&searchString=${options?.searchString}`,
                APIURL +
                'Employee/' +
                employeeId +
                '/CDL/FpList' +
                `?searchString=${options?.searchString}&orderBy=${options?.sort ? options.sort : 'issueDate desc'
                }&searchString=${options?.searchString}`,
                this.requestOptions
            ));
        return response;
    }

    async getDriverCDL(
        employeeId: number,
        CDLId: number
    ): Promise<ApiResponse<DriverCdl>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<DriverCdl>>(
                APIURL + 'Employee/' + employeeId + '/CDL/' + CDLId
            ));
        return response;
    }

    async saveDriverCDL(
        employeeId: number,
        model: DriverCdl | null
    ): Promise<ApiResponseAny> {
        if (model && model.id) {
            // update record here
            let response = await lastValueFrom(this.http
                .put<ApiResponseAny>(
                    APIURL + 'Employee/' + employeeId + '/CDL/' + model.id,
                    model,
                    this.requestOptions
                ));
            return response;
        } else {
            // insert new record
            let response = await lastValueFrom(this.http
                .post<ApiResponseAny>(
                    APIURL + 'Employee/' + employeeId + '/CDL',
                    model,
                    this.requestOptions
                ));
            return response;
        }
    }

    async deleteDriverCDL(
        employeeId: number,
        cdlId: number
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponseAny>(
                APIURL + 'Employee/' + employeeId + '/CDL/' + cdlId,
                this.requestOptions
            ));
        if (!response.success) {
        }
        return response;
    }

    /* Drivers & Employees API Calls */
    async getAllEmployees(
        isActive: boolean = true,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<Driverview>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<Driverview>>>(
                APIURL +
                `Employee/FpList?pageSize=${options?.pageSize}&pageIndex=${options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : ''
                }&searchString=${options?.searchString
                }&isActive=${isActive}`,
                this.requestOptions
            ));
        return response;
    }

    async getActiveEmployeeAux(
        dateInterval: IntervalEnum,
        type: EmployeeTypeEnum,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<EmployeeAux>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<EmployeeAux>>>(
                APIURL +
                `EmployeeAux/FpList?employeeType=${type}&dateInterval=${dateInterval}`,
                this.requestOptions
            ));
        return response;
    }

    async getAllEmployeesAux(
        isActive: boolean | null = true,
        type: EmployeeTypeEnum,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<EmployeeAux>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<EmployeeAux>>>(
                APIURL +
                `EmployeeAux/FpList?pageSize=${options?.pageSize
                }&pageIndex=${options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : ''
                }&searchString=${options?.searchString}&searchById=${options?.searchById
                }&isActive=${isActive == null ? '' : isActive}&employeeType=${type}`,
                this.requestOptions
            ));
        return response;
    }

    async getDriverInfo(
        // companyId: number,
        // companyLocationId: number,
        driverId: number
    ): Promise<ApiResponse<Driverview>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<Driverview>>(
                // `${APIURL}Company/${companyId}/Employee/${driverId}`
                `${APIURL}Employee/${driverId}`
            )
        );

        return response;
    }
    /* Driver Violation API Calls */

    async violationSearch(
        searchString: string,
        pageIndex?: string,
        pageSize?: string
    ): Promise<ApiResponse<PagingData<DriverViolation>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<DriverViolation>>>(
                APIURL +
                `ViolationAux/FpList?searchString=${searchString}&pageIndex=${pageIndex ?? 0
                }&pageSize=${pageSize ?? 10}`
            ));
        return response;
    }
    async getDriverAllViolation(
        employeeId: number,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<DriverViolation>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<DriverViolation>>>(
                APIURL +
                'Employee/' +
                employeeId +
                `/Violation/FpList?pageSize=${options?.pageSize
                }&pageIndex=${options?.pageIndex ? options.pageIndex : 0
                }&orderBy=${options?.sort ? options.sort : ''
                }&searchString=${options?.searchString}`
            ));
        if (response.success) {
            return response;
        } else {
            return response;
        }
    }

    async deleteDriverViolation(
        employeeId: number,
        violationId: number
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponseAny>(
                APIURL + 'Employee/' + employeeId + '/Violation/' + violationId
            ));
        return response;
    }

    async saveDriverViolation(
        employeeId: number,
        violationModel: DriverViolation | undefined
    ): Promise<ApiResponseAny> {
        if (violationModel && violationModel.id) {
            let response = await lastValueFrom(this.http
                .put<ApiResponseAny>(
                    APIURL +
                    'Employee/' +
                    employeeId +
                    '/Violation/' +
                    violationModel.id,
                    violationModel
                ));
            return response;
        } else {
            let response = await lastValueFrom(this.http
                .post<ApiResponseAny>(
                    APIURL + 'Employee/' + employeeId + '/Violation',
                    violationModel
                ));
            return response;
        }
    }

    async archiveDriver(
        // companyId: number,
        // companyLocationId: number,
        id: number,
        body: {}
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                APIURL + `Employee/${id}/Archive`,
                body,
                this.requestOptions
            ));
        return response;
    }

    /* Driver onBoarding API Calls */

    async updateDriverOnBarding(
        // companyId: number,
        employeeId: number,
        onboardingModel: any
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${APIURL}Employee/${employeeId}/Onboarding`,
                onboardingModel
            ));
        return response;
    }

    async getEmployeeTableColumnsAux(): Promise<ApiResponse<string[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<string[]>>(`${APIURL}Employee/ImportColumn/FpList`)
        );

        return response;
    }

    async exportEmployeeList(
        employeeIdList: number[],
        columns: string[]
    ): Promise<any> {
        const response = await lastValueFrom(this.http
            .post(
                `${APIURL}Employee/Export`,

                {
                    ids: employeeIdList,
                    columns: columns,
                },
                { responseType: 'blob' }
            ));
        this.fileService.downloadExportedFile(response, 'exported-list');

        return response;
    }

    async importEmployeeList(
        mappedColoums: ImportListMappedData
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(`${APIURL}Employee/Import`, mappedColoums));
        return response;
    }

    async getExportColumn(): Promise<ApiResponse<ExportColumns[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<ExportColumns[]>>(
                `${APIURL}Employee/ExportColumn/FpList`
            )
        );

        return response;
    }

    async inviteDriver(id: number): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(`${APIURL}Employee/${id}/Invite`, {}));
        return response;
    }

    async addDriverAux(driver: Driverview): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .post<ApiResponseAny>(`${APIURL}EmployeeAux`, driver));

        return response;
    }

    async getShouldInsureFpList(
    ): Promise<ApiResponse<EmployeeNotInsured[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<EmployeeNotInsured[]>>(
                APIURL + `Employee/ShouldInsure/FpList`
            ));
        return response;
    }

    async getShouldUnInsureFpList(
    ): Promise<ApiResponse<EmployeeShouldUnInsure[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<EmployeeShouldUnInsure[]>>(
                APIURL + `Employee/ShouldUnInsure/FpList`
            ));
        return response;
    }

    // async checkNotInsured(
    //     driverId: number
    // ): Promise<ApiResponse<NotInsured>> {
    //     let response = await lastValueFrom(this.http
    //         .get<ApiResponse<NotInsured>>(
    //             APIURL + `Employee/${driverId}/NotInsured`
    //         )
    //);
    //     return response;
    // }

    async checkShouldInsure(
        driverId: number
    ): Promise<ApiResponse<boolean>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<boolean>>(
                APIURL + `Employee/${driverId}/ShouldInsure`
            ));
        return response;
    }

    async checkShouldUnInsure(
        driverId: number
    ): Promise<ApiResponse<boolean>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<boolean>>(
                APIURL + `Employee/${driverId}/ShouldUnInsure`
            ));
        return response;
    }

    async terminationLetterHtml(id: number, reason: string): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .get<ApiResponseAny>(`${APIURL}Employee/${id}/TerminationLetterHtml?reason=${reason}`, {}));
        return response;
    }

    async terminationLetterPdf(id: number, reason: string): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .get<ApiResponseAny>(`${APIURL}Employee/${id}/TerminationLetterPdf?reason=${reason}`, {}));
        return response;
    }

    async generateTerminationLetterPdf(id: number, reason: string) {
        const response = await lastValueFrom(this.http
            .get(
                `${APIURL}Employee/${id}/TerminationLetterPdf?reason=${reason}`,
                {
                    responseType: 'blob',
                }
            ));

        // return response;
        var newBlob = new Blob([response], { type: response.type });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveOrOpenBlob(newBlob, fileName);
        //     return;
        // }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        link.download = reason == "LaidOff" ? "Laid-off" : reason;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );

        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
        }, 100);
    }

    async getUserListAux(
        options: GetDataOptions,
    ): Promise<ApiResponse<PagingData<UserAux>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<UserAux>>>(
                APIURL +
                `UserAux/FpList?searchString=${options.searchString
                }&searchById=${options.searchById ?? ''}
                &pageIndex=${options.pageIndex}&pageSize=${options.pageSize
                }&orderBy=${options.sort}
`,
                { context: tcRequireCompany(false) }
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async checkStatistics(
        isActive: boolean = true
    ): Promise<ApiResponse<DriverStatistics>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<DriverStatistics>>(
                APIURL + `Employee/FpList/Statistics?isActive=${isActive}`
            ));
        return response;
    }

    async getDriverOtherDocumentsList(
        driverId: number,
        options: GetDataOptions
    ): Promise<ApiResponse<PagingData<DriverDocument>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<DriverDocument>>>(
                APIURL +
                `Employee/${driverId}/Document/FpList?searchString=${options.searchString}` +
                `&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async deleteDriverDocument(
        documentId: number
    ): Promise<ApiResponse<DriverDocument>> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponse<DriverDocument>>(
                APIURL + `Employee/${this.contextService.getCompanyId()}/Document/${documentId}`
            ));
        return response;
    }

    async getDocumentDetails(
        documentId: number,
        employeeId: number
    ): Promise<ApiResponse<DriverDocument>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<DriverDocument>>(
                APIURL + `Employee/${employeeId}/Document/${documentId}`
            ));
        if (response.success) {
            return response;
        } else
            return {
                success: false,
                data: null,
            };
    }

    async editEmployeeDocument(
        body: DriverDocument,
        documentId: number,
        employeeId: number
    ): Promise<ApiResponse<DriverDocument>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<DriverDocument>>(
                APIURL + `Employee/${employeeId}/Document/${documentId}`,
                body
            ));
        return response;
    }

    async addEmployeeDocument(
        employeeId: number,
        body: DriverDocument
    ): Promise<ApiResponse<DriverDocument>> {
        let response = await lastValueFrom(this.http
            .post<ApiResponse<DriverDocument>>(
                APIURL + `Employee/${employeeId}/Document`,
                body
            ));
        return response;
    }

    ////////////////////////////////////
    async getDriverAnnualReviewList(
        employeeId: number,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<DriverAnnualReview>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<DriverAnnualReview>>>(
                APIURL +
                'Employee/' +
                employeeId +
                '/AnnualReview/FpList' +
                `?searchString=${options?.searchString}&orderBy=${options?.sort ? options.sort : 'issueDate desc'
                }&searchString=${options?.searchString}`,
                this.requestOptions
            ));
        return response;
    }

    async deleteAnnualReview(employeeId: number, id: number): Promise<ApiResponse<DriverAnnualReview>> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponse<DriverAnnualReview>>(
                APIURL +
                `Employee/${employeeId}/AnnualReview/${id}`,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async changeEmail(
        id: number,
        body: ChangeEmail
    ): Promise<ApiResponse<ChangeEmail>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<ChangeEmail>>(
                APIURL + `Employee/ChangeEmail/${id}`,
                body
            ));

        return response;
    }

    ngOnDestroy(): void {
        // this.driverInfo$.complete();
        // this.driverName$.complete();
        this.allFieldDone$.complete();
        this.shouldInsure$.complete();
        this.shouldUnInsure$.complete();
        this.containTerminationLetter$.complete();
    }
}
