<div class="dialog-header">
    <div class="row">
        <div class="col-11">
            <h5 class="dialog-title">{{ dialogData.title| translate }}</h5>
        </div>
    </div>
    <div class="close-icon" (click)="close()">
        <img (click)="close()" src="/assets/images/x-close-black.svg" />
    </div>
</div>
<div class="dialog-body">
    <ng-container *ngIf="!isStartDownload">
        <div class="row select-all">
            <div class="col-6">
                <mat-checkbox [checked]="allComplete" [indeterminate]="someComplete()"
                    (change)="setAll($event.checked)">
                    {{dialogData.downloadReportOptions.name | translate}}
                </mat-checkbox>
            </div>
            <div class="col-6 show-attachments" *ngIf="checkAttachment()">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="show-attachments" [(ngModel)]="showAttachments"
                        (ngModelChange)="showAttachmentsChange()" [disabled]="isBusy > 0" />
                    <label class="form-check-label body-short-1" for="show-attachments">{{"SHOW_ATTACHMENTS" |
                        translate}}</label>
                </div>
            </div>
        </div>
        <ul>
            <li *ngFor="let sub of dialogData.downloadReportOptions.sub">
                <ng-container *ngIf="!sub.isApplication;">
                    <ng-container *ngIf="sub.show">
                        <mat-checkbox [(ngModel)]="sub.checked" (ngModelChange)="updateAllComplete()">
                            <ng-container *ngIf="sub.isAttachment">
                                <i class="icon-attachment-01" style="font-size: 1.3rem;padding-right: 0.4rem;"></i>
                            </ng-container>
                            {{sub.name |translate}}
                        </mat-checkbox>
                    </ng-container>
                </ng-container>
                <hr *ngIf="sub.isContainHr">
            </li>
            <li *ngIf="hasApplication">
                <hr>
                <div class="heading-7">{{"APPLICATION" |translate}}</div>
            </li>
            <li *ngFor="let sub of dialogData.downloadReportOptions.sub">
                <ng-container *ngIf="sub.isApplication">
                    <ng-container *ngIf="sub.show">
                        <mat-checkbox [(ngModel)]="sub.checked" (ngModelChange)="updateAllComplete()">
                            <ng-container *ngIf="sub.isAttachment">
                                <i class="icon-attachment-01" style="font-size: 1.3rem;padding-right: 0.4rem;"></i>
                            </ng-container>
                            {{sub.name |translate}}
                        </mat-checkbox>
                    </ng-container>
                </ng-container>
            </li>
        </ul>
    </ng-container>
    <ng-container *ngIf="isStartDownload">
        <ng-container *ngIf="isExportFail">
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <img src="assets/images/amico.svg" class="front-img" alt="" />
                </div>
            </div>
            <div class="row mt-3 mb-4">
                <div class="col-12 front-title heading-5 d-flex justify-content-center">
                    Something went wrong!
                </div>
                <div class="col-12 body-short-1 d-flex justify-content-center">
                    We weren’t able to download your report. Please try again!
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isExportFail">
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <img src="/assets/images/list-proccessing.svg" class="front-img" alt="" />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 body-short-1 d-flex justify-content-center">
                    {{"GENERATE_REPORT_LOADING"| translate}}
                </div>
                <div class="col-12 d-flex justify-content-center mt-4 mb-3">
                    <div class="progress-bar-container">
                        <div class="progress-bar">
                            <div class="progress-bar-value"></div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

</div>
<div class="dialog-footer" *ngIf="!isStartDownload">
    <div class="row">
        <div class="invalid-form-warning" *ngFor="let message of errorMessages">
            <div class="invalid-icon"></div>
            <div class="warning-message">
                {{ message | translate }}
            </div>
        </div>
    </div>
    <div class="app-actions-button">
        <button type="button" class="app-close-modal-button" (click)="close()">
            {{ "CANCEL" | translate }}
        </button>
        <button type="submit" class="primary-button-large" (click)="downloadReport()" [disabled]="isBusy > 0 || !checkSelected()">
            <span>{{ "DOWNLOAD_REPORT" | translate }}</span>
        </button>
    </div>
</div>
<div class="dialog-footer" *ngIf="isExportFail">
    <div class="row">
        <div class="invalid-form-warning" *ngFor="let message of errorMessages">
            <div class="invalid-icon"></div>
            <div class="warning-message">
                {{ message | translate }}
            </div>
        </div>
    </div>
    <div class="app-actions-button">
        <button type="button" class="app-close-modal-button" (click)="close()">
            {{ "CLOSE" | translate }}
        </button>
    </div>
</div>