import { PhoneNumberService } from './../../services/phone-number.service';
import {
    TableActionList,
    ActionTypeEnum,
    ColumnSortOptions,
    ColumnTable,
    ColumnTypeEnum,
    TableDataModel,
    FunctionTypeEnum,
    TableOptions,
    ColumnLink,
} from './../../model/app-table.model';
import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
    HostListener
} from '@angular/core';
import { ThrottleCallService } from '../../services/throttle-call.service';
import { DateService } from '../../services/date.service';
import { SharedService } from '../../services/shared.service';
import { PagingData } from 'src/app/core/models/api-response.model';
import { ActionResponse, ApiFilterOptions, GetDataOptions } from '../../model/shared.model';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DownloadListReportComponent } from '../dialogs/download-list-report/download-list-report.component';
import { DownloadReportAdvanceComponent } from '../dialogs/download-report-advance/download-report-advance.component';
import { ExportListComponent } from '../dialogs/export-list/export-list.component';
import { FeatureService } from '../../services/feature.service';
import { CompanyUserPreferencesService } from '../../services/company-user-preferences.service';
import { Router } from '@angular/router';

@Component({
    selector: 'tc-data-table',
    templateUrl: './app-table.component.html',
    styleUrls: ['./app-table.component.scss'],
})
export class AppTableComponent<T extends TableDataModel>
    implements OnInit, AfterViewInit, OnDestroy {
    currentPage: number = 0;
    maxSize: number = 7;
    constructor(
        public phoneNumberService: PhoneNumberService,
        public dateService: DateService,
        public sharedService: SharedService,
        private matDialog: MatDialog,
        public featureService: FeatureService,
        private companyUserPreferencesService: CompanyUserPreferencesService,
        private router: Router
    ) { }

    @Input() set tableOptions(data: TableOptions<T>) {
        if (data.allowTableHeader == undefined) data.allowTableHeader = true;
        this.dataTableConfig = data;
        let columnLink = this.dataTableConfig.column.find(e => e.columnLink)?.columnLink;
        let dialogAction = this.dataTableConfig.column.find(e => e.dialogAction)?.dialogAction;
        this.dataTableConfig.column.forEach((e: any) => {
            if (e.columnLink == undefined && e.type != ColumnTypeEnum.action && e.type != ColumnTypeEnum.checkbox)
                e.columnLink = columnLink;
            if (e.dialogAction == undefined && e.type != ColumnTypeEnum.action && e.type != ColumnTypeEnum.checkbox)
                e.dialogAction = dialogAction;

        })
        let storageData = this.companyUserPreferencesService.getValue(this.dataTableConfig.name);
        if (storageData) {
            this.showAs = storageData.showAs;
            this.isActive = storageData.isActive;
            if (storageData.column) {
                this.dataTableConfig.column.forEach(originalColumn => {
                    storageData.column.forEach((storageColumn: any) => {
                        if (storageColumn.key == originalColumn.key) {
                            originalColumn.sortType = storageColumn.sortType;
                            originalColumn.visible = storageColumn.visible;
                            if (originalColumn.sortType) {
                                let sortingIndex = this.sortArray.findIndex(
                                    (e: ColumnSortOptions) => e.key.toLowerCase() == originalColumn.key.toLowerCase()
                                );
                                if (sortingIndex >= 0) {
                                    this.sortArray.splice(sortingIndex, 1);
                                }
                                this.sortArray.unshift({
                                    key: originalColumn.key.charAt(0).toUpperCase() + originalColumn.key.slice(1),
                                    sortType: originalColumn.sortType ?? 'asc',
                                });

                                let sortString: string = '';
                                this.sortArray.forEach((e: ColumnSortOptions, i: number) => {
                                    sortString +=
                                        e.key +
                                        ' ' +
                                        e.sortType +
                                        (this.sortArray.length - 1 == i ? '' : ',');
                                });
                                this.sortString = sortString;
                            }
                        }
                    });
                })
            }
        }
        // this.dataTableConfig?.column.forEach((e: ColumnTable<T>, i) => {
        //     e.order = i;
        // });
        this.pageSize =
            this.dataTableConfig && this.dataTableConfig?.pageLength
                ? this.dataTableConfig?.pageLength
                : 50;
        this.options = {
            sort: this.sortString,
            pageSize: this.pageSize,
            pageIndex: this.pageIndex,
            searchString: this.searchInput || '',
        };
        this.getData();
        // this.dataTableConfig.allowOrdering = false; // change by tareq
    }
    @Output() onSelectRow = new EventEmitter();
    @Output() showInactiveEvennt = new EventEmitter();

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        if (event.target.innerWidth <= 1540) {
            this.showActionButtonsResponsive = true;
        } else {
            this.showActionButtonsResponsive = false;
        }
    }

    dataTableConfig: TableOptions<T> | undefined;
    options: GetDataOptions = {
        sort: '',
        pageSize: 0,
        pageIndex: 0,
        searchString: '',
    };
    pageIndex: number = 0;
    pages: number = 1;
    totalCount: number = 0;
    pageSize: number = 10;
    hasNextPage: boolean = true;
    searchInput: string | undefined;
    sortArray: ColumnSortOptions[] = [];
    showRowFrom: number | undefined;
    showRowTo: number | undefined;
    tableData: T[] = [];
    sortString: string = '';
    isBusy: number = 0;
    selectedItems: T[] = [];
    selectedAll: boolean = false;
    allComplete: boolean = false;
    isCards: boolean = false;
    showAs: string = 'list';
    isActive: boolean = true;
    isArchived: boolean = false;
    showActionButtonsResponsive: boolean = false;
    public environment = environment;
    public get columnTypeEnum(): typeof ColumnTypeEnum {
        return ColumnTypeEnum;
    }

    public get actionTypeEnum(): typeof ActionTypeEnum {
        return ActionTypeEnum;
    }

    public get functionTypeEnum(): typeof FunctionTypeEnum {
        return FunctionTypeEnum;
    }

    public get hackTableData(): any[] {
        return this.tableData;
    }

    ngOnInit(): void {
        if (window.innerWidth <= 1540) {
            this.showActionButtonsResponsive = true;
        } else {
            this.showActionButtonsResponsive = false;
        }
    }

    checkActions(actionList: any, item: T) {
        return actionList.every((el: any) =>
            el.hideAction ? el.hideAction(item) : false
        );
    }

    ngAfterViewInit(): void {
        var tables = document.getElementsByTagName('table');
        // for (var i = 0; i < tables.length; i++) {
        //     tables[i].style.overflow = 'clip';
        // }
    }

    private isPaginated = (object: PagingData<T> | T[]): object is PagingData<T> => {
        return 'pageData' in object;
    };

    async refresh(resetPage = false) {
        // if (resetPage) {
        //     this.options.pageIndex = 0;
        // }
        this.resetOptionsAndData();
        this.firstCall = false;
        await this.getData();
        this.firstCall = true;
        this.selectedAll = false;
        this.selectAll();
    }

    private tc = new ThrottleCallService<ActionResponse<T>>();
    async getData(delay = 0, fromSearch: boolean = false, fromScroll: boolean = false) {
        try {
            if (this.dataTableConfig) {
                if (!fromScroll)
                    this.isBusy++;
                let filter: ApiFilterOptions = {
                    isActive: this.isActive,
                    isArchived: this.isArchived
                }
                let response = await this.tc.call(
                    () =>
                        this.dataTableConfig!.getData(
                            this.options,
                            filter
                        ),
                    delay
                );
                //console.log('completed fetch from database')
                if (fromSearch) {
                    this.resetOptionsAndData();
                }
                if (response && response.data) {
                    if (this.isPaginated(response.data)) {
                        if (this.options.pageIndex == 0) {
                            this.tableData = [];
                        }
                        this.pages = response.data.totalPages;
                        this.hasNextPage = response.data.hasNextPage;
                        this.tableData.push(...response.data.pageData);
                        this.tableData.forEach((e, i) => {
                            let temp = this.selectedItems.find(
                                (element) => element.id === e.id
                            );
                            if (temp) e.isChecked = true;
                            return e;
                        });
                        this.pageIndex = response.data.pageIndex;
                        this.totalCount = response.data.totalCount;
                        this.updatePagination();
                        this.updateAllComplete();
                        // if (this.selectedItems.length == this.tableData.length) {
                        //     this.selectedAll = true;
                        // } else {
                        //     this.selectedAll = false;
                        // }
                    } else {
                        this.pages = 0;
                        this.tableData = response.data;
                        this.pageIndex = 0;
                        this.totalCount = 0;
                        this.updatePagination();
                    }
                }
            }
        } catch (e) {
            if (typeof e === 'string' && e == 'aborted') console.log('aborted');
        } finally {
            if (!fromScroll)
                this.isBusy--;
        }
    }

    updatePagination() {
        this.showRowFrom = this.pageIndex * this.pageSize + 1;
        this.showRowTo =
            this.pageIndex * this.pageSize + 1 + this.tableData?.length - 1;
    }

    async searchApi() {
        this.options.searchString = this.searchInput;
        this.options.pageSize = 50;
        this.options.pageIndex = 0;
        await this.getData(1000, true);
        let el = document.getElementById("data-table");
        if (el) {
            el.scrollTop = 0
        }
    }

    changePage(pageNumber: number) {
        this.options.pageIndex = pageNumber - 1;
        this.getData();
    }

    firstCall: boolean = true;
    scrollTo: number = 0;
    isScrolledToBottom(el: any): boolean {
        return el.scrollHeight - el.scrollTop === el.clientHeight;
    }
    async onScroll(event: any) {
        const element = event.target as HTMLElement;
        // if (this.isScrolledToBottom(element))
        if (
            event.target.offsetHeight + event.target.scrollTop >=
            event.target.scrollHeight - 1000 &&
            this.firstCall &&
            (this.dataTableConfig?.getAllData == undefined || this.dataTableConfig?.getAllData == false)
        ) {
            this.firstCall = false;
            this.options.pageIndex = this.options.pageIndex + 1;
            if (this.hasNextPage) {
                await this.getData(0, false, true);
                this.firstCall = true;
            } else {
                this.firstCall = true;
            }
        }
    }

    sort(column: ColumnTable<T>) {
        if (this.dataTableConfig?.allowOrdering) {
            this.options.pageSize = 50;
            this.options.pageIndex = 0;
            // column.isSorting = true;
            switch (column.sortType) {
                case 'asc':
                    column.sortType = 'desc';
                    this.generateSortArray(column);
                    break;
                case 'desc':
                case undefined:
                    column.sortType = 'asc';
                    this.generateSortArray(column);
                    break;
            }
        }
    }

    async generateSortArray(column: ColumnTable<T>) {
        let sortingIndex = this.sortArray.findIndex(
            (e: ColumnSortOptions) => e.key.toLowerCase() == column.key.toLowerCase()
        );
        if (sortingIndex >= 0) {
            this.sortArray.splice(sortingIndex, 1);
        }
        this.sortArray.unshift({
            key: column.key.charAt(0).toUpperCase() + column.key.slice(1),
            sortType: column.sortType ?? 'asc',
        });

        let sortString: string = '';
        this.sortArray.forEach((e: ColumnSortOptions, i: number) => {
            sortString +=
                e.key +
                ' ' +
                e.sortType +
                (this.sortArray.length - 1 == i ? '' : ',');
        });
        this.sortString = sortString;
        this.options.sort = this.sortString || '';
        this.firstCall = false;
        this.resetOptionsAndData();
        await this.getData();
        this.firstCall = true;
    }

    async callActionColumn(column: ColumnTable<T>, item: T) {
        if (column && column.dialogAction) {
            if (column.isFuncAsync) {
                let response = await column.dialogAction(item);
                if (response.isRefresh) this.refresh();
            } else {
                let response = <ActionResponse<T>>column.dialogAction(item);
                if (response.isRefresh) this.refresh();
            }
        }
        if (column && column.columnLink) {
            if (typeof column.columnLink == 'function') {
                let url = column.columnLink(item);
                this.router.navigateByUrl(url);
            } else if (typeof column.columnLink == 'object'
                && typeof (column.columnLink as ColumnLink).columnLink == 'function') {
                let url = (column.columnLink as ColumnLink).columnLink!(item);
                this.router.navigateByUrl(url);
            }
        }
    }

    getColumnLink(column: ColumnTable<T>, item: T) {
        if (column && column.columnLink) {
            if (typeof column.columnLink == 'function') {
                let response = column.columnLink(item);
                return response;
            } else if (typeof column.columnLink == 'object'
                && typeof (column.columnLink as ColumnLink).columnLink == 'function') {
                let response = (column.columnLink as ColumnLink).columnLink!(item);
                return response;
            }
        }
        return null;
    }

    getIsOpenNewTab(column: ColumnTable<T>): boolean {
        if (column && column.columnLink) {
            if (typeof column.columnLink == 'object'
                && typeof (column.columnLink as ColumnLink).isOpenNewTab == 'boolean') {
                let response = (column.columnLink as ColumnLink).isOpenNewTab;
                return response;
            }
        }
        return false;
    }

    async callAction(action: TableActionList<T>, item: T) {
        if (action.functionType == FunctionTypeEnum.remove)
            await this.removeRow(item);
        else if (action.actionFunc) {
            if (action.isFuncAsync) {
                let response = await action.actionFunc(item);
                if (response.isRefresh) this.refresh();
            } else {
                let response = <ActionResponse<T>>action.actionFunc(item);
                if (response.isRefresh) this.refresh();
            }
        }
    }

    async callTableHeaderAction(action: TableActionList<T>) {
        if (action.actionFuncHeader) {
            if (action.isFuncAsync) {
                let response = await action.actionFuncHeader();
                if (response.isRefresh) this.refresh();
            } else {
                let response = <ActionResponse<T>>action.actionFuncHeader();
                if (response.isRefresh) this.refresh();
            }
        }
    }

    async addRow() {
        if (this.dataTableConfig && this.dataTableConfig.addRow) {
            let response = await this.dataTableConfig.addRow();
            if (response) {
                if (response.success && response.isRefresh) {
                    this.refresh();
                }
            }
        }
    }

    async removeRow(item: T) {
        try {
            if (this.dataTableConfig && this.dataTableConfig.removeRow) {
                let removeActionResp = null;
                if (item.id !== undefined)
                    removeActionResp = await this.dataTableConfig.removeRow(
                        item
                    );
                if (
                    removeActionResp == null ||
                    removeActionResp.success == true
                ) {
                    this.tableData.splice(this.tableData.indexOf(item), 1);
                    this.tableData = [];
                    this.options.pageIndex = 0;
                    this.getData();
                } else if (
                    removeActionResp != null &&
                    removeActionResp.success == false
                ) {
                    // this.sharedService.alert(
                    //     removeActionResp.errMsg ?? 'General error',
                    //     AlertTypeEnum.Danger
                    // );
                }
                this.updatePagination();
            }
        } catch {
        } finally {

        }
    }

    selectAll() {
        this.selectedItems = [];
        if (this.selectedAll) {
            this.tableData.forEach((e) => {
                e.isChecked = true;
                this.selectedItems.push(e);
            });
        } else {
            this.tableData.forEach((e) => {
                e.isChecked = false;
            });
            this.selectedItems = [];
        }
        this.onSelectRow.emit(this.selectedItems);
    }

    selectItem(event: any, item: T) {
        if (event.target.checked) {
            item.isChecked = true;
            this.selectedItems.push(item);
            if (this.selectedItems.length == this.tableData.length) {
                this.selectedAll = true;
            } else {
                this.selectedAll = false;
            }
        } else {
            item.isChecked = false;
            let index = this.selectedItems.findIndex(function (e) {
                return e.id == item.id;
            });
            if (index !== -1) this.selectedItems.splice(index, 1);
            if (this.selectedItems.length != this.tableData.length)
                this.selectedAll = false;
        }
        if (this.selectedItems.length == 0) {
            this.selectedAll = false;
        }
        this.onSelectRow.emit(this.selectedItems);
    }

    deselectAll() {
        this.tableData.forEach((e) => {
            e.isChecked = false;
        });
        this.setAll(false);
        this.selectedAll = false;
        this.selectedItems = [];
        this.onSelectRow.emit(this.selectedItems);
    }

    showInactiveFun() {
        this.options.pageIndex = 0;
        this.firstCall = false;
        this.tableData = [];
        this.isActive = !this.isActive;
        this.showInactiveEvennt.emit(this.isActive);
        this.refresh();
    }

    showArchivedFun() {
        this.resetOptionsAndData();
        this.isArchived = !this.isArchived;
        this.refresh();
    }

    resetOptionsAndData() {
        this.options.pageIndex = 0;
        this.firstCall = true;
        this.tableData = [];
    }

    updateAllComplete(item?: T) {
        this.allComplete =
            this.tableData.length != 0 &&
            this.tableData.every((t) => t.isChecked);
        if (item) {
            if (item.isChecked) {
                this.selectedItems.push(item);
            } else {
                let index = this.selectedItems.findIndex(function (e) {
                    return e.id == item.id;
                });
                if (index !== -1) this.selectedItems.splice(index, 1);
            }
        }
        this.onSelectRow.emit(this.selectedItems);
        // this.setSelectedItems();
    }

    someComplete(): boolean {
        if (this.tableData.length == 0) {
            return false;
        }
        return (
            this.tableData.filter((t) => t.isChecked).length > 0 &&
            !this.allComplete
        );
    }

    setAll(completed: boolean) {
        // if (completed == false) {
        //     this.selectedItems = [];
        // }
        this.allComplete = completed;
        if (this.tableData.length == 0) {
            return;
        }
        this.tableData.forEach((t) => (t.isChecked = completed));
        this.setSelectedItems();
    }

    setSelectedItems() {
        let selectedItems: T[] = [];
        this.tableData.forEach((e) => {
            let indexItem = this.selectedItems.findIndex(i => i.id == e.id);
            if (indexItem != -1) {
                this.selectedItems.splice(indexItem, 1);
            }
            selectedItems.push(e);
        });
        const mergedArray = [...selectedItems, ...this.selectedItems];
        let uniqueArray: T[] = [];
        mergedArray.forEach((obj) => {
            const exists = uniqueArray.find((item) => item.id === obj.id);
            if (!exists && obj.isChecked) {
                uniqueArray.push(obj);
            }
        });
        this.selectedItems = uniqueArray;
        this.onSelectRow.emit(this.selectedItems);
    }

    checkActionsNumber(item: T) {
        let numberOfAction: number = 0;
        if (this.dataTableConfig?.actionsList) {
            this.dataTableConfig.actionsList.forEach((e) => {
                if (e.hideAction && !e.hideAction(item)) {
                    numberOfAction++;
                }
            });
        }
        return numberOfAction > 0 ? false : true;
    }

    showAsChange() {
        this.isBusy++;
        setTimeout(() => {
            this.isBusy--;
        }, 500);
    }

    async reportListPDF() {
        this.showAs = 'list';
        if (this.selectedItems.length == 0) {
            this.sharedService.alertDangerMessage("No records selected", "Please select records first");
            return;
        }
        this.matDialog.open(DownloadListReportComponent, {
            width: '81.6rem',
            disableClose: true,
            data: {
                title: this.dataTableConfig?.report?.reportListPDF?.label,
                columns: this.dataTableConfig?.column,
                selectedItems: this.selectedItems,
                reportAPI: this.dataTableConfig?.report?.reportListPDF?.reportAPI,
                sortString: this.sortString
            }
        });
    }

    async reportDetailsPDF() {
        this.showAs = 'list';
        if (this.selectedItems.length == 0) {
            this.sharedService.alertDangerMessage("No records selected", "Please select records first");
            return;
        }
        this.matDialog.open(DownloadReportAdvanceComponent, {
            width: '81.6rem',
            disableClose: true,
            data: {
                title: this.dataTableConfig?.report?.reportDetailsPDF?.label,
                reportAPI: this.dataTableConfig?.report?.reportDetailsPDF?.reportAPI,
                downloadReportOptions: this.dataTableConfig?.report?.reportDetailsPDF?.downloadReportOptions,
                selectedItems: this.selectedItems,
            }
        });
    }

    async exportList() {
        this.showAs = 'list';
        if (this.selectedItems.length == 0) {
            this.sharedService.alertDangerMessage("No records selected", "Please select records first");
            return;
        }
        this.matDialog.open(ExportListComponent, {
            width: '81.6rem',
            disableClose: true,
            data: {
                selectedItems: this.selectedItems,
                exportColumnApi: this.dataTableConfig?.exportList?.exportColumnApi,
                exportApi: this.dataTableConfig?.exportList?.exportApi,
                fileName: this.dataTableConfig?.exportList?.fileName
            }
        });
    }

    async importList() {
        if (this.dataTableConfig && this.dataTableConfig.importListFunc) {
            let response = await this.dataTableConfig.importListFunc();
            if (response) {
                if (response.success && response.isRefresh) {
                    this.refresh();
                }
            }
        }
    }

    saveSettings() {
        if (this.dataTableConfig) {
            this.dataTableConfig.showAs = this.showAs;
            this.dataTableConfig.isActive = this.isActive;
            this.companyUserPreferencesService.setTableSettings(this.dataTableConfig.name, this.dataTableConfig);
            this.sharedService.alertSuccessMessage();
        }
    }

    resetSettings() {
        let storageData = this.companyUserPreferencesService.getValue(this.dataTableConfig!.name);
        if (storageData) {
            this.showAs = storageData.showAs;
            if (storageData.column) {
                this.dataTableConfig!.column.forEach(originalColumn => {
                    storageData.column.forEach((storageColumn: any) => {
                        if (storageColumn.key == originalColumn.key) {
                            originalColumn.sortType = '';
                            originalColumn.visible = true;
                        }
                    });
                });
            }
            this.companyUserPreferencesService.removeItem(this.dataTableConfig!.name);
            // this.dataTableConfig.column.forEach(e => {
            //     e.sortType = '';
            // });
            this.showAs = 'list';
            this.options = {
                sort: '',
                pageSize: this.pageSize,
                pageIndex: 0,
                searchString: '',
            };
            this.getData();
        }
    }


    ngOnDestroy(): void {
        this.tableData = [];
    }
}
