import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, forwardRef, input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { AutocompleteDropdown, DropdownList, GetDataOptions, TCDropdown } from '../../model/shared.model';

@Component({
    selector: 'app-tc-dropdown-multi-select',
    templateUrl: './tc-dropdown-multi-select.component.html',
    styleUrl: './tc-dropdown-multi-select.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => TcDropdownMultiSelectComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => TcDropdownMultiSelectComponent),
        },
    ],
})
export class TcDropdownMultiSelectComponent<T> implements OnInit, ControlValueAccessor, Validator {

    constructor(
        private cdr: ChangeDetectorRef
    ) { }

    selectedItemsLength: Number = 0;
    groupedDropdownList: any;

    ///////////////////////////////////////////////////
    @Input() id: string = String(Math.floor(Math.random() * 100000));
    @Input() width: number = 0;
    @Input() isGroupBy: boolean = false;
    @Input() placeholder: string = 'CHOOSE_AN_OPTION';
    @Input() selectedItemslabel: string = 'SELECTED_ITEMS';
    @Input() dropdownList: DropdownList[] = [];
    @Input() allowSelectAll: boolean = false;
    @Input() isSelectAllChecked: boolean = false;
    @Output() dataObject = new EventEmitter<T[]>();
    tcDropdownConfig: TCDropdown<T> | null = null;
    @Input() set tcDropdownOptions(data: TCDropdown<T> | null) {
        if (data) {
            if (this.isGroupBy) {
                this.myControl.setValue([]);
                this.onChangeValue();
            } else {
                this.myControl.setValue(null);
                this.onChangeValue()
            }
            this.tcDropdownConfig = data;
            this.getList();
        }
    }
    @Input() set readonly(data: boolean) {
        this.isReadonly = data;
        if (data) {
            this.myControl.disable();
        } else {
            this.myControl.enable();
        }
    }
    isReadonly: boolean = false;
    optionsApi: GetDataOptions = {
        sort: '',
        pageSize: 300,
        pageIndex: 0,
        searchString: '',
    };

    isBusy: boolean = false;
    myControl = new FormControl();
    currentValue: number | Number[] | null = null;

    ngOnInit() {

    }

    ngAfterContentChecked(): void {
        let elm = document.getElementById(this.id);
        if (elm) {
            this.width = elm.offsetWidth
        }
    }

    init() {
        this.lodaingMessage();
        let elm = document.getElementsByClassName("p-multiselect-panel");
        if (elm && this.allowSelectAll) {
            elm[0].classList.add("tc-multi-select-allow-select-all")
        }
    }

    lodaingMessage() {
        let elm = document.getElementsByClassName("p-multiselect-empty-message");
        if (elm[0] && this.isBusy) {
            elm[0].innerHTML = "Loading ...";
        } else if (elm[0]) {
            elm[0].innerHTML = "No Result Found";
        }
        this.cdr.detectChanges();
    }



    onTouched = () => { };
    onChange = (date: any) => { };
    touched = false;
    disabled = false;

    validate(c: AbstractControl): ValidationErrors | null {
        return null;
    }

    writeValue(val: number | Number[] | null): void {
        this.currentValue = val;
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    async getList() {
        try {
            this.isBusy = true;
            if (this.tcDropdownConfig) {
                let response = await this.tcDropdownConfig!.getData(this.optionsApi)
                if (response) {
                    if (response.autoCompList) {
                        if (response.autoCompList) {
                            if (this.isGroupBy) {
                                this.dropdownList = response.autoCompList.reduce((acc: any, item: DropdownList) => {
                                    const group = acc.find((e: any) => e.label === item.groupBy);
                                    if (group) {
                                        group.items.push({ label: item.text, value: item.value });
                                    } else {
                                        acc.push({ label: item.groupBy, items: [{ label: item.text, value: item.value }] });
                                    }
                                    return acc;
                                }, []);
                            } else {
                                if (this.isSelectAllChecked) {
                                    // let ids: Number[] = [];
                                    // response.autoCompList.forEach(e => {
                                    //     if (e.value)
                                    //         ids.push(Number(e.value));
                                    // })
                                    // this.currentValue = ids;
                                    this.myControl.setValue(response.autoCompList);
                                }
                                this.dropdownList = response.autoCompList;
                            }

                        }
                    }
                }
            }
        }
        catch (error) {

        } finally {
            // setTimeout(() => {
            this.isBusy = false;
            // }, 3000);
        }
    }

    onChangeValue() {
        if (this.myControl.value) {
            let ids: Number[] = [];
            this.myControl.value.forEach((e: any) => {
                if (e.value)
                    ids.push(Number(e.value));
            })
            this.onChange(ids);
            this.dataObject.next(this.myControl.value);
            this.selectedItemsLength = this.myControl.value.length;
        }
    }


}
