import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({

  selector: 'save-changes-dialog',
  templateUrl: './save-changes.component.html',
  styleUrls: ['./save-changes.component.scss']
})
export class SaveChangesComponent implements OnInit {

  constructor(public dialog: MatDialogRef<SaveChangesComponent>) { }

  ngOnInit(): void {
  }

  save() {
    this.dialog.close("save");
  }

  discard() {
    this.dialog.close("discard");
  }

  cancel() {
    this.dialog.close("cancel");
  }

}
