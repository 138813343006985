import { Component, Input, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrl: './password.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => PasswordComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => PasswordComponent),
        },
    ],
})
export class PasswordComponent implements ControlValueAccessor, Validator {

    onTouched = () => { };
    onChange = (val: string = '') => { };
    writeValue(val: string = ''): void {
        this.passwordInput = val;
    }
    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return null;
    }

    constructor(

    ) { }

    @Input() id: string = "";
    @Input() placeholder: string = "PASSWORD";
    @Input() disapleAutoComplete: boolean = false;

    passwordInput: string = "";
    togglePasswordInputType: boolean = false;

    eyeClick(){
        this.togglePasswordInputType = !this.togglePasswordInputType;
        document.getElementById(this.id)?.focus();
    }

    updateValue() {
        this.onChange(this.passwordInput);
    }

}
