import { Component, Input, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { TableOptions } from '../../model/app-table.model';
import { ActionResponse, ApiFilterOptions, GetDataOptions } from '../../model/shared.model';
import { ThrottleCallService } from '../../services/throttle-call.service';
import { PagingData } from 'src/app/core/models/api-response.model';

@Component({
    selector: 'tc-table',
    templateUrl: './tc-table.component.html',
    styleUrl: './tc-table.component.scss'
})
export class TcTableComponent<T> {

    constructor() { }

    @Input() set tableOptions(data: TableOptions<T>) {
        this.dataTableConfig = data;
        this.cols = data.column;
        this.options.pageSize = 100;
        // this.options = {
        //     sort: this.sortString,
        //     pageSize: this.pageSize,
        //     pageIndex: this.pageIndex,
        //     searchString: this.searchInput || '',
        // };
        // this.getData();
    }
    cols: any;
    options: GetDataOptions = {
        sort: '',
        pageSize: 0,
        pageIndex: 0,
        searchString: '',
    };
    pageIndex: number = 0;
    pages: number = 1;
    totalCount: number = 0;
    pageSize: number = 10;
    sortString: string = '';
    searchInput: string = '';
    firstCall: boolean = true;
    tableData: T[] = [];
    dataTableConfig: TableOptions<T> | undefined;
    isBusy: number = 0;
    isActive: boolean = true;
    isArchived: boolean = false;
    hasNextPage: boolean = true;
    loading: boolean = false;
    ngOnInit() {
    }

    // event: LazyLoadEvent
    async loadCarsLazy(event: LazyLoadEvent | any) {
        if (this.firstCall) {
            this.firstCall = false;
            if (this.hasNextPage) {
                await this.getData(0, false, true);
                this.firstCall = true;
                this.options.pageIndex = this.options.pageIndex + 1;
            } else {
                this.firstCall = true;
            }
        }
        event.forceUpdate();
    }

    private tc = new ThrottleCallService<ActionResponse<T>>();
    resetOptionsAndData() {
        this.options.pageIndex = 0;
        this.firstCall = true;
        this.tableData = [];
    }
    private isPaginated = (object: PagingData<T> | T[]): object is PagingData<T> => {
        return 'pageData' in object;
    };
    async getData(delay = 0, fromSearch: boolean = false, fromScroll: boolean = false) {
        try {
            // this.loading = true;
            if (this.dataTableConfig) {
                if (!fromScroll)
                    this.isBusy++;
                let filter: ApiFilterOptions = {
                    isActive: this.isActive,
                    isArchived: this.isArchived
                }
                let response = await this.tc.call(
                    () =>
                        this.dataTableConfig!.getData(
                            this.options,
                            filter
                        ),
                    delay
                );
                //console.log('completed fetch from database')
                if (fromSearch) {
                    this.resetOptionsAndData();
                }
                if (response && response.data) {
                    if (this.isPaginated(response.data)) {
                        if (this.options.pageIndex == 0) {
                            this.tableData = [];
                        }
                        // this.pages = response.data.totalPages;
                        this.hasNextPage = response.data.hasNextPage;
                        this.tableData.push(...response.data.pageData);
                        // this.tableData.forEach((e, i) => {
                        //     let temp = this.selectedItems.find(
                        //         (element) => element.id === e.id
                        //     );
                        //     if (temp) e.isChecked = true;
                        //     return e;
                        // });
                        this.pageIndex = response.data.pageIndex;
                        this.totalCount = response.data.totalCount;
                        // this.updatePagination();
                        // this.updateAllComplete();
                        // if (this.selectedItems.length == this.tableData.length) {
                        //     this.selectedAll = true;
                        // } else {
                        //     this.selectedAll = false;
                        // }
                    } else {
                        this.pages = 0;
                        this.tableData = response.data;
                        this.pageIndex = 0;
                        this.totalCount = 0;
                        // this.updatePagination();
                    }
                }
            }
        } catch (e) {
            if (typeof e === 'string' && e == 'aborted') console.log('aborted');
        } finally {
            if (!fromScroll)
                this.isBusy--;
            this.loading = false;
        }
    }
}
