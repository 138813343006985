<div *ngIf="open" class="viewer-container">
    <div class="viewer-content">
        <div class="row viewer-header">
            <div class="col-6 back">
                <span class="page-back" (click)="close.emit()">
                    <i class="icon-arrow-narrow-left"></i>
                </span>
            </div>
            <div class="col-6 header-buttons">
                <button class="secondary-button-large" (click)="downloadImage()">
                    <span>{{ "DOWNLOAD" | translate }}</span>
                </button>
                <button class="secondary-danger-button-large" (click)="deleteImage()">
                    <span>{{ "DELETE" | translate }}</span>
                </button>
            </div>
        </div>
        <div class="row viewer-body">
            <div class="col-2 d-flex justify-content-center">
                <div class="arrow-controls" [class.disabled]="imageIndex == 0">
                    <img src="/assets/images/prev-circle.svg" (click)="prevArrow()">
                </div>
            </div>
            <div class="col-8">
                <ng-container [ngSwitch]="activeMediaType">
                    <ng-container *ngSwitchCase="'video'">
                        <video class="main-img" autoplay controls *ngIf="activeImageUrl | async as src;">
                            <source [src]="src" type="video/mp4">
                            <source [src]="src" type="video/ogg">
                            Your browser does not support the video tag.
                        </video>
                    </ng-container>
                    <ng-container *ngSwitchCase="'audio'">
                        <audio autoplay controls *ngIf="activeImageUrl | async as src;">
                            <source [src]="src" type="audio/ogg">
                            <source [src]="src" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <img class="main-img" *ngIf="activeImageUrl | async as src;" [src]="src" loading="lazy" />
                    </ng-container>
                </ng-container>
            </div>
            <div class="col-2 d-flex justify-content-center">
                <div class="arrow-controls" [class.disabled]="imageIndex == imagesList.length - 1">
                    <img src="/assets/images/next-circle.svg" (click)="nextArrow()">
                </div>
            </div>
        </div>
    </div>
</div>