import { Injectable } from '@angular/core';

const ServerFormat = /^\d{1,4}-(\d{3})(\d{3})(\d{4})$/g;
const LocalFormat = /^\(([2-9]\d{2})\) (\d{3})-(\d{4})$/g;
@Injectable({
    providedIn: 'root',
})
export class PhoneNumberService {
    constructor() {}

    get localFormat() {
        return LocalFormat;
    }

    isServerFormat(phoneNumber: string) {
        ServerFormat.lastIndex = 0;
        return ServerFormat.test(phoneNumber);
    }

    isLocalFormat(phoneNumber: string) {
        LocalFormat.lastIndex = 0;
        return LocalFormat.test(phoneNumber);
    }

    phoneNumberFromServer(phoneNumber: string) {
        if (this.isServerFormat(phoneNumber) === false) {
            // throw 'invalid number ' + phoneNumber;
        }
        phoneNumber = phoneNumber? phoneNumber.replace(ServerFormat, '($1) $2-$3') : '';
        return phoneNumber;
    }

    phoneNumberFromLocalToServer(phoneNumber: string) {
        if (this.isLocalFormat(phoneNumber) === false) {
            // throw 'invalid number ' + phoneNumber;
        }
        phoneNumber = phoneNumber? phoneNumber.replace(LocalFormat, '1-$1$2$3') : '';
        return phoneNumber;
    }
}
