<form [formGroup]="docListForm">
    <div class="row align-items-end" *ngFor="let doc of docsList">
        <div [class]="filesCol">
            <app-file-upload
                [id]="'docNumber-' + doc.localId"
                [formControlName]="'docNumber-' + doc.localId"
                (valueChange)="onDocChange($event, doc.localId!)"
                [label]="getFileLabel(doc)"
                [labelAfterUpload]="getFileLabel(doc)"
                [disabledReupload]="disabledReupload"
                [disabledDownload]="disabledDownload"
                [disabledDelete]="disabledDelete"
                [hideReupload]="hideReupload"
                [hideDownload]="hideDownload"
                [hideDelete]="hideDelete"
                [isRequired]="isRequired"
                [readonly]="readonly || disabled"
                [fileType]="fileType"
                [subtitle]="subtitle"
                [viewMode]="viewMode"
            >
            </app-file-upload>
        </div>
        <ng-container *ngIf="allowTextField && !viewMode">
            <ng-container *ngIf="autocompleteConfig; else notAutocomplete">
                <div class="col-md-5">
                    <div class="form-group">
                        <label for="autocomplete-{{ doc.localId }}">{{
                            autocompleteConfig.label | translate
                        }}</label>
                        <span class="required" *ngIf="autocompleteConfig.required">*</span>
                        <app-autocomplete-dropdown
                            [optionsList]="autocompleteConfig.optionsList"
                            [formControlName]="'autocomplete-' + doc.localId"
                            (ngModelChange)="
                                onAutocompleteInput($event, doc.localId!)
                            "
                            [id]="'autocomplete-' + doc.localId"
                            [viewMode]="viewMode"
                            [placeholder]="
                                (autocompleteConfig.placeholder
                                    ? autocompleteConfig.placeholder
                                    : autocompleteConfig.label
                                ) | translate
                            "
                        >
                        </app-autocomplete-dropdown>
                        <div
                            class="validation-msg"
                            *ngIf="
                                docListForm.controls[
                                    'autocomplete-' + doc.localId
                                ]?.errors?.required && submitted
                            "
                        >
                            {{ autocompleteConfig.label | translate }}
                            {{ "IS_REQUIRED" | translate }}
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #notAutocomplete>
                <ng-container *ngIf="fieldName">
                    <div class="col-md-5" [class.view-mode]="viewMode">
                        <div class="form-group">
                            <label for="">{{ fieldName | translate }}</label>
                            <input
                                type="text"
                                (input)="onFieldInput($event, doc.localId!)"
                                [placeholder]="fieldName | translate"
                                [formControlName]="'textField-' + doc.localId"
                                id="field-id"
                                class="form-control"
                                [readOnly]="viewMode ? true : false"
                            />
                        </div>
                    </div>
                </ng-container>
            </ng-template>
            <div class="col-md-1 d-flex align-items-end" *ngIf="allowDeleteBtn">
                <img
                    class="delete-img"
                    (click)="onDelete($event, doc.localId!)"
                    src="/assets/images/delete-icon-red.svg"
                    alt=""
                />
            </div>
        </ng-container>
    </div>
</form>
<div class="row">
    <div [class]="buttonCol">
        <div class="form-group">
            <input
                type="file"
                #file
                class="d-none"
                (change)="onFileUpload($event)"
            />
            <ng-container *ngIf="!viewMode">
                <button
                    class="secondary-button-large add-doc-btn"
                    (click)="file.click()"
                    [disabled]="disabled"
                    [ngStyle]="{ width: buttonWidth }"
                >
                    <span>
                        {{ buttonLable | translate }}
                    </span>
                </button>
            </ng-container>
        </div>
    </div>
</div>
