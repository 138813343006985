export class Driverview {
    id: number = 0;
    companyUserCode?: string = 'emp-101';
    userId?: number;
    firstName: string = '';
    lastName: string = '';
    email: string = '';
    isDriver: boolean = false;
    phoneNumber: string = '';
    phoneNo: string = '';
    dateOfBirth: Date | null | string = '';
    medicalCardExpiryDate: string | Date | null = '';
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    ssn?: string;
    cdlNumber?: number;
    stateCode?: string;
    zipCode?: string;
    middleName?: string;
    avatarDocumentId: number | null = null;
    healthDocumentId: number | null = null;
    isOrientationCompleted: boolean = false;
    orientationDate: Date | string | null = null;
    orientationDocumentId: number | null = null;
    fullName: string = '';
    employeeStatus: string = '';
    dutyStatus: string = '';
    reasonOfInactive: string = '';
    reasonOfOos: string = '';
    currentDate: Date | null = null;
    invitationDate: Date | null = null;
    isEmploymentVerifiedByUser: boolean = false;
    issuingState?: string;
    issueDate?: Date | string;
    expirationDate?: Date | string;
    experience?: number;
    terminationLetterDocumentId: null | number = null;
    hiringLetterDocumentId: null | number = null;
    fromDate: string | null = null;
    toDate: string | null = null;
    isCompleteInformation: boolean = false;
    cdlExpirationDate: Date | null = null;
    emergencyContact?: EmergencyContact;
    isActive?: boolean;
    isOutOfService?: boolean;
    isEmployeeAnnualReviewDateExceed?: boolean;
    isEmployeeAnnualReviewDisqualified?: boolean
    constructor(init?: Partial<Driverview>) {
        Object.assign(this, init);
    }
}

interface EmergencyContact {
    firstName: string
    lastName: string
    relationship: string
    phoneNumber: string
}
export class EmployeeAux {
    companyUserId?: number;
    cdlNumber: number | string = '';
    companyUserCode: string = '';
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    zipCode?: string | number;
    title?: string;
    faxNumber?: string;
    id?: number;
}

export interface UserAux {
    id: number
    name: string
    email: string
}

// export interface DriverViewDAT{
//     id?: number;
//     userId?: number;
//     firstName: string;
//     lastName: string;
//     email: string;
//     isDriver: boolean;
//     phoneNo: string;
//     dateOfBirth: Date | string;
//     medicalCardExpiryDate: string;
//     addressLine1?: string;
//     addressLine2?: string;
//     city?: string;
//     ssn?: string;
//     cdlNumber?: number;
//     stateCode?: string;
//     zipCode?: string;
//     middleName?: string;
//     avatarDocumentId: number;
//     healthDocumentId: number;
// }

export interface EmployeeNotInsured {
    id: number;
    companyUserId: number;
    companyUserCode: string;
}

export interface EmployeeShouldUnInsure {
    id: number;
    insuranceId: number;
    companyUserId: number;
    companyUserCode: string;
}

export interface NotInsured {
    id: number;
    isInsured: boolean;
}

export interface DriverStatistics {
    incompleteInformationCount: number
    expiredCdlCount: number
    emailNotVerifiedCount: number
}

export interface DriverDocument{
    id: number
    companyUserId?: number
    documentId: number
    documentType: string
    description: string
    issuer: string
    issueDate: Date | string
    expiryDate: Date | string
}

export interface DriverAnnualReview {
    id: number
    reviewDate: string
    employeeAnnualReviewStatus: string
}

export enum ReasonOfInactiveEnum {
    NA = "NA",
    LaidOff = "LaidOff",
    Resigned = "Resigned",
    Terminated = "Terminated",
    Deceased = "Deceased"
}
