import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    constructor() {}

    resizeImage(file: File, fileName: string): Promise<File> | null {
        if (file.type.match(/image.*/)) {
            const maxSize = 2000;
            const reader = new FileReader();
            const image = new Image();
            const canvas = document.createElement('canvas');

            const resize = (): File => {
                let width = image.width;
                let height = image.height;

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);
                let dataUrl = canvas.toDataURL('image/jpeg');
                let imgToUpload = this.canvasUrlDataToFile(dataUrl, fileName);
                return imgToUpload!;

                // return dataURItoBlob(dataUrl);
            };

            return new Promise((resolve, reject) => {
                if (!file.type.match(/image.*/)) {
                    reject(new Error('Not an image'));
                    return;
                }

                reader.onload = (readerEvent: any) => {
                    image.onload = () => resolve(resize());
                    image.src = readerEvent.target.result;
                    return;
                };
                reader.readAsDataURL(file);
            });
        } else return null;
    }

    canvasUrlDataToFile(imgSrc: string, fileName: string): File | null {
        // .replace('image/png', 'image/octet-stream');
        let blob;
        if (imgSrc) {
            blob = this.dataURLtoBlob(imgSrc);
            let fd = new FormData();
            fd.append('file', blob);
            let signatureFile = new File([blob], fileName, {
                type: 'image/png',
            });

            return signatureFile ?? null;
        }
        return null;
    }

    imageToUrl(file: File): Promise<string> | string {
        if (file.type.match(/image.*/)) {
            const maxSize = 2000;
            const reader = new FileReader();
            const image = new Image();
            const canvas = document.createElement('canvas');

            const resize = (): string => {
                let width = image.width;
                let height = image.height;

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height;
                        height = maxSize;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);
                let dataUrl = canvas.toDataURL('image/jpeg');
                return dataUrl!;

                // return dataURItoBlob(dataUrl);
            };

            return new Promise((resolve, reject) => {
                if (!file.type.match(/image.*/)) {
                    reject(new Error('Not an image'));
                    return;
                }

                reader.onload = (readerEvent: any) => {
                    image.onload = () => resolve(resize());
                    image.src = readerEvent.target.result;
                    return;
                };
                reader.readAsDataURL(file);
            });
        } else return '';
    }

    private dataURLtoBlob(dataurl: string) {
        let arr = dataurl.split(',');
        let mimeArr = arr[0].match(/:(.*?);/);
        let mime: any;
        if (mimeArr) mime = mimeArr[1] ?? '';
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
}
