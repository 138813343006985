import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ISubscription } from 'rxjs-compat/Subscription';

@Directive({
    selector: '[appSsnMask]',
})
export class SsnMaskDirective implements OnChanges {
    private _ssnControl: AbstractControl | null = null;
    private _preValue: string = '';
    id: string = '';
    trueValue: string = '';
    displayValue: string = '';
    isFocus: boolean = false;
    @Input()
    set ssnControl(control: AbstractControl) {
        this._ssnControl = control;
        this.validate(this._preValue);
    }
    @Input()
    set preValue(value: string) {
        this._preValue = value;
        this.id = '#' + this.el.nativeElement.id;
    }
    @Input() submitted: boolean | null = null;

    private sub: ISubscription | null = null;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    @HostListener('focus') onFocus() {
        this.isFocus = true;
        this._ssnControl?.setValue(this.trueValue, {
            emitEvent: false,
        });
    }

    @HostListener('blur') onBlur() {
        this.isFocus = false;
        this._ssnControl?.setValue(this.displayValue, {
            emitEvent: false,
        });
    }

    ngOnInit() {
        this.phoneValidate();
        this.id = '#' + this.el.nativeElement.id;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.submitted) {
            // this._ssnControl?.setValue(this.trueValue, { emitEvent: false });
        } else {
            // this._ssnControl?.setValue(this.displayValue, { emitEvent: false });
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    phoneValidate() {
        let data: string = this._preValue;
        if (this._ssnControl)
            this.sub = this._ssnControl.valueChanges.subscribe((data) => {
                /**we remove from input but: 
           @preInputValue still keep the previous value because of not setting.
        */
                if (data == null || data == '') {
                    return;
                }
                this.validate(data);
            });
    }

    validate(data: string) {
        let preInputValue: string = String(this._preValue);
        var lastChar: string = preInputValue.substring(
            preInputValue.length - 1
        );
        // remove all mask characters (keep only numeric)
        data = (data ?? '');
        var newVal = data.replace(/\D/g, '');

        const element = this.renderer.selectRootElement(this.id);
        // if (element.selectionStart === undefined)
        //     return;

        let start = element.selectionStart ?? 0;
        let end = element.selectionEnd ?? 0;

        //let start=this.phoneRef.nativeElement.selectionStart;
        //let end = this.phoneRef.nativeElement.selectionEnd;
        //when removed value from input
        if (data.length < preInputValue.length) {
            // this.message = 'Removing...'; //Just console
            /**while removing if we encounter ) character,
                then remove the last digit too.*/
            if (preInputValue.length < start) {
                if (lastChar == ')') {
                    newVal = newVal.substring(0, newVal.length - 1);
                }
            }
            //if no number then flush
            if (newVal.length == 0) {
                newVal = '';
            } else if (newVal.length <= 3) {
                /**when removing, we change pattern match.
                   "otherwise deleting of non-numeric characters is not recognized"*/
                newVal = newVal.replace(/^(\d{0,3})/, '$1');
            } else if (newVal.length <= 5) {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,2})/, '$1-$2');
            } else {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(.*)/, '$1-$2-$3');
            }
            if (this._ssnControl) {
                this.trueValue = newVal;
                this.displayValue = this.getDisplayValue(newVal);

                this._ssnControl.setValue(
                    this.isFocus ? this.trueValue : this.displayValue,
                    {
                        emitEvent: false,
                    }
                );
            }
            //keep cursor the normal position after setting the input above.
            element.setSelectionRange(start, end);
            //when typed value in input
        } else {
            // this.message = 'Typing...'; //Just console
            var removedD = data.charAt(start);
            // don't show braces for empty value
            if (newVal.length == 0) {
                newVal = '';
            }
            // don't show braces for empty groups at the end
            else if (newVal.length <= 3) {
                newVal = newVal.replace(/^(\d{0,3})/, '$1');
            } else if (newVal.length <= 5) {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,2})/, '$1-$2');
            } else {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,2})(.*)/, '$1-$2-$3');
            }
            //check typing whether in middle or not
            //in the following case, you are typing in the middle
            if (preInputValue.length >= start) {
                //change cursor according to special chars.
                if (removedD == '(') {
                    start = start + 1;
                    end = end + 1;
                }
                if (removedD == ')') {
                    start = start + 2; // +2 so there is also space char after ')'.
                    end = end + 2;
                }
                if (removedD == '-') {
                    start = start + 1;
                    end = end + 1;
                }
                if (removedD == ' ') {
                    start = start + 1;
                    end = end + 1;
                }
                if (this._ssnControl) {
                    this.trueValue = newVal;
                    this.displayValue = this.getDisplayValue(newVal);
                    this._ssnControl.setValue(
                        this.isFocus ? this.trueValue : this.displayValue,
                        {
                            emitEvent: false,
                        }
                    );
                }
                this.getDisplayValue(newVal);
                if (element.setSelectionRange)
                    element.setSelectionRange(start, end);
            } else {
                if (this._ssnControl) {
                    this.trueValue = newVal;
                    this.displayValue = this.getDisplayValue(newVal);
                    this._ssnControl.setValue(
                        this.isFocus ? this.trueValue : this.displayValue,
                        {
                            emitEvent: false,
                        }
                    );
                }
                element.setSelectionRange(start + 2, end + 2); // +2 because of wanting standard typing

                // For some reason this causes trouble more than it solves !!!!!
                //   when I stop calling selectionRange , it will stop crashing but the 
                //   masked field will be stuck with stars or with numbers !
                // 
                // if (element && element.setSelectionRange) {
                //     element.setSelectionRange(start + 2, end + 2); // +2 because of wanting standard typing
                // } else {
                //     console.log(element);
                //     console.warn('setSelectionRange is not available on the selected element');
                // }
            }
        }
    }

    private getDisplayValue(trueValue: string): string {
        let dsiplayValue = '';
        // if (trueValue.length <= 4) {
        //     dsiplayValue = trueValue;
        // } else
        // {
        // dsiplayValue = trueValue.substring(0, 4);
        for (let i = 0; i < Math.min(trueValue.length, 6); i++) {
            dsiplayValue += trueValue[i].replace(/[^-]/g, '*');
        }
        if (trueValue.length > 7) {
            dsiplayValue += trueValue.substring(6);
        }
        // }
        return dsiplayValue;
    }
}
