import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmResult {
  userAction: string,
  error: string,
  returnValue: any
}

export interface ConfirmActionFuncResult {
  canClose: boolean,
  error: string | null,
  returnValue: any
}

export interface ConfirmInput {
  actionTitle: string,
  customTitle?: string,
  customBody?: string, 
  actionFunc: () => (Promise<ConfirmActionFuncResult> | ConfirmActionFuncResult)
  isFuncNotAsync?: boolean
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  constructor(public dialog: MatDialogRef<ConfirmComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmInput) { }

  errorMsg: string = "";

  ngOnInit(): void { 
    this.dialog.updateSize('57.6rem');
  }

  async confirm() {
    var result: ConfirmActionFuncResult;
    if (this.data.isFuncNotAsync)
      result = <ConfirmActionFuncResult>this.data.actionFunc();
    else
      result = await this.data.actionFunc();

    if (result.canClose)
      this.dialog.close({ userAction: 'confirm', returnValue: result.returnValue });
    else
      this.errorMsg = result.error ?? "";
  }

  cancel() {
    this.dialog.close({ userAction: 'cancel' });
  }
}
