<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">
            {{ "QUICK_DRIVER_ENTRY" | translate }}
        </h5>
    </div>
    <div class="close-icon" (click)="cancel()">
        <img (click)="cancel()" src="/assets/images/x-close-black.svg" />
    </div>
</div>

<div class="dialog-body">
    <form [formGroup]="quickAddDriverForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label [ngClass]="
                            submitted && f.employeeCode.errors
                                ? 'required-text'
                                : ''
                        " for="employeeCode">{{ "EMPLOYEE_ID" | translate }}</label>
                    <span class="required">*</span>
                    <input type="text" class="form-control" id="driver-personal-employee-id"
                        formControlName="employeeCode" placeholder="{{ 'EMPLOYEE_ID' | translate }}" required [ngClass]="[
                            submitted && f.employeeCode.errors
                                ? 'error is-invalid'
                                : '',
                            submitted && !f.employeeCode.errors ? 'success' : ''
                        ]" />
                    <div *ngIf="submitted && f.employeeCode.errors" class="validation-msg">
                        <div class="validation-error" *ngIf="f.employeeCode.errors.required">
                            {{ "EMPLOYEE_ID" | translate }}
                            {{ "IS_REQUIRED" | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="first-name" [ngClass]="
                            submitted && f.firstName.errors
                                ? 'required-text'
                                : ''
                        ">{{ "FIRST_NAME" | translate }}</label>
                    <span class="required">*</span>
                    <input [placeholder]="'FIRST_NAME' | translate" type="text" formControlName="firstName"
                        id="first-name" class="form-control" />
                    <div *ngIf="f.firstName.errors?.required && submitted" class="validation-msg">
                        {{ "FIRST_NAME" | translate }}
                        {{ "IS_REQUIRED" | translate }}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="last-name" [ngClass]="
                            submitted && f.lastName.errors
                                ? 'required-text'
                                : ''
                        ">{{ "LAST_NAME" | translate }}</label>
                    <input [placeholder]="'LAST_NAME' | translate" type="text" formControlName="lastName" id="last-name"
                        class="form-control" />
                    <div *ngIf="f.lastName.errors?.required && submitted" class="validation-msg">
                        {{ "LAST_NAME" | translate }}
                        {{ "IS_REQUIRED" | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="">{{ "EMAIL" | translate }}</label>
                    <input type="email" formControlName="email" id="email" [placeholder]="'EMAIL' | translate"
                        class="form-control" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="date-of-birth">{{
                        "DATE_OF_BIRTH" | translate
                        }}</label>
                    <app-date-picker [id]="'date-of-birth'" formControlName="dateOfBirth" [maxDate]="todayDate"
                        [viewMode]="!allowEdit" [fromModal]="true"> </app-date-picker>
                </div>
            </div>
        </div>
        <hr />

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="cld-number">{{
                        "CDL_NUMBER" | translate
                        }}</label>
                    <input [placeholder]="'CDL_NUMBER' | translate" type="text" formControlName="cdlNumber"
                        id="cdl-number" class="form-control" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="first-name">{{
                        "YEARS_OF_EXPERIENCE" | translate
                        }}</label>
                    <input [placeholder]="'YEARS_OF_EXPERIENCE' | translate" type="number" formControlName="yearsOfExp"
                        id="first-name" class="form-control" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="issuing-state">{{
                        "ISSUING_STATE" | translate
                        }}</label>
                    <app-autocomplete-address formControlName="issuingState"
                        id="issuing-state" placeholder="{{ 'ISSUING_STATE' | translate }}"
                        [types]="'administrative_area_level_1'">
                    </app-autocomplete-address>
                    <div class="validation-msg" *ngIf="f.issuingState?.errors?.invalidUSState && submitted">
                        {{ "INVALID_STATE" | translate }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="issue-date">{{
                        "ISSUE_DATE" | translate
                        }}</label>
                    <app-date-picker [id]="'issue-date'" formControlName="issueDate" [maxDate]="todayDate"
                        [viewMode]="!allowEdit" [fromModal]="true"> </app-date-picker>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="expiration-date">{{
                        "EXPIRATION_DATE" | translate
                        }}</label>
                    <app-date-picker [id]="'expiration-date'" formControlName="expirationDate" [viewMode]="!allowEdit"
                        [fromModal]="true"> </app-date-picker>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="dialog-footer">
    <div class="row">
        <div class="invalid-form-warning" *ngFor="let message of errorMessages">
            <div class="invalid-icon"></div>
            <div class="warning-message">
                {{ message | translate }}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="invalid-form-warning" *ngIf="quickAddDriverForm.invalid && submitted">
                <div class="invalid-icon"></div>
                <div class="warning-message">
                    {{ "GLOBAL_FORM_ERROR" | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="app-actions-button">
        <ng-container *ngIf="allowEdit; else viewMode">
            <button class="app-close-modal-button" (click)="cancel()">
                {{ "CANCEL" | translate }}
            </button>
            <button class="app-add-changes-modal-button" (click)="saveChanges()">
                <span>
                    {{ "ADD_DRIVER" | translate }}
                </span>
            </button>
        </ng-container>
        <ng-template #viewMode>
            <button class="app-close-modal-button" (click)="cancel()">
                {{ "CLOSE" | translate }}
            </button>
        </ng-template>
    </div>
</div>