<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">
            {{ "QUICK_TRAILER_ENTRY" | translate }}
        </h5>
    </div>
    <div class="close-icon" (click)="cancel()">
        <img (click)="cancel()" src="/assets/images/x-close-black.svg" />
    </div>
</div>

<div class="dialog-body">
    <form [formGroup]="quickAddTrailerForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label
                        for="trailer-id"
                        [ngClass]="
                            submitted && f.trailerId.errors
                                ? 'required-text'
                                : ''
                        "
                        >{{ "TRAILER_ID" | translate }}</label
                    >
                    <span class="required">*</span>
                    <input
                        [placeholder]="'TRAILER_ID' | translate"
                        type="text"
                        formControlName="trailerId"
                        id="trailer-id"
                        class="form-control"
                    />
                    <div
                        *ngIf="f.trailerId.errors?.required && submitted"
                        class="validation-msg"
                    >
                        {{ "TRAILER_ID" | translate }}
                        {{ "IS_REQUIRED" | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="make">{{ "MAKE" | translate }}</label>
                    <input
                        type="text"
                        formControlName="make"
                        [placeholder]="'MAKE' | translate"
                        id="make"
                        class="form-control"
                    />
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="model">{{ "MODEL" | translate }}</label>
                    <input
                        type="text"
                        [placeholder]="'MODEL' | translate"
                        formControlName="model"
                        id="model"
                        class="form-control"
                    />
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="year">{{ "YEAR" | translate }}</label>
                    <input
                        type="number"
                        formControlName="year"
                        id="year"
                        [placeholder]="'YEAR' | translate"
                        class="form-control"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="vin">{{ "VIN" | translate }}</label>
                    <input
                        [placeholder]="'VIN' | translate"
                        type="text"
                        formControlName="vin"
                        id="vin"
                        class="form-control"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="value">{{ "VALUE" | translate }}</label>
                    <app-input-unit
                    formControlName="value"
                    [id]="'value'"
                    [inputUnitType]="InputUnitTypeEnum.num"
                    placeholder="{{ 'VALUE' | translate }}"
                ></app-input-unit>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="dialog-footer">
    <div class="row">
        <div class="invalid-form-warning" *ngFor="let message of errorMessages">
            <div class="invalid-icon"></div>
            <div class="warning-message">
                {{ message | translate }}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div
                class="invalid-form-warning"
                *ngIf="quickAddTrailerForm.invalid && submitted"
            >
                <div class="invalid-icon"></div>
                <div class="warning-message">
                    {{ "GLOBAL_FORM_ERROR" | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="app-actions-button">
        <ng-container *ngIf="allowEdit; else viewMode">
            <button class="app-close-modal-button" (click)="cancel()">
                {{ "CANCEL" | translate }}
            </button>
            <button
                class="app-add-changes-modal-button"
                (click)="saveChanges()"
            >
                <span>
                    {{ "ADD_TRAILER" | translate }}
                </span>
            </button>
        </ng-container>
        <ng-template #viewMode>
            <button class="app-close-modal-button" (click)="cancel()">
                {{ "CLOSE" | translate }}
            </button>
        </ng-template>
    </div>
</div>
