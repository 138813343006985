import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[DurationPicker]'
})
export class MyDurationPickerDirective implements OnInit, OnDestroy {
    private navigationKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', ':'];
    @Input()
    picker: HTMLInputElement;
    valueSubscription?: Subscription;
    isPreviewKeyABackspace: boolean = false;
    isPreviewKeyADelete: boolean = false;
    oldValue: string = '';
    @Output() valueChange = new EventEmitter();
    constructor(public el: ElementRef, public ngControl: NgControl) {

        this.picker = el.nativeElement;
        this.picker.value = '00:00';
        // this.picker.style.textAlign = 'right';
    }

    ngOnInit(): void {
        this.valueSubscription = this.ngControl.control?.valueChanges.subscribe(
            (value) => {
                this.onInputChange(value, false, false);
            }
        );
    }

    ngOnDestroy(): void {
        this.valueSubscription?.unsubscribe();
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: any) {
        if (this.navigationKeys.indexOf(e.key) > -1) {
            // let it happen, don't do anything
            return;
        }
        if (e.key != 'Tab' && e.key != 'Home' && e.key != 'End' && (e.key === ' ' || isNaN(Number(e.key)))) {
            // e.preventDefault();
        }
        const sectioned = e.target.value.split(':')
        if (e.key == 'ArrowUp') {
            let val = Number(sectioned[0]);
            val++;
            sectioned[0] = String(val);
            if (sectioned[0] < 10) {
                if (sectioned[0] != "00" && (sectioned[0] == 0 || sectioned[0][0] != 0))
                    sectioned[0] = "0" + sectioned[0]
            }
        }
        if (e.key == 'ArrowDown') {
            let val = Number(sectioned[0]);
            val--;
            if (val >= 0)
                sectioned[0] = String(val);
            if (sectioned[0] < 10) {
                if (sectioned[0] != "00" && (sectioned[0] == 0 || sectioned[0][0] != 0))
                    sectioned[0] = "0" + sectioned[0]
            }
        }
        e.target.value = sectioned.join(':');
        this.onInputChange(e.target.value, false, false);
    }

    @HostListener('change', ['$event']) ngOnChanges(e: any) {
        this.validateInput(e);
    }

    @HostListener('click', ['$event']) ngOnClick(e: any) {
        this.selectFocus(e);
    }

    validateInput(event: any) {
        const sectioned = event.target.value.split(':');
        if (sectioned.length !== 2) {
            if (sectioned[0] < 10 && sectioned[0][0] != 0) {
                sectioned[0] = '0' + sectioned[0]
            }
            event.target.value = sectioned[0] + ':00'; // fallback to default
            return;
        }
        if (sectioned.length === 2 && sectioned[1].length === 0) {
            event.target.value = '00:00'; // fallback to default
            return;
        }
        if (sectioned.length === 2 && sectioned[0].length === 0) {
            event.target.value = '00:00'; // fallback to default
            return;
        }
        if (isNaN(sectioned[0])) {
            sectioned[0] = '00';
        }
        if (isNaN(sectioned[1]) || sectioned[1] < 0) {
            sectioned[1] = '00';
        }
        if (sectioned[1] > 59 || sectioned[1].length > 2) {
            sectioned[1] = '59';
        }
        // if(sectioned[0] < 10 && sectioned[0][0] != 0){
        //     sectioned[0] = "0"+sectioned[0]
        // }
        if (sectioned[1] < 10) {
            if (sectioned[1] != "00" && (sectioned[1] == 0 || sectioned[1][0] != 0))
                sectioned[1] = "0" + sectioned[1]
        }
        event.target.value = sectioned.join(':');
    }

    selectFocus = (event: any) => {
        // get cursor position and select nearest block;
        const cursorPosition = event.target.selectionStart;
        // '00:00' this is the format used to determine cursor location
        const hourMarker = event.target.value.indexOf(':');
        if (hourMarker < 0) {
            // something wrong with the format. just return;
            return;
        }
        if (cursorPosition < hourMarker) {
            event.target.selectionStart = 0; // hours mode
            event.target.selectionEnd = hourMarker;
        }
    };

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event: any) {
        this.onInputChange(event.target.value, true, false);
    }

    @HostListener('keydown.delete', ['$event'])
    keydownDelete(event: any) {
        this.onInputChange(event.target.value, false, true);
    }

    onInputChange(value: string, isBackspace: boolean, isDelete: boolean) {
        let start = this.el.nativeElement.selectionStart;
        let end = this.el.nativeElement.selectionEnd;
        if (isBackspace) {
            // don't consider it a backspace unless it makes a change
            //  even if we are going to undo the change in the subsequent call
            if (start != end || start > 1)
                this.isPreviewKeyABackspace = isBackspace;
            return;
        }

        if (isDelete) {
            // don't consider it a delete unless it makes a change
            //  even if we are going to undo the change in the subsequent call
            if (start != end || start < value.length)
                this.isPreviewKeyADelete = isDelete;
            return;
        }
        if (value == null)
            return;
        let newVal = value.replace(/\D/g, '');
        let rawVal = newVal;

        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length <= 2) {
            newVal = newVal.replace(/^(\d{0,2})/, '$1');
        }
        else if (newVal.length <= 4) {
            newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1:$2');
        } else {
            newVal = newVal.substring(0, 4);
            newVal = newVal.replace(
                /^(\d{0,2})(\d{0,2})/,
                '$1:$2'
            );
        }
        this.ngControl.valueAccessor?.writeValue(newVal);
        this.ngControl.control?.setValue(newVal, { emitEvent: false });
        this.valueChange.emit(true);
        let offset = 0;
        if (this.isPreviewKeyADelete == true) {
            offset = 0;
        } else if (this.isPreviewKeyABackspace == true) {
            if (start == 1) offset = 0;
            // sep 1 : 1
            //else if (start > 1 && start < 5) offset = 0; // section 1 : 2 to 4
            else if (start >= 1 && start < 3) offset = 4 - start;
        } else {
            // Just in case the user typed in a letter then we should undo the progress
            if (rawVal === this.oldValue.replace(/\D/g, '')) offset = -1;
            // because a paranthesis is introduced, we should progress one step
            else if (start == 1) offset = 1;
            // sep 1 : 1
            //else if (start > 1 && start < 5) offset = 0; // section 1 : 2 to 4
            else if (start >= 3) offset = 7 - start;
        }
        this.oldValue = newVal;
        this.el.nativeElement.setSelectionRange(start + offset, end + offset);

        this.isPreviewKeyABackspace = isBackspace;
        this.isPreviewKeyADelete = isDelete;
    }
}
