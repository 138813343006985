<div class="dialog-header">
    <div class="row">
        <span class="dialog-title">{{ "SELECT_COMPANY" | translate }}?</span>
    </div>
    @if(dialogData.closeButton){
        <div class="close-icon" (click)="close()">
            <img (click)="close()" src="/assets/images/x-close-black.svg" />
        </div>
    }
</div>
<div class="dialog-body">
    <div class="form mt-2">
        <form [formGroup]="selectCompanyForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-12 form-group dropdown" *ngIf="userCompanies.length > 1">
                    <label>{{'COMPANY_NAME' |translate}}</label><br>
                    <app-dropdown [dropdownList]="userCompanies" [control]="selectCompanyForm.controls.company"
                        [id]="'change-company'" (changeValueEvent)="changeCompany($event)">
                    </app-dropdown>
                </div>
                <div class="col-12 form-group dropdown" [class.disabled]="!company?.value"
                    *ngIf="compnayLocationsList.length > 1">
                    <label>{{'COMPANY_LOCATION' |translate}}</label><br>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="dialog-footer">
    <div class="app-actions-button">
        <button class="primary-button-large w-100" [class.disabled]="!checkValidation()" (click)="onSubmit()">{{"CONTINUE"
            |translate}}</button>
    </div>
</div>