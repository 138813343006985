
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { DownloadReport, DownloadReportOptionSub, DownloadReportOptions } from 'src/app/shared/model/download-report-dialog.model';

const APIURL = `${environment.apiUrl}/cmp/`;

@Component({
    selector: 'app-download-report',
    templateUrl: './download-report.component.html',
    styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<DownloadReportComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DownloadReport,
        private http: HttpClient,
        private sharedService: SharedService,
        private translate: TranslateService
    ) { }

    @ViewChild('progressBar') progressBar: ElementRef | null = null;
    showAttachments: boolean = false;
    allComplete: boolean = false;
    errorMessages: Set<string> = new Set();
    isBusy: number = 0;
    hasApplication: boolean = false;
    isStartDownload: boolean = false;
    isExportFail: boolean = false;
    ngOnInit(): void {
        this.showAttachmentsChange();
    }

    updateAllComplete() {
        if (this.showAttachments) {
            this.allComplete = this.dialogData.downloadReportOptions.sub != null && this.dialogData.downloadReportOptions.sub.every(t => t.checked);
        } else {
            let downloadReportOptionsSubCopy: DownloadReportOptionSub[] = JSON.parse(JSON.stringify(this.dialogData.downloadReportOptions.sub));
            downloadReportOptionsSubCopy = downloadReportOptionsSubCopy.filter(t => t.isAttachment == false)
            this.allComplete = this.dialogData.downloadReportOptions.sub != null && downloadReportOptionsSubCopy.every(t => t.checked);
        }
    }

    someComplete(): boolean {
        if (this.dialogData.downloadReportOptions.sub == null) {
            return false;
        }
        return this.dialogData.downloadReportOptions.sub.filter(t => t.checked).length > 0 && !this.allComplete;
    }

    setAll(completed: boolean) {
        this.allComplete = completed;
        if (this.dialogData.downloadReportOptions.sub == null) {
            return;
        }
        if (this.showAttachments) {
            this.dialogData.downloadReportOptions.sub.forEach(t => {
                t.checked = completed
            });
        } else {
            this.dialogData.downloadReportOptions.sub.forEach(t => {
                if (t.isAttachment == false)
                    t.checked = completed
            });
        }
    }

    showAttachmentsChange() {
        this.updateAllComplete();
        this.hasApplication = this.dialogData.downloadReportOptions.sub.find(e => e.isApplication == true) ? true : false;
        this.dialogData.downloadReportOptions.sub.forEach(e => {
            if (e.isAttachment) {
                e.show = this.showAttachments;
                if (!this.showAttachments) {
                    e.checked = false;
                }
            } else {
                e.show = true;
            }
        })
    }

    close() {
        this.resetChecked();
        this.dialogRef.close();
    }

    checkSelected() {
        if (this.dialogData.downloadReportOptions.sub.filter(t => t.checked).length > 0)
            return true
        else
            return false
    }

    checkAttachment() {
        if (this.dialogData.downloadReportOptions.sub.filter(t => t.isAttachment).length > 0)
            return true
        else
            return false
    }

    resetChecked() {
        this.dialogData.downloadReportOptions.sub.forEach(e => {
            e.checked = false;
        })
    }

    async downloadReport() {
        try {
            this.isStartDownload = true;
            setTimeout(() => {
                this.progressBar?.nativeElement.animate(
                    [{ width: '0%' }, { width: '100%' }],
                    {
                        duration: 1500,
                        iterations: 100000000000,
                    }
                );
            });
            let resultJson: any = {
                isShowAttachment: this.showAttachments
            }
            this.dialogData.downloadReportOptions.sub.forEach(s => {
                if (!s.isAttachment) {
                    resultJson[s.key] = {
                        isMainPage: s.checked
                    }
                } else {
                    if (s.parent) {
                        resultJson[s.parent][s.key] = s.checked;
                    }
                }
            });
            let response = await this.downloadFile(resultJson);
            if (response.sucsses) {
                this.resetChecked();
                this.dialogRef.close({
                    confirmn: true,
                })
            } else {
                this.isExportFail = true;
            }
        } catch (error: any) {
            this.isExportFail = true;
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        } finally {
            this.resetChecked();
        }
    }

    getReport(
        body: {}
    ): Observable<Blob | any> {
        let url = APIURL + this.dialogData.reportAPI;
        return this.http.post(url, body, {
            observe: 'response',
            responseType: 'blob',
        });
    }


    // async printFile(
    //     body: {}
    // ) {
    //     try {
    //         let response = await lastValueFrom(this.getReport(body));
    //         var newBlob = new Blob([response.body], {
    //             type: response.body.type,
    //         });
    //         const data = window.URL.createObjectURL(newBlob);
    //         let iframe = document.createElement('iframe');
    //         document.body.appendChild(iframe);

    //         iframe.style.display = 'none';
    //         iframe.src = data;
    //         iframe.onload = function () {
    //             setTimeout(function () {
    //                 iframe.focus();
    //                 iframe.contentWindow?.print();
    //             }, 1);
    //         };
    //         return {
    //             sucsses: true
    //         }
    //     } catch (err) {
    //         return {
    //             sucsses: false
    //         }
    //     } finally {
    //     }
    // }

    async downloadFile(body: {}) {
        try {
            let response = await lastValueFrom(this.getReport(body));
            var newBlob = new Blob([response.body], { type: response.body.type });
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = this.translate.instant(this.dialogData.title);
            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                })
            );

            await setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            return {
                sucsses: true
            }
        } catch (err) {
            this.sharedService.alertDangerMessage(
                'Download Failed',
                'There was an error downloading the file, please try again'
            );
            return {
                sucsses: false
            }
        } finally {
        }
    }

}
