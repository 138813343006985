import { TruckAux } from '../../../../modules/Trucks/models/trucks.model';
import { ColumnTypeEnum, TableOptions, TablesNameEnum } from '../../../model/app-table.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { TrucksApiService } from 'src/app/modules/Trucks/services/trucks-api.service';
import { ActionResponse, GetDataOptions } from 'src/app/shared/model/shared.model';

@Component({
    selector: 'app-add-truck',
    templateUrl: './add-truck.component.html',
    styleUrls: ['./add-truck.component.scss']
})
export class AddTruckComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<AddTruckComponent>,
        private truckApiService: TrucksApiService,
        @Inject(MAT_DIALOG_DATA) public dialogData: {
            selectedTrucksIds: number[]
        }
    ) { }
    dataTableConfig: TableOptions<TruckAux> | null = null;
    selectedRows: TruckAux[] = [];
    ngOnInit(): void {
        this.dataTableConfig = {
            name: TablesNameEnum.AddTruck,
            allowSearch: true,
            allowOrdering: true,
            allowSettings: true,
            column: [
                {
                    label: '',
                    key: '',
                    type: ColumnTypeEnum.checkbox,
                    width: "4.5rem",
                    hideSorting: true,
                    visible: true
                },
                {
                    label: 'TRUCK_ID',
                    key: 'assetCode',
                    visible: true,
                },

                {
                    label: 'MAKE',
                    key: 'make',
                    visible: true,
                },
                {
                    label: 'MODEL',
                    key: 'model',
                    visible: true,
                },
                {
                    label: 'YEAR',
                    key: 'modelYear',
                    visible: true,
                },
                {
                    label: 'PLATE_NUMBER',
                    key: 'plateNumber',
                    visible: true,
                },
            ],
            allowActions: false,
            getData: (searchObj: GetDataOptions) => this.getData(searchObj),
            allowCards: false,
            tableHeight: "40rem"
        };
    }

    async getData(options: GetDataOptions): Promise<ActionResponse<TruckAux>> {
        try {
            let optionsApi = {
                searchString: options?.searchString || '',
                pageIndex: options.pageIndex,
                pageSize: options.pageSize,
                sort: options.sort ?? '',
                searchById: 0
            };
            let response = await this.truckApiService.getTruckListAux(
                optionsApi,
                false
            );
            if (response && response.data) {
                if (this.dialogData.selectedTrucksIds) {
                    response.data.pageData = response.data.pageData.filter(
                        (item) => !this.dialogData.selectedTrucksIds.includes(item.assetId ? +item.assetId : 0)
                    );
                }
                return {
                    success: true,
                    data: response.data,
                    isRefresh: true,
                };
            } else {
                return {
                    success: false,
                };
            }
        } catch (error: any) {
            return {
                success: false,
            };
        }
    }

    onSelectRow(list: TruckAux[]) {
        this.selectedRows = list;
    }

    close() {
        this.dialogRef.close();
    }

    addTruks() {
        this.dialogRef.close(this.selectedRows);
    }

}
