<div class="dropdown" #dropdownContainer>
    <button
        type="button"
        [id]="id"
        [disabled]="disabled"
        (click)="onShowDropdown()"
        [style]="'height:' + buttonHeight"
    >
        <span
            [ngClass]="{
                'dropdown-placeholder': getDropdownLabel(control) == placeholder
            }"
            *ngIf="control?.value || control?.value == null; else novalue"
            >{{ getDropdownLabel(control) | translate }}</span
        >
        <ng-template #novalue>
            <span class="dropdown-placeholder">{{
                placeholder | translate
            }}</span>
        </ng-template>
    </button>
    <ul class="dropdown-menu-stat" #list [class.d-none]="!showDropdownMenu">
        <ng-container *ngIf="showDropdownMenu">
            <ng-container *ngFor="let item of dropdownList; let i = index">
                <li [class.active]="control?.value == item.value">
                    <ng-container *ngIf="!item.hide">
                        <a
                            class="dropdown-itemz"
                            (click)="setjobStatusValue(item.value)"
                            href="javascript:void(0)"
                            (keyup.enter)="setjobStatusValue(item.value)"
                        >
                            <span class="body-short-1"
                                >{{ item.text | translate }}
                            </span>
                            <span
                                class="caption-1 item-desc"
                                *ngIf="item.description"
                                >{{ item.description | translate }}</span
                            >
                        </a>
                        <div
                            class="line"
                            *ngIf="i < dropdownList.length - 1 && !item.hide"
                        >
                            <hr />
                        </div>
                    </ng-container>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</div>
