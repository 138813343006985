import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstChar'
})
export class FirstCharPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    const words = value.split(' ');
    const firstChars = words.map(word => word.charAt(0));
    return firstChars.join('');
  }
}
