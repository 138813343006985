import { AlertSnooze, NotificationsApiService } from './../../../services/notifications-api.service';
import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedTemplatesService } from 'src/app/shared/services/shared-templates.service';

interface SnoozOptions {
    text: string
    value: number
}

@Component({
    selector: 'app-snooze-reminder',
    templateUrl: './snooze-reminder.component.html',
    styleUrl: './snooze-reminder.component.scss'
})
export class SnoozeReminderComponent {

    constructor(
        public sharedTemplatesService: SharedTemplatesService<SnoozeReminderComponent>,
        private dialogRef: MatDialogRef<SnoozeReminderComponent>,
        private notificationsApiService: NotificationsApiService,
        @Inject(MAT_DIALOG_DATA) public dialogData: {notificationId: number},
    ) { }

    isBusy: boolean = false;
    snoozOptionValue: number = 1;
    todayDate: Date = new Date();
    snoozeDate: string = "";
    errorMessages: Set<string> = new Set();
    snoozOptions: SnoozOptions[] = [
        {
            text: "1 Day",
            value: 1
        },
        {
            text: "3 Days",
            value: 3
        },
        {
            text: "7 Day",
            value: 7
        },
        {
            text: "Custom",
            value: -1
        },
    ];

    selectTime(item: SnoozOptions) {
        this.snoozOptionValue = item.value;
        if (item.value == -1) {
            this.dialogRef.updateSize('53.5rem');
        } else {
            this.dialogRef.updateSize('29.9rem');
        }
    }

    close() {
        this.dialogRef.close();
    }

    async saveChanges() {
        try {
            this.isBusy = true;
            let snoozedForDate: string = "";
            if (this.snoozOptionValue == -1) {
                snoozedForDate = this.snoozeDate;
            } else {
                let today = new Date();
                today.setDate(today.getDate() + this.snoozOptionValue);
                snoozedForDate = today.toISOString().split('T')[0];
            }
            let body: AlertSnooze = {
                userAlertId: this.dialogData.notificationId,
                snoozedForDate: snoozedForDate
            }
            let response = await this.notificationsApiService.alertSnooze(body);
            if(response.success) {
                this.dialogRef.close("success");
            }
        } catch (error: any) {
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        } finally {
            this.isBusy = false;
        }
    }
}
