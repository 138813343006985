<div class="row card-header-action">
    <div class="col-xl-3 col-lg-4 col-md-5 col-12 search" *ngIf="cardConfig?.allowSearch">
      <div class="form-group">
        <input class="body-large" placeholder="{{ 'SEAECH_BY_KEYWORD' | translate }}" type="text"
          [(ngModel)]="searchInput" (ngModelChange)="searchApi()" />
      </div>
    </div>
    <div class="col-xl-9 col-lg-8 col-md-7 col-12 other-action" *ngIf="cardConfig && cardConfig.cardHeaderAction">
      <ng-container *ngTemplateOutlet="cardConfig.cardHeaderAction;">
      </ng-container>
    </div>
  </div>
  <div class="row" [ngClass]="isBusy && (cardConfig?.allowWrapperDisabled == undefined || cardConfig?.allowWrapperDisabled == true) ? 'wrapper-disabled': ''">
    <!-- No Records -->
    <div class="no-records col-12" *ngIf="!isBusy && (cardsData == undefined || cardsData?.length == 0)">
      <h3 class="heading-4">{{ "NO_RECORDS_FOUND" |translate }}</h3>
      <p class="body-short-2">{{"YOU_DONOT_HAVE_ANY_DATA_IN_THESE_SECTION" |translate}}</p>
    </div>
    <!-- Loading -->
    <div class="no-records col-12" *ngIf="isBusy">
      <h3 class="heading-4">{{ "LOADING" |translate }}...</h3>
      <p class="body-short-2">{{"YOUR_DATA_IS_LOADING_PLEASE_WAIT" |translate}}</p>
    </div>
    <!--  -->
    <ng-container *ngIf="!isBusy">
      <div
        *ngFor="let card of cardsData | paginate: { itemsPerPage: pageSize, currentPage: this.options.pageIndex +1 ,totalItems: totalCount }"
        [ngClass]="cardConfig?.class ?? ''">
        <ng-container
          *ngTemplateOutlet="cardConfig && cardConfig.template ? cardConfig.template : null;context: {card : card,cardComp: this}">
        </ng-container>
      </div>
    </ng-container>
  
  </div>
  <div class="row" *ngIf="cardsData?.length">
    <div class="col-md-5 col-12 mt-4 body-large showing-section" *ngIf="cardConfig?.allowInfo">
      {{ "SHOWING" | translate }}
      {{ showRowFrom + "-" + showRowTo }}
      {{ "OF" | translate }} {{ totalCount }} {{ "ENTRIES" | translate }}
    </div>
    <div class="col-md-7 col-12" *ngIf="cardConfig?.allowPaging && pages > 1">
      <!-- <app-pagination-bar-card [pages]="pages" [currentPage]="pageIndex"
        (selectPage)="selectPage($event, cardConfig ?? undefined)">
      </app-pagination-bar-card> -->
      <pagination-controls (pageChange)="changePage($event)" previousLabel=""
        nextLabel="" [maxSize]="maxSize"></pagination-controls>
    </div>
  </div>