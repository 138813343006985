import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface noProviderData {
    providerType: string;
}
@Component({
    selector: 'app-no-provider-modal',
    templateUrl: './no-provider-modal.component.html',
    styleUrls: ['./no-provider-modal.component.scss'],
})
export class NoProviderModalComponent implements OnInit {
    title: string = '';
    messageHeader: string = '';
    messageBody: string = '';
    addBtnTxt: string = '';
    constructor(
        public dialog: MatDialogRef<NoProviderModalComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: noProviderData
    ) {}

    ngOnInit(): void {
        switch (this.dialogData.providerType) {
            case 'consotrium':
                this.title = 'CONSORTIUM_NOT_AVAILABLE';
                this.messageHeader =
                    'PLEASE_ADD_CONSORTIUM_IN_SETTINGS_BEFORE_SCHEDULING_TEST';
                this.messageBody =
                    'TEST_CANNOT_BE_SCHEDULED_IF_CONSORTIUM_NOT_ADDED';
                this.addBtnTxt = 'ADD_CONSORTIUM';
                break;
            case 'psp':
                this.title = 'PSP_PROVIDER_NOT_AVAILABLE';
                this.messageHeader = 'PLEASE_ADD_PSP_PROVIDER_IN_YOUR_SETTIGNS';
                this.addBtnTxt = 'ADD_PSP_PROVIDER';
                break;
            case 'mvr':
                this.title = 'MVR_PROVIDER_NOT_AVAILABLE';
                this.messageHeader = 'PLEASE_ADD_MVR_PROVIDER_IN_YOUR_SETTIGNS';
                this.messageBody = '';
                this.addBtnTxt = 'ADD_MVR_PROVIDER';
        }
    }

    save() {
        this.dialog.close('add');
    }
    cancel() {
        this.dialog.close('cancel');
    }
}
