import { DropdownList } from './../../model/shared.model';
import {
    Component,
    Input,
    OnInit,
    EventEmitter,
    Output,
    HostListener,
    ElementRef,
    ViewChild,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('dropdownContainer') dropdownContainer: ElementRef | null = null;
    @ViewChild('list') list: ElementRef | null = null;
    @Input() dropdownList: DropdownList[] = [];
    @Input() buttonHeight: string | number = '';
    @Input() control: any | null = null;
    @Input() id: string = '';
    @Input() disabled: boolean = false;
    @Input() placeholder: string = 'CHOOSE_AN_OPTION';
    @Input() openlistInTop: boolean = false;
    @Output() changeValueEvent = new EventEmitter<string | number | null>();
    showDropdownMenu: boolean = false;
    containerTopInit: number | null = null;
    positionInterval: any;
    constructor() { }

    @HostListener('window:scroll', []) onWindowScroll() {
        this.showDropdownMenu = false;
        this.relocateList();
    }

    @HostListener('window:resize', []) onWindowResize() {
        this.showDropdownMenu = false;
        this.relocateList();
    }

    @HostListener('document:click', ['$event']) onDocClick(event: any) {
        const outsideClick =
            typeof event.composedPath === 'function' &&
            !event
                .composedPath()
                .includes(this.dropdownContainer?.nativeElement);

        if (outsideClick) {
            this.showDropdownMenu = false;
            this.relocateList();
        }
    }

    ngOnInit(): void {
        this.positionInterval = setInterval(() => {
            this.relocateList();
        }, 1);
    }

    ngAfterViewInit() {
        this.relocateList();
    }

    onShowDropdown() {
        this.showDropdownMenu = !this.showDropdownMenu;
        this.relocateList();
    }

    setjobStatusValue(value: string | number | null) {
        if (this.control) this.control.patchValue(value);
        this.changeValueEvent.emit(value);
        this.showDropdownMenu = false;
    }

    getDropdownLabel(control: any) {
        if (control) {
            let selectedItem = this.dropdownList.find(
                (item) => item.value == control.value
            );
            if (selectedItem?.text) return selectedItem.text;
            else return this.placeholder;
        } else {
            return ""
        }
    }

    private relocateList() {
        let width =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().width;
        let top =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().top;
        let left =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().left;
        let listHeghit = this.list?.nativeElement.getBoundingClientRect().height;
        if (this.list) {
            this.list.nativeElement.style.width! = width + 'px';

            this.list.nativeElement.style.left! = left + 'px';
            if (this.openlistInTop) {
                this.list.nativeElement.style.top! = top - listHeghit - 3 + 'px';
            } else {
                this.list.nativeElement.style.top! = top + 32 + 'px';
            }
            this.list?.nativeElement.addEventListener(
                'focusout',
                (event: any) => {
                    this.showDropdownMenu = false;
                }
            );
            // this.list?.nativeElement.addEventListener('mouseover', (event: any) => {
        }
        if (this.containerTopInit) {
            if (this.containerTopInit != top && this.showDropdownMenu) {
                this.showDropdownMenu = false;
            }
        } else {
            this.containerTopInit = top;
        }
        this.containerTopInit = top;
    }

    ngOnDestroy(): void {
        clearInterval(this.positionInterval);
    }
}
