export interface TruckList {
    id: number;
    assetId: number;
    fuelType: string;
    assetStatus: string;
    modelYear: number;
    vin: string;
    model: string;
    registeredState: string;
    plate: string;
    annualInspection: Date;
    purchaseDate: Date;
    isCompleteInformation: boolean
}
export interface TruckBasicInformation {
    id?: number;
    assetCode?: string;
    companyId?: number;
    companyLocationId?: number;
    assetStatus?: string;
    assetPhotoDocumentId?: number | null;
    ownership?: string;
    ownedBy?: string;
    titleNumber?: string;
    vin?: string;
    make?: string;
    model?: string;
    modelYear?: number;
    includeForIFTA?: boolean;
    assetId?: number;
    numberOfAxles?: number;
    gvwr?: number;
    unladenWeight?: number;
    height?: number;
    fuelType?: string;
    annualInspectionDocumentId?: number | null;
    certificateTitleDocumentId?: number | null;
    annualInspectionExpirationDate: Date | string | null;
    leaseAgreementDocumentId: number | null;
    form2290ExpiryDate: Date | string | null;
    form2290DocumentId: number | null;
    assetValue: number | null;
    terminationLetterDocumentId: number | null;
    dateInService: string | null;
    terminationDate: string | null;
}

export interface TruckSpecification {
    id: number;
    numberOfAxles: number;
    gvwr: number;
    unladenWeight: number;
    height: number;
    fuelType: string;
    engineSerialNumber: string;
    engineBrand: string;
    horsepower: string;
    steerAxelTireSize: number | null;
    driveAxelTireSize: number | null;
    odometerReading: number;
    cabinType: string | null;
    transmission: string;
}

export class TruckInformation {
    annualInspectionDocumentId?: number | null;
    assetCategory?: string;
    assetCode?: string;
    assetDescription?: string;
    assetId?: number;
    assetPhotoDocumentId?: number | null;
    assetQuantity?: number;
    assetStatus?: string;
    assetSubCategory?: string;
    assetTag?: string;
    cabinType?: string | null;
    certificateTitleDocumentId?: number | null;
    companyId?: number;
    companyLocationId?: number;
    createDate?: string | Date;
    driveAxelTireSize?: number | null;
    engineBrand?: string;
    engineSerialNumber?: number | null;
    annualInspectionExpirationDate?: string | Date;
    fleetNumber?: number | null;
    fuelType?: string;
    gvwr?: number;
    height?: number;
    horsepower?: number;
    id?: number;
    inServiceDate?: String | Date;
    includeForIFTA?: boolean;
    isAidKit?: boolean;
    isCompanyDecalsOnBothSides?: boolean;
    isELDdeviceAvailable?: boolean;
    isEmergencyTriangleAvailable?: boolean;
    isExtraCoolant?: boolean;
    isExtraFuses?: boolean;
    isExtraOil?: boolean;
    isFireExtinguisherAvailable?: boolean;
    isIFTADecalsOnBothSides?: boolean;
    isJumperCable?: boolean;
    isLoadBars?: boolean;
    isPaperLogbookAvailable?: boolean;
    isTruckBinderOnboard?: boolean;
    isSpareTire?: boolean;
    isUpdatedSafteyInformation?: boolean;
    leaseAgreementDocumentId?: number | null;
    make?: string;
    model?: string;
    modelYear?: number;
    numberOfAxles?: number;
    odometerReading?: number;
    ownedBy?: string;
    ownership?: string;
    parentAssetId?: number | null;
    plateNumber?: number | null;
    purchaseDate?: string | Date;
    registeredState?: string;
    registrationDocumentId?: number;
    registrationExpirationDate?: string | Date;
    steerAxelTireSize?: number | null;
    titleNumber?: string;
    transmission?: string;
    unladenWeight?: number;
    usefulLife?: string;
    vin?: string;
    registrationNumber?: string; //deleted
    form2290DocumentId?: number | null;
    form2290ExpiryDate?: Date | string | null;
    includeForNyHut?: boolean;
    permitTruckLicenseNumber?: string;
    assetValue?: number | null;
    terminationLetterDocumentId: number | null = null;
    dateInService?: string;
    terminationDate?: string;
    inspectionDate?: string;
    IsCompleteInformation?: boolean = false;
    constructor(init?: Partial<TruckInformation>) {
        Object.assign(this, init);
    }
}

export class TruckRegistration {
    id?: number;
    registeredState: string = '';
    registrationDocumentId: number | null = null;
    plateNumber?: string;
    fleetNumber?: string;
    isUpdatedSafteyInformation?: boolean;
    registrationExpirationDate?: string | Date | null;
    includeForIFTA?: boolean;
    includeForNyHut?: boolean;
    permitTruckLicenseNumber?: string;
    constructor(init?: Partial<TruckRegistration>) {
        Object.assign(this, init);
    }
}

export interface VinNumberInfo {
    make: string;
    model: string;
    modelYear: string;
    id: number;
}
export class truckOnboarding {
    id?: number;
    isCompanyDecalsOnBothSides: boolean = false;
    isIFTADecalsOnBothSides: boolean = false;
    isFireExtinguisherAvailable: boolean = false;
    isEmergencyTriangleAvailable: boolean = false;
    isELDdeviceAvailable: boolean = false;
    isPaperLogbookAvailable: boolean = false;
    isTruckBinderOnboard: boolean = false;
    isExtraOil: boolean = false;
    isExtraCoolant: boolean = false;
    isExtraFuses: boolean = false;
    isJumperCable: boolean = false;
    isSpareTire: boolean = false;
    isAidKit: boolean = false;
    isLoadBars: boolean = false;

    constructor(init?: Partial<truckOnboarding>) {
        this.isAidKit = false;
        this.isCompanyDecalsOnBothSides = false;
        this.isELDdeviceAvailable = false;
        this.isEmergencyTriangleAvailable = false;
        this.isExtraCoolant = false;
        this.isExtraFuses = false;
        this.isExtraOil = false;
        this.isFireExtinguisherAvailable = false;
        this.isIFTADecalsOnBothSides = false;
        this.isJumperCable = false;
        this.isLoadBars = false;
        this.isPaperLogbookAvailable = false;
        this.isSpareTire = false;
        Object.assign(this, init);
    }
}

export class OtherDocument {
    id: number = 0;
    documentId?: number;
    issuer?: string;
    documentType?: string;
    description?: string;
    issueDate?: string | Date | null;
    expiryDate?: string | Date | null;
    // tags?: { name: string; id: number }[];

    constructor(init?: Partial<OtherDocument>) {
        this.id = 0;
        Object.assign(this, init);
    }
}
export class TruckAux {
    assetId: number = 0;
    assetCode: string = '';
    id: number = 0;
    make?: string = '';
    model?: string = '';
    modelYear?: number = 0;
    plateNumber?: string = '';
    vin?: string = '';
    assetTruckId?: 0;
    assetValue?: number | string | null;
    constructor(init?: Partial<TruckAux>) {
        Object.assign(this, init);
    }
}
export interface TruckAuxInterface {
    assetId: number;
    assetCode: string;
    id: number;
    make: string;
    model: string;
    modelYear: string;
    plateNumber: string;
    vin: string;
}

export interface TruckNotInsured {
    id: number;
    assetId: number;
    assetCode: string;
    assetValue: number;
}

export interface TruckShouldUnInsure {
    id: number;
    insuranceId: number;
    assetId: number;
    assetCode: string;
    assetValue: number;
}
