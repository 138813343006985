import {
    Component,
    OnInit,
    OnDestroy,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbsConfig, Breadcrumb } from '../../model/shared.model';
import { Location } from '@angular/common';
@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() isLoading: boolean = true;
    @Input() set config(value: BreadcrumbsConfig) {
        if (!this.isObjectEqual(value, this._config)) {
            // Object properties have changed, perform necessary actions
            this._config = value;
        }
    }
    // config: BreadcrumbsConfig = {
    //     editMode: true,
    //     backButtonLink: null,
    //     newObjectTitle: '',
    //     hasTabs: false,
    //     inverseIndex: 0,
    //     list: [],
    //     mainInfo: '',
    //     mainTitle: '',
    // };
    private _config: BreadcrumbsConfig = {
        editMode: true,
        backButtonLink: null,
        newObjectTitle: '',
        hasTabs: false,
        inverseIndex: 0,
        list: [],
        mainInfo: '',
        mainTitle: '',
        // objectToInsert: null,
    };
    get config(): BreadcrumbsConfig {
        return this._config;
    }
    routerSub: Subscription | null = null;
    constructor(private location: Location) { }

    ngOnInit(): void {
        if (
            !this.config.editMode &&
            !this.config.list.some((el) => el.title == 'NEW')
        )
            this.config.list.push({
                id: "",
                info: null,
                routerLink: null,
                title: 'NEW',
            });
        if (this.config.hasTabs && !this.isLoading) {
            this.getTabName(window.location.href);

            this.location.onUrlChange((url: string, state: any) => {
                this.getTabName(url);
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            !this.config.editMode &&
            !this.config.list.some((el) => el.title == 'NEW')
        )
            this.config.list.push({
                id: "",
                info: null,
                routerLink: null,
                title: 'NEW',
            });

        if (changes.config) {
            if (changes.config.currentValue.editMode) {
                this.config.list = this.config.list.filter(
                    (el) => el.title != 'NEW'
                );
            }
        }
        if (this.config.hasTabs && !changes.isLoading.currentValue) {
            if (!this.config.notSetNameFromRoute) {
                this.getTabName(window.location.href);

                this.location.onUrlChange((url: string, state: any) => {
                    this.getTabName(url);
                });
            }
        }
    }

    // insertIntoList(
    //     item: BreadcrumbsList,
    //     index?: number | null,
    //     isNew?: boolean
    // ) {
    //     if (
    //         this.config.list.some(
    //             (el) => el.title == item.title && el.info == item.info
    //         )
    //     ) {
    //         return;
    //     } else {
    //         if (index) {
    //             if (isNew) {
    //                 this.config.list.splice(index, 0, item);
    //             } else {
    //                 this.config.list[index] = item;
    //             }
    //         } else {
    //             this.config.list.push(item);
    //         }
    //         this.config.list;
    //     }
    // }

    ngOnDestroy(): void {
        this.routerSub?.unsubscribe();
    }

    private getTabName(url: string) {
        if (url.includes('?')) {
            url = url.split('?')[0];
        }
        let urlArr = String(url).split('/');
        this.config!.list[this.config!.list.length - 1].info = urlArr[
            urlArr.length - 1 - (this.config.inverseIndex ?? 0)
        ]
            .replace(new RegExp('-', 'g'), '_')
            .toUpperCase();
    }

    private isObjectEqual(obj1: any, obj2: any): boolean {
        return JSON.stringify(obj1) === JSON.stringify(obj2);
    }
    // private checkDiff(
    //     obj1: BreadcrumbsConfig,
    //     obj2: BreadcrumbsConfig
    // ): boolean {
    //     if (obj1 === obj2) {
    //         return true;
    //     }

    //     if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    //         return false;
    //     }

    //     const keys1 = Object.keys(obj1);
    //     const keys2 = Object.keys(obj2);

    //     if (keys1.length !== keys2.length) {
    //         return false;
    //     }

    //     for (const key of keys1) {
    //         if (
    //             !obj2.hasOwnProperty(key) ||
    //             !this.checkDiff(obj1[key], obj2[key])
    //         ) {
    //             return false;
    //         }
    //     }

    //     return true;
    // }
}
