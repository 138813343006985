<div (click)="clickDate()" *ngIf="!viewMode" class="container-date" [class.open-in-top]="openlistInTop"
    id="container-date" #dateContainer (click)="handleClick($event,id)">
    <ng-container [ngSwitch]="dateType">
        <div *ngSwitchCase="dateTypeEnum.time">
            <!-- <input class="form-control custom-data-input" type="time" [ngModel]="date |date: 'hh:mm'"
                (click)="showInline = !showInline" (ngModelChange)="changeDateVal($event)" id="date-input-{{id}}"
                [readonly]="readonly"> -->
            <p-calendar #list getDateTimeLocalVal [(ngModel)]="date" [showIcon]="true" [maxDate]="maxDate" (ngModelChange)="onChangeInput()"
                (onClose)="onClose()" placeholder="hh:mm" (onSelect)="onSelect()" [timeOnly]="true"
                [class.readonly]="readonly" [hideOnDateTimeSelect]="false" [disabled]="readonly" dateMask inputId="{{id}}">
                <ng-template pTemplate="footer">
                    <button class="secondary-button-large" (click)="list.overlayVisible = false">Done</button>
                </ng-template>
            </p-calendar>
        </div>
        <div *ngSwitchCase="dateTypeEnum.dateTime">
            <div *ngIf="maxDate">
                <!-- <input class="form-control custom-data-input" type="datetime-local" [ngModel]="getDateTimeLocalVal()"
                    (click)="showInline = !showInline" (ngModelChange)="changeDateVal($event)" id="date-input-{{id}}"
                    [readonly]="readonly"> -->
                <p-calendar #list appendTo="body" [(ngModel)]="date" [showIcon]="true" [maxDate]="maxDate"
                    (onClose)="onClose()" placeholder="mm/dd/yyyy hh:mm" (onSelect)="onSelect(true)" (ngModelChange)="onChangeInput()"
                    [class.readonly]="readonly" [showTime]="true" [hideOnDateTimeSelect]="false"
                    [disabled]="readonly" dateMask hourFormat="12" [showButtonBar]="true" inputId="{{id}}">
                    <ng-template pTemplate="footer">
                        <button class="secondary-button-large" id="button-done"
                            (click)="list.overlayVisible = false;">Done</button>
                    </ng-template>
                </p-calendar>
            </div>
            <div *ngIf="minDate">
                <!-- <input class="form-control custom-data-input" type="datetime-local" [ngModel]="getDateTimeLocalVal()"
                    (click)="showInline = !showInline;" (ngModelChange)="changeDateVal($event)" id="date-input-{{id}}"
                    [readonly]="readonly"> -->
                <p-calendar #list appendTo="body" [(ngModel)]="date" [showIcon]="true" [minDate]="minDate"
                    (onClose)="onClose()" placeholder="mm/dd/yyyy hh:mm" (onSelect)="onSelect(true)" (ngModelChange)="onChangeInput()"
                    [class.readonly]="readonly" [showTime]="true" [hideOnDateTimeSelect]="false"
                    [disabled]="readonly" dateMask hourFormat="12" [showButtonBar]="true" inputId="{{id}}">
                    <ng-template pTemplate="footer">
                        <button class="secondary-button-large" id="button-done"
                            (click)="list.overlayVisible = false;">Done</button>
                    </ng-template>
                </p-calendar>
            </div>
            <div *ngIf="!maxDate && !minDate">
                <!-- <input class="form-control custom-data-input" type="datetime-local" [ngModel]="getDateTimeLocalVal()"
                    (click)="showInline = !showInline" (ngModelChange)="changeDateVal($event)" id="date-input-{{id}}"
                    [readonly]="readonly"> -->
                <p-calendar #list appendTo="body" [(ngModel)]="date" [showIcon]="true" (ngModelChange)="onChangeInput()"
                    (onClose)="onClose()" placeholder="mm/dd/yyyy hh:mm" (onSelect)="onSelect(true)"
                    [class.readonly]="readonly" [showTime]="true" [hideOnDateTimeSelect]="false"
                    [disabled]="readonly" dateMask hourFormat="12" [showButtonBar]="true" inputId="{{id}}">
                    <ng-template pTemplate="footer">
                        <button class="secondary-button-large"
                            (click)="list.overlayVisible = false;">Done</button>
                    </ng-template>
                </p-calendar>
            </div>
            <span class="tooltip-warning-custom" *ngIf="isDateExpired() && hasExpirationDate">
                <img src="/assets/images/warning-icon-red.svg" alt="" placement="end"
                    ngbPopover="{{'THIS_DATE_HAS_EXPIRED' |translate}}" />
            </span>
        </div>
        <div *ngSwitchCase="dateTypeEnum.date">
            <div *ngIf="maxDate">
                <!-- <input class="form-control custom-data-input" type="date" [ngModel]="date |date: 'yyyy-MM-dd'"
                    (click)="showInline = !showInline" (ngModelChange)="changeDateVal($event)" id="date-input-{{id}}"
                    [readonly]="readonly"> -->
                <p-calendar #list appendTo="body" [(ngModel)]="date" [showIcon]="true" [maxDate]="maxDate" (ngModelChange)="onChangeInput()"
                    [showButtonBar]="true" (onClose)="onClose()" placeholder="mm/dd/yyyy" (onSelect)="onSelect()"
                    [class.readonly]="readonly" [disabled]="readonly" dateMask inputId="{{id}}"></p-calendar>
            </div>
            <div *ngIf="minDate">
                <!-- <input class="form-control custom-data-input" type="date" [ngModel]="date |date: 'yyyy-MM-dd'"
                    (click)="showInline = !showInline" (ngModelChange)="changeDateVal($event)" id="date-input-{{id}}"
                    [readonly]="readonly"> -->
                <p-calendar appendTo="body" #list [(ngModel)]="date" [inline]="inline"
                    [showIcon]="true" [minDate]="minDate" [showButtonBar]="true" (onClose)="onClose()" (ngModelChange)="onChangeInput()"
                    placeholder="mm/dd/yyyy" (onSelect)="onSelect()" [class.readonly]="readonly"
                    [disabled]="readonly" dateMask inputId="{{id}}"></p-calendar>
            </div>
            <div *ngIf="!maxDate && !minDate">
                <!-- <input class="form-control custom-data-input" type="date" [ngModel]="date |date: 'yyyy-MM-dd'"
                    (click)="showInline = !showInline" (ngModelChange)="changeDateVal($event)" id="date-input-{{id}}"
                    [readonly]="readonly"> -->
                <p-calendar #list [(ngModel)]="date" appendTo="body" [showIcon]="true" [showButtonBar]="true" (ngModelChange)="onChangeInput()"
                    (onClose)="onClose()" placeholder="mm/dd/yyyy" (onSelect)="onSelect()" [class.readonly]="readonly"
                    [disabled]="readonly" dateMask inputId="{{id}}"></p-calendar>
                   
            </div>
            <span class="tooltip-warning-custom" *ngIf="isDateExpired() && hasExpirationDate">
                <img src="/assets/images/warning-icon-red.svg" alt="" placement="end"
                    ngbPopover="{{'THIS_DATE_HAS_EXPIRED' |translate}}" />
            </span>
        </div>
        <div *ngSwitchCase="dateTypeEnum.dateOnly">
            <div *ngIf="maxDate">
                <!-- <input class="form-control custom-data-input" type="date" [ngModel]="date |date: 'yyyy-MM-dd'"
                    (click)="showInline = !showInline" (ngModelChange)="changeDateVal($event)" id="date-input-{{id}}"
                    [readonly]="readonly"> -->
                <p-calendar #list appendTo="body" [(ngModel)]="date" [showIcon]="true" [maxDate]="maxDate" (ngModelChange)="onChangeInput()"
                    [showButtonBar]="true" (onClose)="onClose()" placeholder="mm/dd/yyyy" (onSelect)="onSelect()"
                    [class.readonly]="readonly" [disabled]="readonly" dateMask inputId="{{id}}"></p-calendar>
            </div>
            <div *ngIf="minDate">
                <!-- <input class="form-control custom-data-input" type="date" [ngModel]="date |date: 'yyyy-MM-dd'"
                    (click)="showInline = !showInline" (ngModelChange)="changeDateVal($event)" id="date-input-{{id}}"
                    [readonly]="readonly"> -->
                <p-calendar appendTo="body" #list [(ngModel)]="date" [inline]="inline"
                    [showIcon]="true" [minDate]="minDate" [showButtonBar]="true" (onClose)="onClose()" (ngModelChange)="onChangeInput()"
                    placeholder="mm/dd/yyyy" (onSelect)="onSelect()" [class.readonly]="readonly"
                    [disabled]="readonly" dateMask inputId="{{id}}"></p-calendar>
            </div>
            <div *ngIf="!maxDate && !minDate">
                <!-- <input class="form-control custom-data-input" type="date" [ngModel]="date |date: 'yyyy-MM-dd'"
                    (click)="showInline = !showInline" (ngModelChange)="changeDateVal($event)" id="date-input-{{id}}"
                    [readonly]="readonly"> -->
                <p-calendar #list [(ngModel)]="date" appendTo="body" [showIcon]="true" [showButtonBar]="true" (ngModelChange)="onChangeInput()"
                    (onClose)="onClose()" placeholder="mm/dd/yyyy" (onSelect)="onSelect()" [class.readonly]="readonly"
                    [disabled]="readonly" dateMask inputId="{{id}}"></p-calendar>
                   
            </div>
            <span class="tooltip-warning-custom" *ngIf="isDateExpired() && hasExpirationDate">
                <img src="/assets/images/warning-icon-red.svg" alt="" placement="end"
                    ngbPopover="{{'THIS_DATE_HAS_EXPIRED' |translate}}" />
            </span>
        </div>
       
    </ng-container>
</div>
<div *ngIf="viewMode" class="view-mode" id="{{id}}">
    <div *ngIf="date">
        <ng-container [ngSwitch]="dateType">
            <div *ngSwitchCase="dateTypeEnum.time">
                {{date |date:'hh:mm'}}
            </div>
            <div *ngSwitchCase="dateTypeEnum.dateTime">
                {{date |date:environment.dateTimeFormat}}
            </div>
            <div *ngSwitchCase="dateTypeEnum.date">
                {{date |date:environment.dateFormat}}
            </div>
        </ng-container>
    </div>
    
    <span class="tooltip-custom" *ngIf="isDateExpired() && hasExpirationDate">
        <img src="/assets/images/warning-icon-red.svg" alt="" placement="end"
            ngbPopover="{{'THIS_DATE_HAS_EXPIRED' |translate}}" />
    </span>
    <div *ngIf="!date" class="unfilled">Unfilled</div>
</div>

