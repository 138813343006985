<div class="container">
    <ng-container *ngIf="loading">
        <div class="main-content">
            <div class="row">
                <div class="col-12 d-flex justify-content-center">
                    <img src="/assets/images/list-proccessing.svg" class="front-img" alt="" />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 d-flex justify-content-center mt-4 mb-3">
                    <div class="progress-bar-container">
                        <div class="progress-bar">
                            <div class="progress-bar-value"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!loading && !isPasswordSet">
        <div class="main-content">
            <h2 class="heading-2">{{"SET_PASSWORD" |translate}}</h2>
            <p class="body-short-2 sub-title">{{"SET_PASSWORD_DESC" |translate}}</p>
            <form [formGroup]="form" [ngClass]="isBusy > 0 ? 'wrapper-disabled' : ''">
                <div class="form-body">
                    <div class="form-group" [class.field-error]="
                                        newPassword.errors && submitted ? true : false
                                    ">
                        <label for="new-password">{{
                            "NEW_PASSWORD" | translate
                            }}</label>
                        <app-password
                            formControlName="newPassword"
                            [id]="'new-password'"
                            [placeholder]="'NEW_PASSWORD'">
                        </app-password>
                        <div class="validation-msg" *ngIf="newPassword?.errors && submitted">
                            {{ "NEW_PASSWORD" | translate }}
                            {{ "NOT_VALID" | translate }}
                        </div>
                        <div class="hint">
                            <div class="label-1 password-hint">
                                {{ "PASSWORD_MUST_HAVE" | translate }}
                            </div>
                            <ul>
                                <li [class.fail]="newPassword.errors?.minlength || newPassword.value == ''">
                                    {{ "AT_LEAST_6_CHARACTERS" | translate }}
                                </li>
                                <li
                                    [class.fail]="newPassword.hasError('missingLowercase') || newPassword.hasError('missingUppercase')">
                                    {{ "ONE_UPPERCASE_LOWERCASE" | translate }}
                                </li>
                                <li [class.fail]="newPassword.hasError('missingSymbol')">{{ "A_SYMBOL"
                                    | translate }}</li>
                                <li [class.fail]="newPassword.hasError('missingNumber')">{{ "A_NUMBER" | translate }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="form-group" [class.field-error]="
                        confirmNewPassword.errors && submitted ? true : false
                                    ">
                        <label for="new-password">{{
                            "CONFIRM_NEW_PASSWORD" | translate
                            }}</label>
                        <app-password
                            formControlName="confirmNewPassword"
                            [id]="'confirm-new-password'"
                            [placeholder]="'CONFIRM_NEW_PASSWORD'">
                        </app-password>
                        <div class="validation-msg" *ngIf="confirmNewPassword?.errors && submitted">
                            {{ "CONFIRM_NEW_PASSWORD" | translate }}
                            {{ "IS_REQUIRED" | translate }}
                        </div>
                    </div>
                    <div class="invalid-form-warning mt-3" *ngIf="form.invalid &&submitted">
                        <div class="invalid-icon"></div>
                        <div class="warning-message">
                            {{ "GLOBAL_FORM_ERROR" | translate }}
                        </div>
                    </div>
                    <div class="invalid-form-warning mt-3" *ngFor="let message of errorMessages">
                        <div class="invalid-icon"></div>
                        <div class="warning-message">
                            {{ message | translate }}
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <button class="primary-button-large w-100" (click)="submit()">{{"ACTIVATE_ACCOUNT" |translate}}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
</div>

