<div [class.view-mode]="readOnly">
    <div class="check-input tc-check-input">
        <div class="form-check" [class.disabled]="disabled">
            <input [id]="id" class="form-check-input tc-form-check-input" type="checkbox"
                (change)="onChangeValue($event)" [formControl]="myControl" />
            <label class="tc-check-box-label" [for]="id">
                {{ label | translate}}
                <span *ngIf="isRequiredBlack" class="required-black">*</span>
                <span *ngIf="isRequired" class="required">*</span>
            </label>
        </div>
    </div>
</div>