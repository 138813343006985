<div class="dialog-header">
    <div class="row">
        <div class="col-6">
            <h5 class="dialog-title">{{dialogData.title | translate}}</h5>
        </div>
    </div>
    <div class="close-icon" (click)="close()">
        <img (click)="close()" src="/assets/images/x-close-black.svg" />
    </div>
    <div class="report-tabs">
        <ul class="mt-3">
            <!-- <li *ngFor="let item of reportTabs" class="body-short-1" [class.active]="activeTab == item.index"
                (click)="goToTab(item.index)">
                {{item.title}}
            </li> -->
            <li class="body-short-1" [class.active]="activeTab == 1"
                [class.disabled]="isExportDone || isBusy > 0 || isExportFail" (click)="goToTab(1)">
                Select Columns
            </li>
            <li class="body-short-1" [class.active]="activeTab == 2" [class.disabled]="activeTab == 1">
                Results
            </li>
        </ul>
    </div>
</div>
<div class="dialog-body">
    <ng-container *ngIf="activeTab == 1">
        <mat-checkbox [checked]="selectAllColumns" [indeterminate]="someComplete()" (change)="setAll($event.checked)">
            {{"SELECT_ALL" | translate}}
        </mat-checkbox>
        <hr>
        <ul class="select-columns">
            <li *ngFor="let item of columns">
                <mat-checkbox [(ngModel)]="item.checked" (ngModelChange)="updateAllComplete()">
                    {{item.label |translate}}
                </mat-checkbox>
            </li>
        </ul>
    </ng-container>
    <!-- <ng-container>
        <div cdkDropList (cdkDropListDropped)="drop($event)">
            <div *ngFor="let item of columns" cdkDrag cdkDragBoundary=".sort-column">
                <div class="sort-column">
                    <img class="drag-image" src="../../../../../assets/images/drag-drop-small-icon.svg" cdkDragHandle />
                    <div class="body-short-1">
                        {{item.label |translate}}
                    </div>
                </div>
            </div>
        </div>
    </ng-container> -->
    <ng-container *ngIf="activeTab == 2">
        <ng-container *ngIf="!isExportFail">
            <div class="results">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <img src="{{
                                    isExportDone
                                        ? '/assets/images/import-done-celebration.svg'
                                        : '/assets/images/list-proccessing.svg'
                                }}" class="front-img" alt="" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 front-title heading-5 d-flex justify-content-center">
                        {{
                        (isExportDone
                        ? "ALL_DONE_!"
                        : "YOUR_LIST_IS_BEING_PROCCESSED"
                        ) | translate
                        }}
                    </div>
                    <div class="col-12 body-short-1 d-flex justify-content-center">
                        {{
                        (isExportDone
                        ? "GENERATE_REPORT_DONE"
                        : "GENERATE_REPORT_LOADING"
                        ) | translate
                        }}
                    </div>

                    <ng-container *ngIf="!isExportDone">
                        <div class="col-12 d-flex justify-content-center mt-4">
                            <div class="progress-bar-container">
                                <div class="progress-bar">
                                    <div class="progress-bar-value"></div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isExportFail">
            <div class="results">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <img src="assets/images/amico.svg" class="front-img" alt="" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 front-title heading-5 d-flex justify-content-center">
                        Something went wrong!
                    </div>
                    <div class="col-12 body-short-1 d-flex justify-content-center">
                        We weren’t able to download your report. Please try again!
                    </div>
                </div>
            </div>
        </ng-container>

    </ng-container>

</div>
<div class="dialog-footer">
    <div class="app-actions-button">
        <button type="submit" class="primary-button-large" (click)="activeTab = 2;continue()" [disabled]="isBusy > 0 || !checkSelected()"
            *ngIf="!isExportDone && !isExportFail">
            <span>{{"GENERATE_REPORT" | translate }}</span>
        </button>
        <button type="submit" class="secondary-button-large" (click)="close()" *ngIf="isExportDone || isExportFail">
            <span>{{"CLOSE" | translate }}</span>
        </button>
    </div>
</div>