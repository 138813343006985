<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">{{ "ADD_TRAILER" | translate }}</h5>
    </div>
    <div class="close-icon" (click)="close()">
        <img (click)="close()" src="/assets/images/x-close-black.svg" />
    </div>
</div>
<div class="dialog-body">
    <div class="row">
        <div class="col-12" *ngIf="dataTableConfig">
            <tc-data-table [tableOptions]="dataTableConfig" (onSelectRow)="onSelectRow($event)"></tc-data-table>
        </div>
    </div>
</div>
<div class="dialog-footer">
    <div class="app-actions-button">
        <div class="selected-trailers" *ngIf="selectedRows.length">
            <span class="body-short-1">({{selectedRows.length }} trailers selected)</span>
        </div>
        <button type="button" class="app-close-modal-button" (click)="close()">
            {{ "CANCEL" | translate }}
        </button>
        <button type="submit" class="app-add-new-on-modal-button" (click)="addTruks()"
            [disabled]="selectedRows.length == 0">
            <span>{{ "ADD_TRAILER" | translate }}</span>
        </button>
    </div>
</div>