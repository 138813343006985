<div class="dialog-header">
    <div class="row">
        <div class="col-6">
            <h5 class="dialog-title">{{"EXPORT_LIST" | translate}}</h5>
        </div>
    </div>
    <div class="close-icon" (click)="close()">
        <img (click)="close()" src="/assets/images/x-close-black.svg" />
    </div>
    <div class="report-tabs" *ngIf="isStarted">
        <ul class="mt-3">
            <li class="body-short-1" [class.active]="activeTab == 1"
                (click)="goToTab(1)" [class.disabled]="isStartExport">
                Select Columns
            </li>
            <li class="body-short-1" [class.active]="activeTab == 2" [class.disabled]="activeTab == 1">
                Results 
            </li>
        </ul>
    </div>
</div>
<div class="dialog-body" [ngClass]="isBusy > 0 ? 'wrapper-disabled' : ''">
    <ng-container *ngIf="isStarted">
        <ng-container *ngIf="activeTab == 1">
            <mat-checkbox [checked]="selectAllColumns" [indeterminate]="someComplete()"
                (change)="setAll($event.checked)">
                {{"SELECT_ALL" | translate}}
            </mat-checkbox>
            <hr>
            <ul class="select-columns">
                <li *ngFor="let item of exportColumns">
                    <mat-checkbox [(ngModel)]="item.isChecked" (ngModelChange)="updateAllComplete()">
                        {{item.aliasColumnName}}
                    </mat-checkbox>
                </li>
            </ul>
        </ng-container>
        <ng-container *ngIf="activeTab == 2">
            <div class="results">
                <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                        <img src="{{
                                    isExportDone
                                        ? '/assets/images/import-done-celebration.svg'
                                        : '/assets/images/list-proccessing.svg'
                                }}" class="front-img" alt="" />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 front-title heading-5 d-flex justify-content-center">
                        {{
                            (isExportDone
                                ? "ALL_DONE_!"
                                : "YOUR_LIST_IS_BEING_PROCCESSED"
                            ) | translate
                        }}
                    </div>
                    <div class="col-12 body-short-1 d-flex justify-content-center">
                        {{
                            (isExportDone
                                ? "YOUR_LIST_HAS_BEEN_EXPORTED_SUCCESSFULLY"
                                : "YOUR_LIST_WILL_BE_EXPORTED_SOON_PLEASE_WAIT"
                            ) | translate
                        }}
                    </div>

                    <ng-container *ngIf="!isExportDone">
                        <div class="col-12 d-flex justify-content-center mt-3">
                            <div class="progress">
                                <div #progressBar class="progress-bar width-100" role="progressbar" aria-valuenow="75"
                                    aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="!isStarted">
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <img src="/assets/images/export-front.svg" class="start-img" alt="" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 front-title heading-5 d-flex justify-content-center">
                {{ "EXPORT_YOUR_DATA" | translate }}
            </div>
            <div class="col-12 body-short-1 mb-32 d-flex justify-content-center">
                {{ "EXPORT_YOUR_LISTS_AS_CSV" | translate }}
            </div>
        </div>
    </ng-container>
</div>
<div class="dialog-footer">
    <div class="row">
        <div class="col-12">
            <div class="invalid-form-warning" *ngFor="let message of errorMessages">
                <div class="invalid-icon"></div>
                <div class="warning-message">
                    {{ message | translate }}
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="isStarted">
        <div class="app-actions-button">
            <!-- <button type="button" class="app-close-modal-button" (click)="close()">
                {{ "CANCEL" | translate }}
            </button> -->
            <button type="submit" class="primary-button-large" (click)="export()" [disabled]="!allowExport || isStartExport" *ngIf="!isExportDone">
                <span>{{"CONTINUE" | translate }}</span>
            </button>
            <button type="submit" class="primary-button-large" (click)="close()" [disabled]="!allowExport" *ngIf="isExportDone">
                <span>{{"CLOSE" | translate }}</span>
            </button>
        </div>
    </ng-container>
    <ng-container *ngIf="!isStarted">
        <div class="app-actions-button">
            <button type="submit" class="primary-button-large" (click)="isStarted = true">
                <span>{{"GET_STARTED" | translate }}</span>
            </button>
        </div>
    </ng-container>
</div>