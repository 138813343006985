import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberWithCommas'
})
export class NumberWithCommas implements PipeTransform {
  transform(value: string | number): string {
    if (value === null || value === undefined) return '';

    // Convert value to string to retain any format including .00
    const strValue = value.toString();
    
    // Split into integer and decimal parts
    const [integerPart, decimalPart] = strValue.split('.');

    // Format the integer part with commas
    const formattedInteger = Number(integerPart).toLocaleString();

    // If there is a decimal part, keep it as is
    if (decimalPart !== undefined) {
      return `${formattedInteger}.${decimalPart}`;
    }

    // Return the formatted integer part
    return formattedInteger;
  }
}