<div class="dialog-header">
    <ng-container
        *ngTemplateOutlet="sharedTemplatesService.$dialog_header;context: { comp: this,title: 'SNOOZE_REMINDER' }">
    </ng-container>
</div>
<div class="dialog-body" [ngClass]="isBusy ? 'wrapper-disabled' : ''">
    <div class="body-short-1">
        {{"PLEASE_SPECIFY_WHEN_YOU'D_LIKE_TO_BE_REMINDED" |translate}}:
    </div>
    <hr>
    <ng-container *ngIf="snoozOptionValue != -1;else custome">
        <div class="snooz-options-container">
            <div class="snooz-option" *ngFor="let item of snoozOptions" [class.active]="item.value == snoozOptionValue"
                (click)="selectTime(item)">
                {{item.text}}
            </div>
        </div>
    </ng-container>
    <ng-template #custome>
        <div class="row">
            <div class="col-5">
                <div class="snooz-options-container">
                    <div class="snooz-option" *ngFor="let item of snoozOptions" [class.active]="item.value == snoozOptionValue"
                        (click)="selectTime(item)">
                        {{item.text}}
                    </div>
                </div>
            </div>
            <div class="col-7">
                <div class="date-container">
                    <app-date-picker [(ngModel)]="snoozeDate" [minDate]="todayDate"
                        [inline]="true"
                        [fromModal]="true"></app-date-picker>
                </div>
            </div>
        </div>
    </ng-template>
    
    
</div>
<div class="dialog-footer">
    <div class="row">
        <div class="invalid-form-warning" *ngFor="let message of errorMessages">
            <div class="invalid-icon"></div>
            <div class="warning-message">
                {{ message | translate }}
            </div>
        </div>
    </div>
    <div class="app-actions-button">
        <button class="app-close-modal-button" (click)="close()">
            {{ "CANCEL" | translate }}
        </button>
        <button class="app-save-changes-modal-button" (click)="saveChanges()">
            <span>
                {{ "SAVE" | translate }}
            </span>
        </button>
    </div>
</div>