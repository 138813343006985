import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Driverview } from 'src/app/modules/driver/models/driverview.model';
import { DriverService } from 'src/app/modules/driver/services/driver.service';
import { FormService } from 'src/app/shared/services/form.service';

@Component({
    selector: 'app-driver-quick-add',
    templateUrl: './driver-quick-add.component.html',
    styleUrls: ['./driver-quick-add.component.scss'],
})
export class DriverQuickAddComponent implements OnInit {
    allowEdit: boolean = true;
    editMode: boolean = true;
    submitted: boolean = false;
    errorMessages: Set<string> = new Set();
    todayDate: Date = new Date();
    constructor(
        private dialogRef: MatDialogRef<DriverQuickAddComponent>,
        private fb: UntypedFormBuilder,
        private driverService: DriverService
    ) {}
    quickAddDriverForm = this.fb.group({
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        dateOfBirth: [],
        cdlNumber: [],
        yearsOfExp: [],
        issuingState: [],
        issueDate: [],
        expirationDate: [],
        email: [
            '',
            [
                Validators.email,
                Validators.pattern(
                    /(^(?:[A-Za-z0-9_%+-]+\.)*[A-Za-z0-9_%+-]+)@((?:(?:[A-Za-z0-9]+-)*[A-Za-z0-9]+\.)+)([A-Za-z]{2,})$/
                ),
            ],
        ],
        employeeCode: ['', [Validators.required]],
    });
    ngOnInit(): void {}

    cancel() {
        this.dialogRef.close(null);
    }

    get f() {
        return this.quickAddDriverForm.controls;
    }

    populateForm() {
        let model = new Driverview({
            firstName: this.f.firstName.value,
            lastName: this.f.lastName.value,
            dateOfBirth: this.f.dateOfBirth.value,
            cdlNumber: this.f.cdlNumber.value,
            issuingState: this.f.issuingState.value,
            email: this.f.email.value,
            companyUserCode: this.f.employeeCode.value,
            issueDate: this.f.issueDate.value,
            expirationDate: this.f.expirationDate.value,
            experience: Number(this.f.yearsOfExp.value) ?? 0,
        });

        return model;
    }

    async saveChanges() {
        this.submitted = true;
        if (this.quickAddDriverForm.invalid) return;

        try {
            const response = await this.driverService.addDriverAux(
                this.populateForm()
            );
            if (response.success) this.dialogRef.close(response.data);
        } catch (error: any) {
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        }
    }
}
