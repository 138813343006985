import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, ApiResponseAny, PagingData } from 'src/app/core/models/api-response.model';

import { environment } from 'src/environments/environment';
import { ChangeEmail, Employee } from '../models/employee.model';
import { lastValueFrom } from 'rxjs';
import { GetDataOptions } from 'src/app/shared/model/shared.model';
import { DriverStatistics } from '../../driver/models/driverview.model';

const APIURL = `${environment.apiUrl}/cmp/`;

@Injectable({
    providedIn: 'root'
})
export class EmployeesApiService {

    constructor(
        private http: HttpClient
    ) { }

    async getAllEmployees(
        isActive: boolean,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<Employee>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<Employee>>>(
                APIURL +
                `EmployeeOffice/FpList?pageSize=${options?.pageSize}&pageIndex=${options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : ''
                }&searchString=${options?.searchString
                }&isActive=${isActive}`
            ));
        return response;
    }

    async getEmployeeInfo(
        id: number
    ): Promise<ApiResponse<Employee>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<Employee>>(
                `${APIURL}EmployeeOffice/${id}`
            )
            );

        return response;
    }

    async addEmployee(
        body: Employee
    ): Promise<ApiResponse<Employee>> {
        let response = await lastValueFrom(this.http
            .post<ApiResponse<Employee>>(
                APIURL + `EmployeeOffice`,
                body
            ));

        return response;
    }

    async editEmployee(
        id: number,
        body: Employee
    ): Promise<ApiResponse<Employee>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<Employee>>(
                APIURL + `EmployeeOffice/${id}`,
                body
            ));

        return response;
    }

    async checkStatistics(
        isActive: boolean = true
    ): Promise<ApiResponse<DriverStatistics>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<DriverStatistics>>(
                APIURL + `EmployeeOffice/FpList/Statistics?isActive=${isActive}`
            ));
        return response;
    }

    async inviteEmployeeOffice(id: number): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(`${APIURL}EmployeeOffice/${id}/Invite`, {}));
        return response;
    }

    async changeEmail(
        id: number,
        body: ChangeEmail
    ): Promise<ApiResponse<ChangeEmail>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<ChangeEmail>>(
                APIURL + `EmployeeOffice/ChangeEmail/${id}`,
                body
            ));

        return response;
    }
}
