import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-import-export-alert',
    templateUrl: './import-export-alert.component.html',
    styleUrls: ['./import-export-alert.component.scss'],
})
export class ImportExportAlertComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<ImportExportAlertComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogData: {
            type: string;
        }
    ) {}

    ngOnInit(): void {}

    cancel() {
        this.dialogRef.close('cancel');
    }

    confirm() {
        this.dialogRef.close('confirm');
    }
}
