<div class="requireds-box" *ngIf="!hide">
    <div class="requireds-box-header d-flex">
        <img class="warning" src="../../../../../assets/images/warning-triangle-icon.svg">
        <div class="heading-6 text">
            {{title |translate}}
        </div>
    </div>
    <div class="requireds-box-body">
        <div class="row body-short-1">
            {{description |translate}}
        </div>
        <div class="row required-progress">
            <span class="body-short-1 progress-info">{{requiredFieldDoneCount}} of {{requiredFieldCount}} completed</span>
            <div class="progress">
                <div class="progress-bar" role="progressbar" [ngStyle]="{'width': progressWidthDone}" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
        <div class="row">
            <div class="required-fields" *ngFor="let item of requiredList">
                <span class="page-name">{{item.pageName |translate}}</span>
                <div *ngFor="let field of item.pageFields">
                    <div class="page-fields">
                        <span class="status-icon">
                            <img src="assets/images/{{field.isEmpty ? 'gray-circle-small.svg' : 'required-check-icon.svg'}}">
                        </span>
                        <!-- [routerLink]="item.pageRoute" [queryParams]="{id: field.fieldId}" -->
                        <a class="field-name"  (click)="goToLink(item.pageRoute,field.fieldId ?? '')"  [class.line-through]="!field.isEmpty">{{field.fieldName |translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>