<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">
            {{ "SIGNATURE_DETAILS" | translate }}
        </h5>
    </div>
    <div class="close-icon" (click)="cancel()">
        <img (click)="cancel()" src="/assets/images/x-close-black.svg" />
    </div>
</div>

<div class="dialog-body">
    <!-- <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="signed-on">{{ "SIGNED_ON" | translate }}</label>
                <div class="body-short-1">
                    <a role="button">document.name</a>
                </div>
            </div>
        </div>
    </div> -->
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="full-name">{{ "FULL_NAME" | translate }}</label>
                <div class="body-short-1">
                    {{ signatureDetials?.signerName }}
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="email-address">
                    {{ "EMAIL_ADDRESS" | translate }}
                </label>
                <div class="body-short-1">
                    {{ signatureDetials?.signerEmail }}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="dated"> {{ "DATED" | translate }} </label>
                <div class="body-short-1">
                    {{ signatureDetials?.singingDate | date : "d/MM/y" }}
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="timestamp"> {{ "TIME_STAMP" | translate }} </label>
                <div class="body-short-1">
                    {{
                        (signatureDetials?.signingTimeStamp | date : "d/MM/y") +
                            " " +
                            ("AT" | translate) +
                            " " +
                            (signatureDetials?.signingTimeStamp
                                | date : "hh:mm:ss") + ' (UTC)'
                    }}
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="ip-address"> {{ "IP_ADDRESS" | translate }} </label>
                <div class="body-short-1">
                    {{ signatureDetials?.ipAddress }}
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label for="device-used">
                    {{ "DEVICE_USED" | translate }}
                </label>
                <div class="body-short-1">
                    {{ signatureDetials?.deviceInfo }}
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="signatureDetials?.signedDocumentId">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="signature">
                        {{ "SIGNATURE" | translate }}
                    </label>
                    <img     [src]="signatureImgLink  | safe" alt="" />
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="dialog-footer">
    <div class="app-actions-button">
        <button class="app-close-modal-button" (click)="cancel()">
            {{ "CLOSE" | translate }}
        </button>
    </div>
</div>
