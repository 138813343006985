<!-- <div class="eye-icon-wrap">
    <input
        [type]="togglePasswordInputType == false ? 'password' : 'text'"
        class="form-control passwordField"
        name="password"
        [(ngModel)]="passwordInput"
        placeholder="{{ placeholder | translate }}"
        [id]="id"
        (ngModelChange)="updateValue()"
        maxlength="250"
    />
    <a 
        href="javascript:void(0)"
        (click)="eyeClick()"
        [ngClass]="togglePasswordInputType == true ? 'eyeOpened' : 'eyeClosed'"
        class="eye-icon"
        tabindex="-1"
    ></a>
</div> -->
<ng-container *ngIf="disapleAutoComplete;else allowAutoComplete">
    <div class="eye-icon-wrap">
        <input
            type="text"
            class="form-control passwordField hide-password"
            [ngClass]="{'show-password': togglePasswordInputType}"
            name="password"
            [(ngModel)]="passwordInput"
            placeholder="{{ placeholder | translate }}"
            [id]="id"
            (ngModelChange)="updateValue()"
            maxlength="250"
            autocomplete="off"
        />
        <a 
            href="javascript:void(0)"
            (click)="eyeClick()"
            [ngClass]="togglePasswordInputType == true ? 'eyeOpened' : 'eyeClosed'"
            class="eye-icon"
            tabindex="-1"
        ></a>
    </div>
</ng-container>
<ng-template #allowAutoComplete>
    <div class="eye-icon-wrap">
        <input
            [type]="togglePasswordInputType == false ? 'password' : 'text'"
            class="form-control passwordField"
            name="password"
            [(ngModel)]="passwordInput"
            placeholder="{{ placeholder | translate }}"
            [id]="id"
            (ngModelChange)="updateValue()"
            maxlength="250"
        />
        <a 
            href="javascript:void(0)"
            (click)="eyeClick()"
            [ngClass]="togglePasswordInputType == true ? 'eyeOpened' : 'eyeClosed'"
            class="eye-icon"
            tabindex="-1"
        ></a>
    </div>
</ng-template>

