import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-permission',
  templateUrl: './notification-permission.component.html',
  styleUrls: ['./notification-permission.component.scss']
})
export class NotificationPermissionComponent {

    constructor(
        public dialogRef: MatDialogRef<NotificationPermissionComponent>,
    ){}

    close() {
        this.dialogRef.close('close');
    }

    allow(){
        this.dialogRef.close('allow');
    }

}
