import {
    OtherDocument,
    TruckAux,
    TruckBasicInformation,
    TruckInformation,
    TruckList,
    TruckNotInsured,
    truckOnboarding,
    TruckRegistration,
    TruckShouldUnInsure,
    TruckSpecification,
    VinNumberInfo,
} from './../models/trucks.model';
import {
    ApiResponse,
    ApiResponseAny,
    PagingData,
} from './../../../core/models/api-response.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import {
    ExportColumns,
    ImportListMappedData,
} from 'src/app/shared/model/import-export-list.model';
import { FileService } from 'src/app/shared/services/file.service';
import { lastValueFrom } from 'rxjs';
import { GetDataOptions } from 'src/app/shared/model/shared.model';
import { RepairsList } from '../../repairs/models/repairs.model';
import { IntervalEnum } from '../../eld-monitoring/models/eld-monitoring.model';

const APIURL = `${environment.apiUrl}/`;

@Injectable({
    providedIn: 'root',
})
export class TrucksApiService {
    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'json',
        }),
        responseType: 'json' as 'json',
    };
    constructor(private http: HttpClient, private fileService: FileService) { }

    async getTrucksList(
        options: GetDataOptions,
        isActive: boolean
    ): Promise<ApiResponse<PagingData<TruckList>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<TruckList>>>(
                APIURL +
                `cmp/AssetTruck/fplist?searchString=${options.searchString}` +
                `&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}&isActive=${isActive}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async addTruckBasicInformation(
        body: TruckBasicInformation
    ): Promise<ApiResponse<TruckInformation>> {
        let response = await lastValueFrom(this.http
            .post<ApiResponse<TruckInformation>>(
                APIURL + `cmp/AssetTruck`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async updateTruckBasicInformation(
        truckId: number,
        body: TruckBasicInformation
    ): Promise<ApiResponse<TruckInformation>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<TruckInformation>>(
                APIURL + `cmp/AssetTruck/${truckId}/BasicInformation`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async updateTruckSpecification(
        truckId: number,
        body: TruckSpecification
    ): Promise<ApiResponse<TruckInformation>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<TruckInformation>>(
                APIURL + `cmp/AssetTruck/${truckId}/Specification`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async updateTruckRegistration(
        truckId: number,
        registrationModel: TruckRegistration
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${APIURL}cmp/AssetTruck/${truckId}/Permit`,
                registrationModel
            ));

        return response;
    }

    async getTruckInformation(
        truckId: number
    ): Promise<ApiResponse<TruckInformation>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<TruckInformation>>(
                APIURL + `cmp/AssetTruck/${truckId}`
            ));
        if (response.success) {
            return response;
        } else
            return {
                success: false,
                data: null,
            };
    }
    async getDecodeVIN(vinNumber: number): Promise<ApiResponse<VinNumberInfo>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<VinNumberInfo>>(
                APIURL + `cmp/AssetTruck/DecodeVIN/${vinNumber}`
            ));
        if (response.success) {
            return response;
        } else
            return {
                success: false,
                data: null,
            };
    }

    async updateTuckOnboarding(
        truckId: number,
        onBoardingModel: truckOnboarding
    ): Promise<ApiResponse<TruckInformation>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<TruckInformation>>(
                `${APIURL}cmp/AssetTruck/${truckId}/Onboarding`,
                onBoardingModel
            ));
        return response;
    }

    async getOtherDocuments(
        assetId: number,
        options?: GetDataOptions
    ): Promise<ApiResponse<PagingData<OtherDocument>>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<OtherDocument>>>(
                `${APIURL}cmp/AssetTruck/${assetId}/Documents?pageSize=${options?.pageSize
                }&pageIndex=${options?.pageIndex ? options?.pageIndex : 0
                }&orderBy=${options?.sort ? options?.sort : ''}&searchString=${options?.searchString
                }`
            ));

        return response;
    }

    async updateDocument(
        assetId: number,
        documentModel: OtherDocument
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${APIURL}cmp/AssetTruck/${assetId}/Document/${documentModel.id}`,
                documentModel
            ));

        return response;
    }

    async deleteDocument(
        assetId: number,
        otherDocumentId: number
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .delete<ApiResponseAny>(
                `${APIURL}cmp/AssetTruck/${assetId}/Document/${otherDocumentId}`
            )
        );

        return response;
    }

    async addDocument(
        assetId: number,
        documentModel: OtherDocument
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .post<ApiResponseAny>(
                `${APIURL}cmp/AssetTruck/${assetId}/Document`,
                documentModel
            ));

        return response;
    }

    async getTruckListAux(
        options: GetDataOptions,
        isActive: boolean | null = true
    ): Promise<ApiResponse<PagingData<TruckAux>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<TruckAux>>>(
                APIURL +
                `cmp/TruckAux/FpList?searchString=${options.searchString
                }&searchById=${options.searchById ?? ''}
                &pageIndex=${options.pageIndex}&pageSize=${options.pageSize
                }&orderBy=${options.sort}
                &isActive=${isActive}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getActiveTrucksListAux(
        options: GetDataOptions,
        dateInterval: IntervalEnum = IntervalEnum.Week
    ): Promise<ApiResponse<PagingData<TruckAux>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<TruckAux>>>(
                APIURL +
                `cmp/TruckAux/FpList?dateInterval=${dateInterval}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async addTruckAux(truck: TruckAux): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .post<ApiResponseAny>(`${APIURL}cmp/TruckAux`, truck));

        return response;
    }

    async getShouldInsureFpList(): Promise<ApiResponse<TruckNotInsured[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<TruckNotInsured[]>>(
                APIURL + `cmp/AssetTruck/ShouldInsure/FpList`
            ));
        return response;
    }

    async getShouldUnInsureFpList(): Promise<
        ApiResponse<TruckShouldUnInsure[]>
    > {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<TruckShouldUnInsure[]>>(
                APIURL + `cmp/AssetTruck/ShouldUnInsure/FpList`
            ));
        return response;
    }

    async checkShouldInsure(truckId: number): Promise<ApiResponse<boolean>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<boolean>>(
                APIURL + `cmp/AssetTruck/${truckId}/ShouldInsure`
            ));
        return response;
    }

    async checkShouldUnInsure(truckId: number): Promise<ApiResponse<boolean>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<boolean>>(
                APIURL + `cmp/AssetTruck/${truckId}/ShouldUnInsure`
            ));
        return response;
    }

    /**Import/Export */

    async getExportColumn(): Promise<ApiResponse<ExportColumns[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<ExportColumns[]>>(
                `${APIURL}cmp/AssetTruck/ExportColumn/FpList`
            )
        );

        return response;
    }

    async getTruckTableColumnsAux(): Promise<ApiResponse<string[]>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<string[]>>(
                `${APIURL}cmp/AssetTruck/ImportColumn/FpList`
            )
        );

        return response;
    }

    async importTruckList(
        mappedColoums: ImportListMappedData
    ): Promise<ApiResponseAny> {
        const response = await lastValueFrom(this.http
            .put<ApiResponseAny>(
                `${APIURL}cmp/AssetTruck/Import`,
                mappedColoums
            ));
        return response;
    }

    async exportTruckList(
        employeeIdList: number[],
        columns: string[]
    ): Promise<any> {
        const response = await lastValueFrom(this.http
            .post(
                `${APIURL}cmp/AssetTruck/Export`,

                {
                    ids: employeeIdList,
                    columns: columns,
                },
                { responseType: 'blob' }
            ));
        this.fileService.downloadExportedFile(response, 'exported-list');

        return response;
    }
    
    async getRepairHistoryList(
        id: number,
        options: GetDataOptions
    ): Promise<ApiResponse<PagingData<RepairsList>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<RepairsList>>>(
                APIURL +
                `cmp/AssetTruck/${id}/QueryInsight/RepairHistory?searchString=${options.searchString}` +
                `&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    /** */
}
